import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "./card";
import Chest from "../../img/chest.png";
import { fetchLastPlayed } from "../../util/fetch";
import { useSelector } from "react-redux";
import { exerciseTypes } from "../../util/options";
import ReadingBook from "../../img/readingBook.png";
import Letter from "../../img/letter.png";
import Sequence from "../../img/sequence.png";
import NoConnection from "../../img/no-connection.png";
import Brick from "../../img/brick.png";
import { useTranslation } from "react-i18next";

const LastPlayedExercise = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user.userData);
  const [lastPlayedType, setLastPlayedType] = useState(null);

  useEffect(() => {
    const getLastPlayed = async () => {
      const lastPlayed = await fetchLastPlayed(userData.uid);
      setLastPlayedType(lastPlayed);
    };

    if (userData && userData.uid) {
      getLastPlayed();
    }
  }, [userData]);

  switch (lastPlayedType) {
    case 0:
      return (
        <Card
          text={t("words")}
          secondaryText={t("play")}
          onClick={() => navigate("/home/exercises/words")}
          icon={Chest}
        />
      );
    case exerciseTypes.words:
      return (
        <Card
          text={t("words")}
          secondaryText={t("lastPlayed")}
          onClick={() => navigate("/home/exercises/words")}
          icon={Chest}
        />
      );
    case exerciseTypes.readingComprehension:
      return (
        <Card
          text={t("readingComprehension")}
          secondaryText={t("lastPlayed")}
          onClick={() => navigate("/home/exercises/readingComprehension")}
          icon={ReadingBook}
        />
      );
    case exerciseTypes.multipleChoice:
      return (
        <Card
          text={t("multipleChoice")}
          secondaryText={t("lastPlayed")}
          onClick={() => navigate("/home/exercises/multipleChoice")}
          icon={Letter}
        />
      );
    case exerciseTypes.order:
      return (
        <Card
          text={t("order")}
          secondaryText={t("lastPlayed")}
          onClick={() => navigate("/home/exercises/order")}
          icon={Sequence}
        />
      );
    case exerciseTypes.connection:
      return (
        <Card
          text={t("connection")}
          secondaryText={t("lastPlayed")}
          onClick={() => navigate("/home/exercises/connection")}
          icon={NoConnection}
        />
      );
    case exerciseTypes.sentenceCreation:
      return (
        <Card
          text={t("sentenceCreation")}
          secondaryText={t("lastPlayed")}
          onClick={() => navigate("/home/exercises/sentenceCreation")}
          icon={Brick}
        />
      );
    default:
      return (
        <Card
          text={t("words")}
          secondaryText={t("play")}
          onClick={() => navigate("/home/exercises/words")}
          icon={Chest}
        />
      );
  }
};

export default LastPlayedExercise;
