import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCULIbYHZhVyhYaRJdCr4a7a3gFikHGDBU",
  authDomain: "lernmit-4e37a.firebaseapp.com",
  projectId: "lernmit-4e37a",
  storageBucket: "lernmit-4e37a.appspot.com",
  messagingSenderId: "303970627463",
  appId: "1:303970627463:web:78dad26b5e882d5c4fed22",
  measurementId: "G-T6SNRT4F1T",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };
