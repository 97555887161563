import React from "react";

const LoadingCard = ({
  onClick,
  icon,
  text,
  flex = "col",
  textSize = "text-4xl",
  imgSize = "w-32 h-32",
  padding = "p-4",
  secondaryText = "",
  secondaryTextSize = "text-2xl",
}) => {
  return (
    <div className="flex-1 mx-5 my-2 sm:my-5  ">
      <button
        className={`${padding} font-bold w-full h-full flex flex-${flex} flex-1 justify-center ${textSize} text-black rounded-3xl bg-gray-300 items-center`}
      >
        <div className="flex flex-col">
          <h1 className="border-b border-transparent text-headerText opacity-0">
            {text}
          </h1>
          <h1 className={`${secondaryTextSize}text-underHeaderText opacity-0`}>
            {secondaryText}
          </h1>
        </div>
        <img
          src={icon}
          className={`opacity-0 ml-4 w-32 h-32`}
          alt={String(icon)}
        />
      </button>
    </div>
  );
};

export default LoadingCard;
