import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../config/firebase";
import PageTransition from "./pageTransition";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const Header = ({ children, navigateTo = null }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1024);

  let navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleMenu = () => {
    if (isMobileView) {
      setIsMenuVisible(!isMenuVisible);
    }
  };

  const displayNames = (segment, index) => {
    switch (segment) {
      case "home":
        return <span className="material-symbols-outlined">home</span>;
      case "exercises":
        return t("typeChoice");
      case "profile":
        return t("myProfile");
      case "statistics":
        return t("statistics");
      case "words":
        return t("words");
      case "readingComprehension":
        return t("readingComprehension");
      case "sentenceCreation":
        return t("sentenceCreation");
      case "order":
        return t("order");
      case "multipleChoice":
        return t("multipleChoice");
      case "connection":
        return t("connection");
      case "requests":
        return t("requests");
      case "createExercise":
        return t("typeChoice");
      case "myExercises":
        return t("myExercisesButtonText");
      default:
        return index === 3 || index === 4
          ? t("currentExercise")
          : segment.charAt(0).toUpperCase() + segment.slice(1);
    }
  };

  const pathSegments = location.pathname
    .split("/")
    .filter((path) => path.length > 0);
  let lastSegment = "";
  const timeline = pathSegments.map((segment, index) => {
    const path = `/${pathSegments.slice(0, index + 1).join("/")}`;
    if (segment === "editExersice" || lastSegment === "editExersice") {
      lastSegment = segment;
      return null;
    }
    const displayName = displayNames(segment, index);
    lastSegment = segment;

    return (
      <div
        className={`flex  pb-2 flex-row items-center ${
          index === pathSegments.length - 1 ? "border-white-500 border-b-2" : ""
        }`}
      >
        <div
          key={path}
          className={`rounded-lg timeline-node px-2 py-1 text-white   transition duration-300 ease-in-out ${
            index === pathSegments.length - 1
              ? "cursor-default "
              : "bg-ctaPrimary hover:bg-ctaHover cursor-pointer"
          }`}
          onClick={() => navigate(path)}
        >
          <h1
            className={`font-3xl text-red${
              index === pathSegments.length - 1 ? "font-bold text-3xl" : ""
            }`}
          >
            {displayName}
          </h1>
        </div>
        <div className="text-white">
          {index !== pathSegments.length - 1 && (
            <span class="material-symbols-outlined">double_arrow</span>
          )}
        </div>
      </div>
    );
  });

  return (
    <div className="header w-full text-3xl font-bold mb-0 bg-primaryColor flex flex-col lg:flex-row justify-between p-2 sm:p-5 items-center">
      <div className="flex flex-row w-full justify-between lg:justify-between">
        {/* <PageTransition extraStyles="flex flex-row w-full justify-between lg:justify-between"> */}
        <div className="flex items-center md:hidden">
          <button
            className={`title text-headerTitle font-bold "flex"   ${
              navigateTo != null ? "opacity-1" : "opacity-0"
            } md:hidden transition transform hover:scale-110 `}
            onClick={navigateTo}
          >
            <span
              className="material-symbols-outlined"
              style={{
                color: "text-headerTitle",
                fontSize: "2rem",
              }}
            >
              arrow_back
            </span>
          </button>
        </div>
        <button
          className="title text-headerTitle font-bold text-5xl md:text-6xl "
          onClick={() => {
            auth.currentUser ? navigate("/home") : navigate("/");
          }}
        >
          SpielMit
        </button>
        <PageTransition extraStyles="hidden md:flex">
          {timeline.length > 1 && (
            <div className="timeline flex flex-row items-center">
              {timeline}
            </div>
          )}
        </PageTransition>
        <button
          className={`lg:hidden text-headerTitle m-0 p-0 "flex"`}
          onClick={toggleMenu}
          style={{ opacity: children ? 1 : 0 }}
        >
          ☰
        </button>
        {/* </PageTransition> */}
      </div>
      <PageTransition extraStyles="w-fit overflow-visible">
        <motion.div
          style={{ overflow: "visible" }}
          initial={false}
          animate={isMenuVisible || !isMobileView ? "open" : "closed"}
          variants={{
            closed: {
              opacity: 0,
              maxHeight: 0,
              overflow: "hidden",
              transition: { duration: 0.3 },
            },
            open: {
              opacity: 1,
              maxHeight: "20rem",
              transition: {
                duration: 0.3,
                when: "beforeChildren",
                staggerChildren: 0.05,
              },
            },
          }}
          className="menu lg:flex flex-col lg:flex-row items-center mt-4 lg:mt-0"
        >
          {children}
        </motion.div>
      </PageTransition>
    </div>
  );
};

export default Header;
