import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import LoginInput from "../../components/shared/LoginInput";
import Button from "../../components/shared/button";
import Footer from "../../components/shared/footer";
import Header from "../../components/shared/header";
import PageTransition from "../../components/shared/pageTransition";

import {
  doc,
  runTransaction,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import "react-toastify/dist/ReactToastify.css";
import { fetchUserData } from "../../actions/userActions";
import LanguageSwitch from "../../components/shared/languageSwitch";
import ValidationComboBox from "../../components/shared/validationComboBox";
import { db } from "../../config/firebase";
import * as animations from "../../util/animations";
import { logout } from "../../util/authFunctions";
import { notifyError, notifySuccess } from "../../util/toast";
import { exerciseDifficulty, levelOptions } from "../../util/options";

const Profile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);
  const [userDetails, setUserDetails] = useState({
    nickname: userData.nickname,
    firstname: userData.firstname,
    lastname: userData.lastname,
    level: userData.level,
  });
  const [editMode, setEditMode] = useState({
    nickname: false,
    firstname: false,
    lastname: false,
    level: false,
  });

  const [motivation, setMotivation] = useState("");
  const [experience, setExperience] = useState("");
  const [qualifications, setQualifications] = useState("");
  const [errors, setErrors] = useState({
    nickname: { showError: false, message: "" },
    firstname: { showError: false, message: "" },
    lastname: { showError: false, message: "" },
    level: { showError: false, message: "" },
    motivation: { showError: false, message: "" },
    experience: { showError: false, message: "" },
    qualifications: { showError: false, message: "" },
  });

  // eslint-disable-next-line no-unused-vars
  const [showError, setShowError] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [errorMessage, setErrorMessage] = useState("");
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => setLoaded(true), 10);
    return () => clearTimeout(timeoutId);
  }, []);

  const handleEdit = (field) => {
    setEditMode({ ...editMode, [field]: !editMode[field] });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: { showError: false, message: "" },
    }));
    setUserDetails({ ...userDetails, [field]: userData[field] });
  };

  const handleSave = async (field) => {
    if (!validateProfileField(field, userDetails[field])) {
      return;
    }

    const updates = { [field]: userDetails[field] };
    const userRef = doc(db, "users", userData.uid);
    try {
      await updateDoc(userRef, updates);
      dispatch(fetchUserData(userData.uid));
      setEditMode((prev) => ({ ...prev, [field]: false }));
      console.log("User details updated successfully.");
      notifySuccess(t("updateSuccess"));
    } catch (error) {
      console.error("Error updating user details:", error);
      notifyError(t("updateError"));
      setShowError(true);
    }
  };

  const handleChange = (field, value) => {
    setUserDetails({ ...userDetails, [field]: value });
  };

  const saveTeacherRequest = async () => {
    if (!validateTeacherForm()) {
      return;
    }

    const requestRef = doc(db, "teacher_requests", userData.uid);

    try {
      await runTransaction(db, async (transaction) => {
        const docSnapshot = await transaction.get(requestRef);

        if (docSnapshot.exists()) {
          throw new Error("A request has already been submitted.");
        }

        transaction.set(requestRef, {
          motivation,
          experience,
          qualifications,
          userId: userData.uid,
          status: "pending",
          email: userData.email,
          name: `${userData.firstname} ${userData.lastName}`,
          submittedAt: serverTimestamp(),
        });
      });

      notifySuccess(t("requestSubmitted"));
    } catch (error) {
      console.error("Error submitting teacher request:", error);
      setErrorMessage(t("requestError") + ": " + error.message);
      setShowError(true);
      notifyError(t("errorSubmittingRequest"));
    }
  };

  const validateProfileField = (field, value) => {
    if (!value.trim()) {
      setErrors((prev) => ({
        ...prev,
        [field]: { showError: true, message: t(`${field}CannotBeEmpty`) },
      }));
      return false;
    }
    setErrors((prev) => ({
      ...prev,
      [field]: { showError: false, message: "" },
    }));
    return true;
  };

  const validateTeacherForm = () => {
    let valid = true;

    if (!motivation.trim()) {
      setErrors((prev) => ({
        ...prev,
        motivation: { showError: true, message: t("motivationRequired") },
      }));
      valid = false;
    } else {
      setErrors((prev) => ({
        ...prev,
        motivation: { showError: false, message: "" },
      }));
    }

    if (!experience.trim()) {
      setErrors((prev) => ({
        ...prev,
        experience: { showError: true, message: t("experienceRequired") },
      }));
      valid = false;
    } else {
      setErrors((prev) => ({
        ...prev,
        experience: { showError: false, message: "" },
      }));
    }

    if (!qualifications.trim()) {
      setErrors((prev) => ({
        ...prev,
        qualifications: {
          showError: true,
          message: t("qualificationsRequired"),
        },
      }));
      valid = false;
    } else {
      setErrors((prev) => ({
        ...prev,
        qualifications: { showError: false, message: "" },
      }));
    }

    return valid;
  };

  return (
    <div className="h-fit min-h-screen bg-pageBackground flex flex-col justify-center items-center">
      <Header navigateTo={() => navigate("/home")} />
      <PageTransition
        variants={animations.slideUpEffect}
        extraStyles="flex flex-col sm:flex-row p-10 h-full w-full justify-center  flex-1"
      >
        <div className="flex flex-col md:flex-row">
          <div className="flex flex-col md:mr-20">
            <h1 className="font-bold text-5xl text-primaryColor mb-5">
              {t("yourProfile")}
            </h1>
            <div
              className="bg-white rounded-xl w-full h-fit p-10 flex flex-col text-3xl transition-opacity duration-500 ease-in-out items-center justify-center"
              style={{ opacity: loaded ? 1 : 0 }}
            >
              {Object.keys(userDetails).map((key) =>
                editMode[key] ? (
                  <div
                    key={key}
                    className="flex flex-row justify-center items-center mb-5 w-full"
                  >
                    {key === "level" ? (
                      <ValidationComboBox
                        icon="edit"
                        title={t("level")}
                        options={levelOptions}
                        selectedValue={userDetails[key]}
                        setSelectedValue={(value) => handleChange(key, value)}
                        hasError={errors[key].showError}
                        errorMessage={errors[key].message}
                      />
                    ) : (
                      <LoginInput
                        setValue={(field) => handleChange(key, field)}
                        value={userDetails[key]}
                        icon="edit"
                        placeholder={t(key)}
                        showError={errors[key].showError}
                        errorMessage={errors[key].message}
                      />
                    )}
                    <div className="flex flex-col">
                      <Button
                        text={
                          <span
                            className="material-symbols-outlined"
                            style={{ color: "white" }}
                          >
                            save
                          </span>
                        }
                        width="w-6"
                        padding="p-2 px-6"
                        onClick={() => handleSave(key)}
                      />
                      <Button
                        text={
                          <span
                            className="material-symbols-outlined"
                            style={{ color: "white" }}
                          >
                            cancel
                          </span>
                        }
                        width="w-6"
                        padding="p-2 px-6"
                        onClick={() => handleEdit(key)}
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    key={key}
                    className="flex flex-row justify-center items-center mb-5 w-full"
                  >
                    <LoginInput
                      icon="edit"
                      placeholder={t(key)}
                      value={
                        key === "level"
                          ? exerciseDifficulty[userDetails[key]]
                          : userDetails[key]
                      }
                      disabled={true}
                    />
                    <Button
                      text={
                        <span
                          className="material-symbols-outlined"
                          style={{ color: "white" }}
                        >
                          edit
                        </span>
                      }
                      width="w-6"
                      padding="p-2 px-6"
                      onClick={() => handleEdit(key)}
                    />
                  </div>
                )
              )}
              <div>
                <h1 className="font-bold text-primaryColor">{t("language")}</h1>
                <div className="flex flex-col sm:flex-row items-center mb-5">
                  <h1 className="text-xl text-secondaryColor">
                    {t("languageSet")}
                  </h1>
                  <LanguageSwitch />
                </div>
              </div>
              <Button
                icon="power_settings_new"
                text={t("logoutButtonText")}
                onClick={() => {
                  notifySuccess(t("logoutSuccess"));
                  logout(dispatch, navigate);
                }}
              />
            </div>
          </div>
          <div>
            {userData.role === "user" && (
              <div>
                <h1 className="text-5xl font-bold text-primaryColor mb-5 mt-10 md:mt-0">
                  {t("requestTeacherRole")}
                </h1>
                <div className="bg-white rounded-xl w-fit h-fit p-10 flex flex-col text-3xl transition-opacity duration-500 ease-in-out items-center justify-center">
                  <p className="text-secondaryColor text-2xl mb-5">
                    {t("fillForm")}
                  </p>
                  <LoginInput
                    icon="edit"
                    value={motivation}
                    setValue={setMotivation}
                    placeholder={t("motivation")}
                    showError={errors.motivation.showError}
                    errorMessage={errors.motivation.message}
                  />

                  <LoginInput
                    icon="history_edu"
                    value={experience}
                    setValue={setExperience}
                    placeholder={t("experience")}
                    showError={errors.experience.showError}
                    errorMessage={errors.experience.message}
                  />

                  <LoginInput
                    icon="school"
                    value={qualifications}
                    setValue={setQualifications}
                    placeholder={t("qualifications")}
                    showError={errors.qualifications.showError}
                    errorMessage={errors.qualifications.message}
                  />
                  <Button
                    text={t("submitRequest")}
                    onClick={saveTeacherRequest}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </PageTransition>
      <Footer />
    </div>
  );
};

export default Profile;
