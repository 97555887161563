import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { fetchConnectionExerciseWithTasks } from "../../util/fetch";
import ExerciseBody from "../../components/shared/ExerciseBody";
import LoadingPage from "../../components/shared/loadingPage";
import { exerciseTypes } from "../../util/options";
import PairConnector from "../../components/shared/pairConnector";
import NoConnection from "../../img/no-connection.png";
import { useTranslation } from "react-i18next";
import { notifyError } from "../../util/toast";

const ConnectionExercise = () => {
  const { t } = useTranslation();
  const { exerciseId } = useParams();
  const [exercise, setExercise] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [taskInputs, setTaskInputs] = useState([]);
  const [shuffledTasks, setShuffledTasks] = useState([]);

  useEffect(() => {
    fetchConnectionExerciseWithTasks(
      setIsLoading,
      exerciseId,
      setExercise,
      setTaskInputs
    );
  }, [exerciseId]);

  const checkInputs = () => {
    let correct = true;
    let displayEmptyError = false;
    let displayIncorrectError = false;
    const updatedTaskInputs = [];

    exercise.connectionTasks.forEach((task) => {
      const inputPair = taskInputs.find((input) => input.left === task.left);
      if (inputPair) {
        const rightTask = shuffledTasks.find(
          (t) => t.uniqueId === inputPair.right
        ); // Find the right task based on uniqueId
        if (rightTask && rightTask.right === task.right) {
          updatedTaskInputs.push(inputPair);
        } else if (inputPair.right === null) {
          displayEmptyError = true;
          updatedTaskInputs.push({ left: task.left, right: null });
          correct = false;
        } else {
          correct = false;
          displayIncorrectError = true;
          updatedTaskInputs.push({ left: task.left, right: null });
        }
      } else {
        displayIncorrectError = true; // Handles cases where no input pair exists
        updatedTaskInputs.push({ left: task.left, right: null });
        correct = false;
      }
    });

    setTaskInputs(updatedTaskInputs);
    if (correct) {
      setShowModal(true);
    }

    if (displayEmptyError) {
      notifyError(t("connectionCannotBeEmpty"));
    }

    if (displayIncorrectError) {
      notifyError(t("connectionIncorrect"));
    }

    return correct;
  };

  return isLoading ? (
    <LoadingPage />
  ) : (
    <ExerciseBody
      exerciseTypeTitle={t("connection")}
      icon={NoConnection}
      exercise={exercise}
      checkInputs={checkInputs}
      showModal={showModal}
      exerciseType={exerciseTypes.connection}
      navigateString="/home/exercises/connection"
      defaultDescription={t("connectionDefaultDescription")}
    >
      <PairConnector
        tasks={exercise.connectionTasks}
        setTaskInputs={setTaskInputs}
        taskInputs={taskInputs}
        updateShuffledTasks={setShuffledTasks} // Passing the setter function as a callback
      />
    </ExerciseBody>
  );
};

export default ConnectionExercise;
