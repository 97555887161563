import { format } from "date-fns";
import { collection, getDocs } from "firebase/firestore";
import { t } from "i18next";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setFilter } from "../../actions/allWordsFilterActions";
import Button from "../../components/shared/button";
import Exercise from "../../components/shared/exerciseListItem";
import Filter from "../../components/shared/filter";
import Footer from "../../components/shared/footer";
import Header from "../../components/shared/header";
import LoadingPage from "../../components/shared/loadingPage";
import PageTransition from "../../components/shared/pageTransition";
import { db } from "../../config/firebase";
import * as animations from "../../util/animations";
import { exerciseTypes, levelOptionsArray } from "../../util/options";

const getKeyByValue = (object, value) => {
  const invertedObject = Object.keys(object).reduce((acc, key) => {
    acc[object[key]] = key;
    return acc;
  }, {});

  return invertedObject[value];
};

const AllExercises = () => {
  const dispatch = useDispatch();
  const { typeFilter } = useParams();
  React.useEffect(() => {
    if (typeFilter && exerciseTypes[typeFilter] !== undefined) {
      dispatch(setFilter({ type: exerciseTypes[typeFilter].toString() }));
    }
  }, [typeFilter, dispatch]);

  const navigationLink = React.useMemo(() => {
    return `/home/exercises`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeFilter]);

  const [exercises, setExercises] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const navigate = useNavigate();
  const { name, createdBy, level, type } = useSelector(
    (state) => state.allWordsFilter
  );

  React.useEffect(() => {
    const fetchExercises = async () => {
      setIsLoading(true);
      try {
        const querySnapshot = await getDocs(collection(db, "excercise"));
        const exercisesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setExercises(exercisesData);
      } catch (error) {
        console.error("Error fetching exercises:", error);
      }
      setIsLoading(false);
    };

    fetchExercises();
  }, []);

  const filteredExercises = exercises
    .sort(
      (a, b) =>
        b.createdAt.seconds * 1000 +
        b.createdAt.nanoseconds / 1000000 -
        (a.createdAt.seconds * 1000 + a.createdAt.nanoseconds / 1000000)
    )
    .map((exercise) => {
      const startDate = new Date(
        exercise.createdAt?.seconds * 1000 +
          exercise.createdAt?.nanoseconds / 1000000
      );
      const formattedCreatedAt = format(startDate, "PPpp");
      return (
        <Exercise
          key={exercise.id}
          exercise={{
            ...exercise,
            createdAt: formattedCreatedAt,
          }}
        >
          <Button
            icon={"play_arrow"}
            buttonType={"cta"}
            text={t("play")}
            onClick={() =>
              navigate(
                navigationLink +
                  "/" +
                  getKeyByValue(exerciseTypes, exercise.type) +
                  "/" +
                  exercise.id
              )
            }
          />
        </Exercise>
      );
    })
    .filter((exercise) => {
      let flag = true;
      if (name) {
        flag =
          exercise.props.exercise.name
            ?.toLowerCase()
            .includes(name.toLowerCase()) && flag;
      }

      if (createdBy) {
        flag =
          (exercise.props.exercise.createdBy
            ?.toLowerCase()
            .includes(createdBy.toLowerCase()) &&
            flag) ||
          (exercise.props.exercise.createdByNickname
            ?.toLowerCase()
            .includes(createdBy.toLowerCase()) &&
            flag) ||
          (exercise.props.exercise.createdByName
            ?.toLowerCase()
            .includes(createdBy.toLowerCase()) &&
            flag) ||
          (exercise.props.exercise.createdByLastName
            ?.toLowerCase()
            .includes(createdBy.toLowerCase()) &&
            flag);
      }
      if (level) {
        if (level > 0) {
          flag =
            exercise.props.exercise.level.includes(
              levelOptionsArray[level - 1]
            ) && flag;
        }
      }
      if (type && type !== "Kein Filter" && type !== "Žiadny filter") {
        flag = String(exercise.props.exercise.type) === String(type) && flag;
      }
      return flag;
    });

  return isLoading ? (
    <LoadingPage />
  ) : (
    <div className="h-fit min-h-screen w-full bg-pageBackground items-center flex flex-col ">
      <Header
        navigateTo={() => {
          navigate("/home/exercises");
        }}
      />
      <PageTransition
        variants={animations.slideUpEffect}
        extraStyles="flex flex-col flex-1 w-full"
      >
        <div className="flex flex-col-reverse sm:flex-row items-center p-10 ">
          <div className="flex flex-col flex-3 w-full">
            <Filter
              defaultFilter={{ type: String(exerciseTypes[typeFilter]) }}
            />

            {filteredExercises.length > 0 ? (
              filteredExercises
            ) : (
              <h1 className="text-headerText text-2xl font-bold ">
                {t("noExercises")}
              </h1>
            )}
          </div>
        </div>
      </PageTransition>
      <Footer />
    </div>
  );
};

export default AllExercises;
