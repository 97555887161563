import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as animations from "../../util/animations";
import {
  femaleAvatarImages,
  getAvatarItemCategories,
  getAvatarItemNames,
  maleAvatarImages,
} from "../../util/avatarStyles";
import {
  addItemToUserAvatar,
  checkIfUserOwnsItem,
  updateLastPlayed,
  updateUserStatistics,
} from "../../util/fetch";
import { getRandomAvatarItem } from "../../util/lootDrops";
import { exerciseTypes, levelOptionsArray } from "../../util/options";
import LoadingIndicator from "./activityIndicator";
import Button from "./button";
import Footer from "./footer";
import Header from "./header";
import Modal from "./modal";
import PageTransition from "./pageTransition";
import { useTranslation } from "react-i18next";

export default function ExerciseBody({
  exerciseTypeTitle,
  icon,
  exercise,
  checkInputs,
  children,
  defaultDescription,
  showModal,
  navigateString,
  exerciseType,
}) {
  const { t } = useTranslation();
  const userData = useSelector((state) => state.user.userData);
  const [lootItem, setLootItem] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [userOwnsItem, setUserOwnsItem] = React.useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCheck = async () => {
    if (checkInputs()) {
      setIsLoading(true);
      const randomItem = getRandomAvatarItem(userData.gender);
      setLootItem(randomItem);
      setUserOwnsItem(await checkIfUserOwnsItem(userData.uid, randomItem));
      addItemToUserAvatar(userData.uid, randomItem, dispatch);
      setIsLoading(false);
    }
  };

  const navigateTo = () => {
    switch (exerciseType) {
      case exerciseTypes.words:
        navigate("/home/exercises/words");
        break;
      case exerciseTypes.readingComprehension:
        navigate("/home/exercises/readingComprehension");
        break;
      case exerciseTypes.sentenceCreation:
        navigate("/home/exercises/sentenceCreation");
        break;
      case exerciseTypes.order:
        navigate("/home/exercises/order");
        break;
      case exerciseTypes.multipleChoice:
        navigate("/home/exercises/multipleChoice");
        break;
      case exerciseTypes.connection:
        navigate("/home/exercises/connection");
        break;
      default:
        navigate("/home/exercises");
        break;
    }
  };

  return (
    <div className="h-fit min-h-screen bg-pageBackground flex flex-col justify-center items-center">
      <Modal
        showModal={showModal}
        onClick={() => {
          navigate(navigateString);
          updateUserStatistics(userData.uid, exerciseType);
          updateLastPlayed(userData.uid, exerciseType);
        }}
      >
        <h1 className="text-3xl font-bold mr-3 text-headerText">
          {t("congradulations")}
        </h1>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <div>
            {lootItem && userOwnsItem !== null && (
              <div className="flex flex-col items-center justify-center">
                {" "}
                <h1 className="text-secondaryColor font-bold">
                  {t("newItemsGained")}
                </h1>
                <div className="flex flex-row flex-wrap items-center justify-center">
                  <button className="bg-white h-32 w-64 hover:bg-gray-100 rounded-xl items-center flex justify-center">
                    <img
                      src={
                        userData.gender === "male"
                          ? maleAvatarImages[lootItem?.category][lootItem?.name]
                          : femaleAvatarImages[lootItem?.category][
                              lootItem?.name
                            ]
                      }
                      className={
                        lootItem?.category === "heads"
                          ? "mt-40"
                          : lootItem?.category === "legs"
                          ? "mb-32"
                          : lootItem?.category === "boots"
                          ? "mb-56"
                          : ""
                      }
                      alt={lootItem?.name}
                    />
                  </button>
                </div>
                <div className="flex flex-row">
                  <h1 className="font-bold text-primaryColor">
                    {t("category")}
                  </h1>
                  <h1>
                    &nbsp;
                    {getAvatarItemCategories(t)[lootItem?.category]}
                  </h1>
                </div>
                <div className="flex flex-row">
                  <h1 className="font-bold text-primaryColor">{t("name")}</h1>
                  &nbsp;
                  <h1>{getAvatarItemNames(t)[lootItem?.name]}</h1>
                </div>
                <h1 className="text-xl font-bold text-secondaryColor mb-5 mt-5">
                  {userOwnsItem ? t("itemOwned") : t("itemNotOwned")}
                </h1>
              </div>
            )}
          </div>
        )}
      </Modal>
      <Header navigateTo={navigateTo} />
      <PageTransition
        variants={animations.slideUpEffect}
        extraStyles="h-full w-full bg-neutral  flex flex-col flex-1"
      >
        <div className="w-fit items-center justify-center flex flex-col mx-auto">
          <div className="flex flex-row items-center justify-center">
            <h1 className="font-bold text-3xl">{exerciseTypeTitle}</h1>
            <img src={icon} className="w-20 h-20 ml-4" alt={String(icon)} />
          </div>

          <div className="flex flex-col items-center bg-cardBg rounded-2xl p-5 m-3 mt-5 mb-5 max-w-3xl justify-center">
            <div className="flex flex-row justify-between w-full mb-5">
              <div className="flex flex-col items-start">
                <div className="flex flex-row text-primaryColor">
                  <span className="material-symbols-outlined">list_alt</span>
                  <h1 className="font-bold text-primaryColor">{t("name")}</h1>
                </div>
                <h1 className="text-3xl font-bold mr-3 text-primaryColor">
                  {exercise.name}
                </h1>
              </div>
              <div className="flex flex-col items-start">
                <div className="flex flex-row">
                  <span className="material-symbols-outlined">speed</span>
                  <h1 className="font-bold">{t("level")}</h1>
                </div>

                <h2 className="text-3xl font-bold mr-3">
                  {levelOptionsArray[exercise.level]}
                </h2>
              </div>
            </div>
            <div className="flex flex-col justify-start">
              <div className="flex flex-row">
                <span className="material-symbols-outlined">description</span>
                <h1 className="font-bold">{t("description")}</h1>
              </div>

              <h2 className="text-xl font-thin mr-3">
                {exercise.description ?? defaultDescription}
              </h2>
            </div>
            {children}
            <Button
              icon={"check"}
              text={t("check")}
              onClick={handleCheck}
              buttonType={"cta"}
              extraStyle="mt-5"
            />
            <div className="flex flex-col items-start mt-10">
              <div className="flex flex-row">
                <span className="material-symbols-outlined">
                  account_circle
                </span>
                <h1 className="font-bold">{t("createdBy")}</h1>
              </div>

              <h2 className="text-3xl font-bold mr-3">
                {exercise.createdByName + " " + exercise.createdByLastName}
              </h2>
            </div>
          </div>
        </div>
      </PageTransition>
      <Footer />
    </div>
  );
}
