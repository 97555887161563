import React from "react";
import SignIn from "../../components/auth/signIn";
import Header from "../../components/shared/header";
import Footer from "../../components/shared/footer";
import PageTransition from "../../components/shared/pageTransition";
import * as animations from "../../util/animations";

const Register = () => {
  const [isLoginScreen, setIsLoginScreen] = React.useState(true);

  const toggleScreen = () => {
    setIsLoginScreen(!isLoginScreen);
  };

  return (
    <div className="bg-pageBackground h-fit min-h-screen w-screen flex flex-col items-center ">
      <Header />
      <div className="fadeIn p-4 flex-grow h-full">
        <PageTransition variants={animations.slideInLeft}>
          <SignIn toggleScreen={toggleScreen} />
        </PageTransition>
      </div>
      <Footer />
    </div>
  );
};

export default Register;
