import { doc, updateDoc } from "firebase/firestore";
import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchUserData } from "../../actions/userActions";
import Footer from "../../components/shared/footer";
import Header from "../../components/shared/header";
import PageTransition from "../../components/shared/pageTransition";
import { db } from "../../config/firebase";
import * as animations from "../../util/animations";
import { femaleAvatarImages, maleAvatarImages } from "../../util/avatarStyles";

export default function AvatarPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user.userData);
  const dispatch = useDispatch();

  const [selectedHead, setSelectedHead] = useState(
    userData.headEquipped || "default"
  );
  const [selectedTorso, setSelectedTorso] = useState(
    userData.torsoEquipped || "default"
  );
  const [selectedPants, setSelectedPants] = useState(
    userData.legsEquipped || "default"
  );
  const [selectedBoots, setSelectedBoots] = useState(
    userData.bootsEquipped || "default"
  );
  const [showEdit, setShowEdit] = useState(false);

  const handlerHeadChange = async (headName) => {
    setSelectedHead(headName);
    const userDocRef = doc(db, "users", userData.uid);
    await updateDoc(userDocRef, {
      headEquipped: headName,
    });
    dispatch(fetchUserData(userData.uid));
  };

  const handleTorsoChange = async (torsoName) => {
    setSelectedTorso(torsoName);
    const userDocRef = doc(db, "users", userData.uid);
    await updateDoc(userDocRef, {
      torsoEquipped: torsoName,
    });
    dispatch(fetchUserData(userData.uid));
  };

  const handlePantsChange = async (pantsName) => {
    setSelectedPants(pantsName);
    const userDocRef = doc(db, "users", userData.uid);
    await updateDoc(userDocRef, {
      legsEquipped: pantsName,
    });
    dispatch(fetchUserData(userData.uid));
  };

  const handleBootsChange = async (bootsName) => {
    setSelectedBoots(bootsName);
    const userDocRef = doc(db, "users", userData.uid);
    await updateDoc(userDocRef, {
      bootsEquipped: bootsName,
    });
    dispatch(fetchUserData(userData.uid));
  };

  const editVariants = {
    hidden: { opacity: 0, height: 0, overflow: "hidden" },
    visible: { opacity: 1, height: "auto", transition: { duration: 0.5 } },
  };

  return (
    <div className="h-screen min-h-fit flex flex-col justify-start items-center bg-pageBackground">
      <Header navigateTo={() => navigate("/home")} />

      <div className="bg-pageBackground h-full w-full flex flex-col justify-start items-center ">
        <PageTransition
          variants={animations.slideUpEffect}
          extraStyles="flex flex-col h-fit relative w-full"
        >
          <div className="flex flex-col h-fit relative w-full ">
            <h1 className="text-primaryColor font-bold text-4xl items-center justify-center w-fit mr-auto ml-auto">
              {t("yourAvatar")}
            </h1>
            <div className="bg-backgroundColor relative  h-fit  items-center flex rounded-xl">
              <img
                src={
                  userData.gender === "male"
                    ? maleAvatarImages.heads[selectedHead]
                    : femaleAvatarImages.heads[selectedHead]
                }
                alt="Head"
                className="absolute top-0 left-0 right-0 ml-auto mr-auto  h-96 "
              />
              <img
                src={
                  userData.gender === "male"
                    ? maleAvatarImages.torsos[selectedTorso]
                    : femaleAvatarImages.torsos[selectedTorso]
                }
                alt="Torso"
                className="absolute top-0 left-0 right-0 ml-auto mr-auto  h-96"
              />
              <img
                src={
                  userData.gender === "male"
                    ? maleAvatarImages.legs[selectedPants]
                    : femaleAvatarImages.legs[selectedPants]
                }
                alt="Pants"
                className="absolute top-0 left-0 right-0 ml-auto mr-auto  h-96"
              />
              <img
                src={
                  userData.gender === "male"
                    ? maleAvatarImages.boots[selectedBoots]
                    : femaleAvatarImages.boots[selectedBoots]
                }
                alt="Boots"
                className="absolute top-0 left-0 right-0 ml-auto mr-auto h-96"
              />
            </div>
          </div>
          <div className="bg-white rounded-xl flex w-fit flex-col items-center relative mt-96 mr-auto ml-auto">
            <div
              className="flex flex-row hover:bg-gray-100 cursor-pointer p-5 mr-auto ml-auto"
              onClick={() => setShowEdit(!showEdit)}
            >
              <h1>{t("edit")}</h1>
              <span className="material-symbols-outlined">
                {showEdit ? "expand_less" : "expand_more"}
              </span>
            </div>
            <AnimatePresence>
              {showEdit && (
                <motion.div
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={editVariants}
                >
                  <div className="p-4">
                    <h1 className="text-primaryColor font-bold text-2xl justify-start">
                      {t("head")}
                    </h1>
                    <div className="flex flex-row flex-wrap items-center justify-center">
                      {userData.avatar.heads.map((color) => (
                        <button
                          key={color}
                          className="bg-white w-24 h-20 hover:bg-gray-100 rounded-xl items-center flex justify-center"
                          onClick={() => handlerHeadChange(color)}
                        >
                          <img
                            src={
                              userData.gender === "male"
                                ? maleAvatarImages.heads[color]
                                : femaleAvatarImages.heads[color]
                            }
                            alt="torso"
                            className="mt-16"
                          />
                        </button>
                      ))}
                    </div>
                    <h1 className="text-primaryColor font-bold text-2xl justify-start w-fit">
                      {t("torso")}
                    </h1>
                    <div className="flex flex-row flex-wrap items-center justify-center w-fit">
                      {userData.avatar.torsos.map((color) => (
                        <button
                          key={color}
                          className="bg-white w-24 h-20 hover:bg-gray-100 rounded-xl items-center flex justify-center"
                          onClick={() => handleTorsoChange(color)}
                        >
                          <img
                            src={
                              userData.gender === "male"
                                ? maleAvatarImages.torsos[color]
                                : femaleAvatarImages.torsos[color]
                            }
                            alt="torso"
                          />
                        </button>
                      ))}
                    </div>
                    <h1 className="text-primaryColor font-bold text-2xl justify-start">
                      {t("pants")}
                    </h1>
                    <div className="flex flex-row flex-wrap items-center justify-center">
                      {userData.avatar.legs.map((color) => (
                        <button
                          key={color}
                          className="bg-white w-24 h-20 hover:bg-gray-100 rounded-xl items-center flex justify-center"
                          onClick={() => handlePantsChange(color)}
                        >
                          <img
                            src={
                              userData.gender === "male"
                                ? maleAvatarImages.legs[color]
                                : femaleAvatarImages.legs[color]
                            }
                            alt="legs"
                            className=" mb-12"
                          />
                        </button>
                      ))}
                    </div>
                    <h1 className="text-primaryColor font-bold text-2xl justify-start">
                      {t("boots")}
                    </h1>
                    <div className="flex flex-row flex-wrap items-center justify-center">
                      {userData.avatar.boots.map((color) => (
                        <button
                          key={color}
                          className="bg-white w-24 h-20 hover:bg-gray-100 rounded-xl items-center flex justify-center"
                          onClick={() => handleBootsChange(color)}
                        >
                          <img
                            src={
                              userData.gender === "male"
                                ? maleAvatarImages.boots[color]
                                : femaleAvatarImages.boots[color]
                            }
                            alt="boots"
                            className="mb-20"
                          />
                        </button>
                      ))}
                    </div>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </PageTransition>
      </div>
      <Footer />
    </div>
  );
}
