import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationDE from "./locales/de/translations.json";
import translationSK from "./locales/sk/translations.json";

const resources = {
  de: {
    translation: translationDE,
  },
  sk: {
    translation: translationSK,
  },
};

const language = localStorage.getItem("appLanguage") || "sk";
i18n.use(initReactI18next).init({
  resources,
  lng: language,
  interpolation: {
    escapeValue: false,
  },
});

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: language,
    fallbackLng: language,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
