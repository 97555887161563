export const slideInLeft = {
  initial: {
    opacity: 0,
    x: "-100vw",
  },
  in: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.3, ease: "easeInOut" },
  },
  out: {
    opacity: 0,
    x: "100vw",
    transition: { duration: 0.1, ease: "easeInOut" },
  },
};

export const fadeIn = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};

export const scaleUpEffect = {
  initial: {
    opacity: 0,
    scale: 0.8,
  },
  in: {
    opacity: 1,
    scale: 1,
    transition: { duration: 0.5, ease: "easeInOut" },
  },
  out: {
    opacity: 0,
    scale: 1.2,
    transition: { duration: 0.3, ease: "easeInOut" },
  },
};

export const slideUpEffect = {
  initial: {
    opacity: 0,
    y: 100,
  },
  in: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.5, ease: "easeInOut" },
  },
  out: {
    opacity: 0,
    y: -50,
    transition: { duration: 0.3, ease: "easeInOut" },
  },
};

export const slideUpEffectLoadIn = {
  initial: {
    opacity: 0,
    y: 100,
  },
  in: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.5, ease: "easeInOut" },
  },
  out: {
    opacity: 1,
    transition: { duration: 0.3, ease: "easeInOut" },
  },
};

export const slideUpEffectLoadOut = {
  initial: {
    opacity: 1,
    y: 0,
  },
  in: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.5, ease: "easeInOut" },
  },
  out: {
    opacity: 0,
    y: -50,
    transition: { duration: 0.3, ease: "easeInOut" },
  },
};
export const slideDownEffect = {
  initial: {
    opacity: 0,
    y: -50,
  },
  in: {
    opacity: 1,
    y: 0,
  },
  out: {
    opacity: 0,
    y: 50,
  },
};

export const rotateEffect = {
  initial: {
    opacity: 0,
    rotate: -45,
  },
  in: {
    opacity: 1,
    rotate: 0,
  },
  out: {
    opacity: 0,
    rotate: 45,
  },
};

export const zoomInEffect = {
  initial: {
    opacity: 0,
    scale: 0.5,
  },
  in: {
    opacity: 1,
    scale: 1,
  },
  out: {
    opacity: 0,
    scale: 1.5,
  },
};
