import React from "react";

export default function LoginTextArea({
  value,
  setValue,
  showError,
  errorMessage,
  icon,
  placeholder,
  secondParam = false,
  disabled = false,
  rows = 3,
  width = "w-full",
}) {
  return (
    <div className="w-full">
      <div className={`w-full flex flex-row items-center relative`}>
        <textarea
          disabled={disabled}
          placeholder=""
          value={value}
          rows={rows}
          onChange={(e) => setValue(secondParam ? e : e.target.value)}
          className={`w-full pt-10 pb-5 ${
            showError
              ? "mb-0 border-b-2 border-red-500"
              : "mb-4 border-b-2 border-gray-500"
          } resize-none appearance-none bg-transparent text-gray-700 leading-tight focus:outline-none focus:shadow-outline
          transition-all duration-200 ease-in-out focus:bg-inputFocusBg hover:bg-inputHoverBg hover:border-inputHoverBorder focus:border-inputFocusHover md:hover:bg-inputFocusBg hover:rounded-md focus:rounded-md `}
        />
        <div className="absolute top-0 left-0 flex items-center pl-2">
          <span
            className="material-symbols-outlined"
            style={{ color: "grey", marginRight: "10px" }}
          >
            {icon}
          </span>
          <h1 className="text-xl text-gray-400">{placeholder}</h1>
        </div>
      </div>
      <div
        className={`transition-opacity duration-300 ease-in-out ${
          showError ? "opacity-100 mb-5" : "opacity-0 pointer-events-none h-0"
        }`}
        style={{ transitionProperty: "height, opacity" }}
      >
        {showError && <p className="text-sm text-red-600">{errorMessage}</p>}
      </div>
    </div>
  );
}
