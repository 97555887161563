import { combineReducers } from "redux";
import userReducer from "./userReducer";
import allWordsFilterReducer from "./allWordsFilterReducer";

const rootReducer = combineReducers({
  user: userReducer,
  allWordsFilter: allWordsFilterReducer,
});

export default rootReducer;
