import React, { useMemo } from "react";
import Header from "./header.js";
import Button from "./button.js";
import { exerciseTypes, levelOptions } from "../../util/options.js";
import Modal from "./modal";
import * as animations from "../../util/animations.js";
import PageTransition from "./pageTransition.js";
import { useTranslation } from "react-i18next";
import { deleteExercise } from "../../util/fetch.js";
import { useNavigate } from "react-router-dom";
import Footer from "./footer.js";
import LoginInput from "./LoginInput.js";
import ValidationComboBox from "./validationComboBox.js";

const CreateExerciseBody = ({
  children,
  addButtonOnclick,
  addNewExercise,
  titleValue,
  setTitleValue,
  selectedLevelValue,
  setSelectedLevelValue,
  showModal,
  setShowModal,
  isCorrect,
  isEditing,
  type,
  id,
  titleError,
  levelError,
  hideAddButton = false,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [deleted, setDeleted] = React.useState(false);
  const backFunc = () => {
    if (id) {
      navigate("/home/myExercises");
    } else {
      navigate("/home/createExercise");
    }
  };
  const name = useMemo(() => {
    switch (type) {
      case exerciseTypes.words:
        return t("words");
      case exerciseTypes.readingComprehension:
        return t("readingComprehension");
      case exerciseTypes.order:
        return t("order");
      case exerciseTypes.connection:
        return t("connection");
      case exerciseTypes.multipleChoice:
        return t("multipleChoice");
      case exerciseTypes.sentenceCreation:
        return t("sentenceCreation");
      default:
        return t("words");
    }
  }, [type, t]);
  return (
    <div className="h-fit min-h-screen w-full bg-pageBackground items-center flex flex-col">
      <Header
        navigateTo={() => {
          if (id) {
            navigate("/home/myExercises");
          } else {
            navigate("/home/createExercise");
          }
        }}
      />
      <div className="flex-1">
        <Modal
          showModal={showModal}
          onClick={() => {
            isCorrect
              ? backFunc()
              : deleted
              ? navigate("/home/myExercises")
              : setShowModal(false);
          }}
          buttonText={t("confirm")}
        >
          <h1 className="font-bold text-5xl mb-5 text-headerText">
            {isCorrect
              ? t("success")
              : deleted
              ? t("deleteSucces")
              : t("error")}
          </h1>
          <p className="text-secondaryColor text-xl mb-5">
            {isCorrect
              ? t("exerciseCreated")
              : deleted
              ? t("deleteSuccesText")
              : t("exerciseNotCreated")}
          </p>
        </Modal>
        <PageTransition
          variants={animations.slideUpEffect}
          className="h-full w-full bg-neutral  flex flex-col flex-1 p-8"
        >
          <div className="bg-cardBg p-10 rounded-lg flex flex-col m-10">
            {isEditing && (
              <Button
                icon={"delete"}
                buttonType={"danger"}
                onClick={() => {
                  deleteExercise(id, type)
                    .then(() => console.log("Delete successful"))
                    .catch((error) => console.error("Delete failed", error));
                  setDeleted(true);
                  setShowModal(true);
                }}
                width="w-[50px]"
                padding="p-3"
                extraStyle="mt-5 bg-red-500 hover:bg-red-700 text-white"
              />
            )}

            <h1 className="flex justify-between mb-5 font-bold text-3xl">
              {t("newExercise") + " - " + name}
            </h1>
            <LoginInput
              icon={"description"}
              placeholder={t("nameOfExercise")}
              value={titleValue}
              setValue={setTitleValue}
              isError={false}
              width="w-full"
              showError={titleError}
              errorMessage={t("nameError")}
            />
            <ValidationComboBox
              title={t("chooseLevel")}
              icon={"speed"}
              width="w-full"
              options={levelOptions}
              selectedValue={selectedLevelValue}
              setSelectedValue={setSelectedLevelValue}
              hasError={levelError}
              errorMessage={t("levelError")}
            />

            {children}
            <div className="flex flex-col items-center">
              {!hideAddButton && (
                <Button
                  icon={"add"}
                  buttonType={"cta"}
                  text={t("add")}
                  onClick={addButtonOnclick}
                />
              )}
              <Button
                icon={"post_add"}
                text={isEditing ? t("save") : t("create")}
                onClick={addNewExercise}
                buttonType={"cta"}
                extraStyle="mt-5"
              />
            </div>
          </div>
        </PageTransition>
      </div>
      <Footer />
    </div>
  );
};

export default CreateExerciseBody;
