import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import CreateExercisePage from "./pages/CreateExercises/CreateExercisePage.js";
import Profile from "./pages/ProfilePage";
import ChooseExerciseTypePage from "./pages/Exercises/ChooseExerciseTypePage.js";
import MyExercises from "./pages/MyExercisesPage";
import WordExercise from "./pages/Exercises/WordExercise.js";
import LandingPage from "./pages/LandingPage";
import ReadingExercise from "./pages/Exercises/ReadingExercise.js";
import MultipleChoiceExercise from "./pages/Exercises/MultipleChoiceExercise.js";
import OrderExercise from "./pages/Exercises/OrderExercise.js";
import ConnectionExercise from "./pages/Exercises/ConnectionExercise.js";
import SentenceCreationExercise from "./pages/Exercises/SentenceCreationExercise.js";
import RandomExercisePage from "./pages/RandomExercisePage/index.js";
import StatisticsPage from "./pages/StatisticsPage/index.js";
import RegisterPage from "./pages/RegisterPage/index.js";
import PasswordReset from "./pages/PasswordResetPage/index.js";
import PasswordResetConfirm from "./pages/PasswordResetConfirmPage/index.js";
import CreditsPage from "./pages/CreditsPage/index.js";
import CreateWordExercisePage from "./pages/CreateExercises/CreateWordExercisePage.js";
import CreateReadingExercisePage from "./pages/CreateExercises/CreateReadingExercisePage.js";
import AvatarPage from "./pages/AvatarPage/index.js";
import RequestsPage from "./pages/RequestsPage/index.js";
import CreateSentenceCreationExercise from "./pages/CreateExercises/CreateSentenceCreationExercisePage.js";
import CreateMultipleChoiceExercise from "./pages/CreateExercises/CreateMultipleChoicePage.js";
import CreateOrderExercise from "./pages/CreateExercises/CreateOrderExercisePage.js";
import CreateConnectionExercise from "./pages/CreateExercises/CreateConnectionExercisePage.js";
import AllExercises from "./pages/Exercises/AllExercisesPage.js";

const routes = [
  { path: "/", component: LandingPage, exact: true },
  { path: "/login", component: LoginPage, exact: true },
  { path: "/register", component: RegisterPage, exact: true },
  { path: "/passwordReset", component: PasswordReset, exact: true },
  {
    path: "/passwordResetConfirm",
    component: PasswordResetConfirm,
    exact: true,
  },

  { path: "/home", component: HomePage },
  { path: "/home/credits", component: CreditsPage },
  {
    path: "/home/requests",
    component: RequestsPage,
    isProtected: true,
    roles: ["admin"],
  },
  {
    path: "/home/avatar",
    component: AvatarPage,
    isProtected: true,
    roles: ["admin", "teacher", "user"],
  },

  {
    path: "/home/createExercise",
    component: CreateExercisePage,
    isProtected: true,
    roles: ["admin", "teacher"],
  },
  {
    path: "/home/createExercise/words",
    component: CreateWordExercisePage,
    isProtected: true,
    roles: ["admin", "teacher"],
  },
  {
    path: "/home/createExercise/readingComprehension",
    component: CreateReadingExercisePage,
    isProtected: true,
    roles: ["admin", "teacher"],
  },
  {
    path: "/home/createExercise/sentenceCreation",
    component: CreateSentenceCreationExercise,
    isProtected: true,
    roles: ["admin", "teacher"],
  },
  {
    path: "/home/createExercise/multipleChoice",
    component: CreateMultipleChoiceExercise,
    isProtected: true,
    roles: ["admin", "teacher"],
  },
  {
    path: "/home/createExercise/order",
    component: CreateOrderExercise,
    isProtected: true,
    roles: ["admin", "teacher"],
  },
  {
    path: "/home/createExercise/connection",
    component: CreateConnectionExercise,
    isProtected: true,
    roles: ["admin", "teacher"],
  },

  {
    path: "/home/myExercises",
    component: MyExercises,
    isProtected: true,
    roles: ["admin", "teacher"],
  },
  {
    path: "/home/myExercises/editExersice/words/:exerciseId",
    component: CreateWordExercisePage,
    isProtected: true,
    roles: ["admin", "teacher"],
  },
  {
    path: "/home/myExercises/editExersice/readingComprehension/:exerciseId",
    component: CreateReadingExercisePage,
    isProtected: true,
    roles: ["admin", "teacher"],
  },
  {
    path: "/home/myExercises/editExersice/connection/:exerciseId",
    component: CreateConnectionExercise,
    isProtected: true,
    roles: ["admin", "teacher"],
  },
  {
    path: "/home/myExercises/editExersice/sentenceCreation/:exerciseId",
    component: CreateSentenceCreationExercise,
    isProtected: true,
    roles: ["admin", "teacher"],
  },
  {
    path: "/home/myExercises/editExersice/order/:exerciseId",
    component: CreateOrderExercise,
    isProtected: true,
    roles: ["admin", "teacher"],
  },
  {
    path: "/home/myExercises/editExersice/multipleChoice/:exerciseId",
    component: CreateMultipleChoiceExercise,
    isProtected: true,
    roles: ["admin", "teacher"],
  },

  {
    path: "/home/profile",
    component: Profile,
    isProtected: true,
    roles: ["admin", "teacher", "user"],
  },
  {
    path: "/home/statistics",
    component: StatisticsPage,
    isProtected: true,
    roles: ["admin", "teacher", "user"],
  },
  {
    path: "/home/exercises",
    component: ChooseExerciseTypePage,
    isProtected: true,
    roles: ["admin", "teacher", "user"],
  },
  {
    path: "/home/exercises/random",
    component: RandomExercisePage,
    isProtected: true,
    roles: ["admin", "teacher", "user"],
  },
  {
    path: "/home/exercises/:typeFilter",
    component: AllExercises,
    isProtected: true,
    roles: ["admin", "teacher", "user"],
  },
  {
    path: "/home/exercises/words/:exerciseId",
    component: WordExercise,
    isProtected: true,
    roles: ["admin", "teacher", "user"],
  },
  {
    path: "/home/exercises/readingComprehension/:exerciseId",
    component: ReadingExercise,
    isProtected: true,
    roles: ["admin", "teacher", "user"],
  },
  //
  {
    path: "/home/exercises/multipleChoice/:exerciseId",
    component: MultipleChoiceExercise,
    isProtected: true,
    roles: ["admin", "teacher", "user"],
  },
  {
    path: "/home/exercises/order/:exerciseId",
    component: OrderExercise,
    isProtected: true,
    roles: ["admin", "teacher", "user"],
  },
  {
    path: "/home/exercises/connection/:exerciseId",
    component: ConnectionExercise,
    isProtected: true,
    roles: ["admin", "teacher", "user"],
  },
  {
    path: "/home/exercises/sentenceCreation/:exerciseId",
    component: SentenceCreationExercise,
    isProtected: true,
    roles: ["admin", "teacher", "user"],
  },
];

export default routes;
