import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const DraggableList = ({ listItems, setListItems }) => {
  const [isVertical, setIsVertical] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsVertical(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return; // dropped outside the list
    }

    const items = reorder(
      listItems,
      result.source.index,
      result.destination.index
    );
    setListItems(items);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId="droppable"
        direction={isVertical ? "vertical" : "horizontal"}
      >
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={`flex ${
              isVertical ? "flex-col" : "flex-wrap"
            } gap-2 p-2`}
          >
            {listItems.map((item, index) => (
              <Draggable
                key={index}
                draggableId={`item-${index}`}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={`rounded-lg flex items-center justify-center text-white font-bold ${
                      snapshot.isDragging ? "bg-ctaHover" : "bg-ctaPrimary"
                    } select-none p-4 m-0 mr-2 mb-0 mt-0`}
                  >
                    {item}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableList;
