import React from "react";
import { db } from "../../config/firebase";
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import Button from "../../components/shared/button";
import Header from "../../components/shared/header";
import Footer from "../../components/shared/footer";
import LoadingPage from "../../components/shared/loadingPage";
import { useTranslation } from "react-i18next";
import PageTransition from "../../components/shared/pageTransition";

const RequestsPage = () => {
  const { t } = useTranslation();
  const [requests, setRequests] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const navigate = useNavigate();

  React.useEffect(() => {
    const fetchRequests = async () => {
      setIsLoading(true);
      try {
        const querySnapshot = await getDocs(collection(db, "teacher_requests"));
        const requestsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setRequests(requestsData);
      } catch (error) {
        console.error("Error fetching teacher requests:", error);
      }
      setIsLoading(false);
    };

    fetchRequests();
  }, []);

  const handleApprove = async (id, userId) => {
    const userRef = doc(db, "users", userId);
    await updateDoc(userRef, {
      role: "teacher",
    });
    const requestRef = doc(db, "teacher_requests", id);
    await deleteDoc(requestRef);
    setRequests(requests.filter((request) => request.id !== id));
  };

  const handleDisapprove = async (id) => {
    const requestRef = doc(db, "teacher_requests", id);
    await deleteDoc(requestRef);
    setRequests(requests.filter((request) => request.id !== id));
  };

  return isLoading ? (
    <LoadingPage />
  ) : (
    <div className="bg-pageBackground h-fit min-h-screen flex flex-col">
      <Header navigateTo={() => navigate("/home")} />
      <PageTransition extraStyles="flex flex-col items-center p-10 flex-1">
        {requests.length > 0 ? (
          requests.map((request) => (
            <div
              key={request.id}
              className="bg-white p-5 m-5 rounded-lg shadow-lg w-full max-w-md"
            >
              <h2 className="text-xl font-bold mb-2">{t("request")}</h2>
              <p>
                <strong>{t("name")}</strong> {request.name}
              </p>
              <p>
                <strong>Email:</strong> {request.email}
              </p>
              <p>
                <strong>{t("motivation")}</strong> {request.motivation}
              </p>
              <p>
                <strong>{t("experience")}</strong> {request.experience}
              </p>
              <p>
                <strong>{t("qualifications")}</strong> {request.qualifications}
              </p>
              <div className="flex justify-end mt-4 flex-col items-center">
                <Button
                  icon={"check"}
                  buttonType={"success"}
                  text={t("approve")}
                  onClick={() => handleApprove(request.id, request.userId)}
                  extraStyle="bg-green-400 hover:bg-green-600"
                />
                <Button
                  icon={"close"}
                  buttonType={"danger"}
                  text={t("disapprove")}
                  extraStyle="mt-5 bg-red-500 hover:bg-red-300"
                  onClick={() => handleDisapprove(request.id)}
                />
              </div>
            </div>
          ))
        ) : (
          <p className="text-3xl font-bold text-primaryColor">
            {t("noRequests")}
          </p>
        )}
      </PageTransition>
      <Footer />
    </div>
  );
};

export default RequestsPage;
