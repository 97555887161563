import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setFilter } from "../../actions/allWordsFilterActions";
import ComboBox from "./combobox";
import { exerciseTypesOptions, levelOptions } from "../../util/options";
import Input from "./input";
import { motion, AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";

const Filter = ({ defaultFilter = null }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { name, createdBy, level, type } = useSelector(
    (state) => state.allWordsFilter
  );

  const [filter, setFilterState] = React.useState({
    name: name || defaultFilter?.name,
    createdBy: createdBy || defaultFilter?.createdBy,
    level: level || defaultFilter?.level,
    type: type || defaultFilter?.type,
  });

  const [showFilter, setShowFilter] = React.useState(false);

  const handleNameChange = (event) => {
    setFilterState({ ...filter, name: event.target.value });
    dispatch(setFilter({ name: event.target.value }));
  };

  const handleCreatedByChange = (event) => {
    setFilterState({ ...filter, createdBy: event.target.value });
    dispatch(setFilter({ createdBy: event.target.value }));
  };

  const handleLevelChange = (level) => {
    setFilterState({ ...filter, level: level });
    dispatch(setFilter({ level: level }));
  };

  const handleTypeChange = (type) => {
    setFilterState({ ...filter, type: type });
    dispatch(setFilter({ type: type }));
  };
  const containerVariants = {
    hidden: { opacity: 0, height: 0, overflow: "hidden" },
    visible: { opacity: 1, height: "auto", transition: { duration: 0.5 } },
  };

  return (
    <div className="bg-white p-5 rounded-md mb-5 w-full">
      <div
        className="flex flex-row justify-between cursor-pointer items-center "
        onClick={() => setShowFilter(!showFilter)}
      >
        <h1 className="font-bold text-3xl">Filter</h1>
        <div className="flex flex-row">
          <span className="material-symbols-outlined">
            {showFilter ? "expand_less" : "expand_more"}
          </span>
          <div>{showFilter ? t("hideFilter") : t("showFilter")}</div>
        </div>
      </div>
      <AnimatePresence>
        {showFilter && (
          <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={containerVariants}
            className="flex flex-col md:flex-row mt-4"
          >
            {/* Filter Fields */}
            <div className="mb-4 md:mb-0 md:mr-4">
              <h1 className="font-bold">{t("name")}</h1>
              <Input
                type="text"
                placeholderName={t("nameOfExercise")}
                onChangeFunction={handleNameChange}
                value={filter.name}
                width="w-full"
              />
            </div>
            <div className="mb-4 md:mb-0 md:mr-4">
              <h1 className="font-bold">{t("createdBy")}</h1>
              <Input
                type="text"
                placeholderName={t("createdBy")}
                onChangeFunction={handleCreatedByChange}
                value={filter.createdBy}
                width="w-full"
              />
            </div>
            <div className="mb-4 md:mb-0 md:mr-4">
              <h1 className="font-bold">{t("level")}</h1>
              <ComboBox
                options={levelOptions}
                selectedValue={filter.level}
                setSelectedValue={handleLevelChange}
                title={t("noFilter")}
                width="w-full"
              />
            </div>
            <div className="mb-4 md:mb-0 md:mr-4">
              <h1 className="font-bold">{t("type")}</h1>
              <ComboBox
                options={exerciseTypesOptions(t)}
                selectedValue={filter.type}
                setSelectedValue={handleTypeChange}
                title={t("noFilter")}
                width="w-full"
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Filter;
