import React from "react";
import { useParams } from "react-router-dom";
import ExerciseBody from "../../components/shared/ExerciseBody";
import ComboBox from "../../components/shared/combobox";
import LoadingPage from "../../components/shared/loadingPage";
import ReadingBook from "../../img/readingBook.png";
import { fetchReadingExerciseWithTasks } from "../../util/fetch";
import { exerciseTypes, taskState } from "../../util/options";
import { useTranslation } from "react-i18next";

const ReadingExercise = () => {
  const { t } = useTranslation();
  const { exerciseId } = useParams();
  const [exercise, setExercise] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [taskInputs, setTaskInputs] = React.useState([]);
  // eslint-disable-next-line
  const [stats, setStats] = React.useState({ correct: 0, total: 0 });
  const [showModal, setShowModal] = React.useState(false);

  React.useEffect(() => {
    fetchReadingExerciseWithTasks(
      setIsLoading,
      exerciseId,
      setExercise,
      setTaskInputs,
      setStats
    );
    // eslint-disable-next-line
  }, []);

  const checkInputs = () => {
    let correct = true;
    exercise.readingTasks.forEach((task, index) => {
      if (task.truthness !== taskInputs[index].truthness) {
        correct = false;
        const newTaskInputs = [...taskInputs];
        newTaskInputs[index].taskState = taskState.incorrect;
        setTaskInputs(newTaskInputs);
      }

      if (task.truthness === taskInputs[index].truthness) {
        const newTaskInputs = [...taskInputs];
        newTaskInputs[index].taskState = taskState.correct;
        setTaskInputs(newTaskInputs);
      }
    });
    if (correct) {
      setShowModal(true);
      return true;
    } else {
      return false;
    }
  };

  return isLoading || !taskInputs ? (
    <LoadingPage />
  ) : (
    <ExerciseBody
      exerciseTypeTitle={t("readingComprehension")}
      icon={ReadingBook}
      showModal={showModal}
      exerciseType={exerciseTypes.readingComprehension}
      navigateString="/home/exercises/reading"
      defaultDescription={t("readingComprehensionDefaultDescription")}
      exercise={exercise}
      checkInputs={checkInputs}
    >
      <div className="flex flex-col items-start mt-5 w-full mb-5">
        <div className="flex flex-row">
          <span className="material-symbols-outlined">description</span>
          <h1 className="font-bold">Text</h1>
        </div>

        <h2 className="text-xl font-thin mr-3">{exercise.text}</h2>
      </div>
      {exercise.readingTasks &&
        exercise.readingTasks.map((task, index) => {
          console.log(
            "BOOLEAN: ",
            taskInputs[index].taskState === taskState.incorrect,
            taskInputs[index].taskState,
            taskState.incorrect
          );
          return (
            <div key={index} className="flex flex-row items-center w-full">
              <span className="mr-2 text-xl flex-2">
                {index + 1}. {task.sentence}
              </span>
              <ComboBox
                title={t("choose")}
                selectedValue={taskInputs[index].truthness ?? ""}
                width="w-full"
                setSelectedValue={(value) => {
                  const newReadingTask = [...taskInputs];
                  newReadingTask[index].truthness = value;
                  setTaskInputs(newReadingTask);
                }}
                options={[
                  { value: true, label: t("true") },
                  { value: false, label: t("false") },
                ]}
                isCorrect={taskInputs[index].taskState === taskState.correct}
                isError={taskInputs[index].taskState === taskState.incorrect}
              />

              {taskInputs[index].taskState === taskState.incorrect && (
                <span
                  className="material-symbols-outlined"
                  style={{
                    color: "red",
                  }}
                >
                  close
                </span>
              )}
              {taskInputs[index].taskState === taskState.correct && (
                <span
                  className="material-symbols-outlined"
                  style={{
                    color: "green",
                  }}
                >
                  done
                </span>
              )}
            </div>
          );
        })}
    </ExerciseBody>
  );
};

export default ReadingExercise;
