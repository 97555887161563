import React from "react";

const ComboBox = ({
  title,
  options,
  selectedValue,
  setSelectedValue,
  extraStyles = "",
  width = "w-[320px]",
  border = "border-b-2 border-gray-500",
  text = "text-xl ",
  disabled = false,
  isError = false,
  isCorrect = false,
}) => {
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const errorStyles = isError
    ? "border-b-2 border-red-500"
    : isCorrect
    ? "border-b-2 border-green-500"
    : border;

  return (
    <div className="flex flex-col items-center justify-center">
      <select
        disabled={disabled}
        value={selectedValue || ""}
        onChange={handleChange}
        className={`${width} p-2.5 h-[55px] rounded-none hover:rounded-md ${errorStyles} focus:rounded-md outline-none bg-transparent font-roboto ${text} ${extraStyles} focus:bg-inputFocusBg hover:bg-inputHoverBg hover:border-inputHoverBorder focus:border-inputFocusHover md:hover:bg-inputFocusBg rounded-md px-4 py-2 outline-none transition-colors duration-200 ease-in-out`}
      >
        <option value="">{title}</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default ComboBox;
