import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { confirmPasswordReset } from "firebase/auth";
import { auth } from "../../config/firebase";
import Button from "../../components/shared/button";
import { useTranslation } from "react-i18next";
import LoginInput from "../../components/shared/LoginInput";

const PasswordResetConfirm = () => {
  const { t } = useTranslation();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const location = useLocation();

  const actionCode = new URLSearchParams(location.search).get("oobCode");

  useEffect(() => {
    if (!actionCode) {
      setError("Error: No or invalid action code provided.");
    }
  }, [actionCode]);

  const handlePasswordUpdate = async () => {
    if (newPassword !== confirmPassword) {
      setError("The passwords do not match. Please try again.");
      return;
    }

    if (newPassword.length < 6) {
      setError("Password should be at least 6 characters long.");
      return;
    }

    try {
      await confirmPasswordReset(auth, actionCode, newPassword);
      setMessage("Your password has been reset successfully.");
      setError("");
    } catch (error) {
      setError(
        "Failed to reset password. Please ensure the link is correct or request a new one."
      );
      console.error("Password reset error:", error);
    }
  };

  return (
    <div className="password-reset-container">
      <h1 className="text-xl font-semibold mb-4">{t("createNewPassword")}</h1>
      <LoginInput
        inputType="password"
        value={newPassword}
        icon={"lock"}
        placeholder={t("newPassword")}
        setValue={setNewPassword}
        border={error ? "border-red-400" : "border-gray-400"}
        extraStyles="mb-3"
      />
      <LoginInput
        inputType="password"
        value={confirmPassword}
        icon={"lock"}
        placeholder={t("passwordConfirmation")}
        setValue={setConfirmPassword}
        border={error ? "border-red-400" : "border-gray-400"}
        extraStyles="mb-3"
      />

      {message && <p className="text-green-500">{message}</p>}
      {error && <p className="text-red-500">{error}</p>}
      <Button
        onClick={handlePasswordUpdate}
        text={t("resetPassword")}
        buttonType="cta"
      />
    </div>
  );
};

export default PasswordResetConfirm;
