import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../components/shared/button";
import CreateExerciseBody from "../../components/shared/createExerciseBody";
import { db } from "../../config/firebase";
import { exerciseTypes } from "../../util/options";
import Textarea from "../../components/shared/textarea";
import LoginInput from "../../components/shared/LoginInput";
import ValidationComboBox from "../../components/shared/validationComboBox";

const CreateReadingExercise = () => {
  const { t } = useTranslation();
  const { exerciseId } = useParams();

  const navigate = useNavigate();

  const backFunc = () => {
    if (exerciseId) {
      navigate("home/myExercise/");
    } else {
      navigate("/home/createExercise");
    }
  };

  const userData = useSelector((state) => state.user.userData);

  const [readingTasks, setReadingTasks] = React.useState([
    { sentence: "", truthness: null },
  ]);
  const [selectedLevelValue, setSelectedLevelValue] = React.useState("");
  const [titleValue, setTitleValue] = React.useState("");
  const [text, setText] = React.useState("");
  const [showModal, setShowModal] = React.useState(false);
  const [isCorrect, setIsCorrect] = React.useState(false);
  const [titleError, setTitleError] = React.useState(false);
  const [levelError, setLevelError] = React.useState(false);
  const [textError, setTextError] = React.useState(false);
  const [ReadingTasksError, setReadingTasksError] = React.useState([]);

  React.useEffect(() => {
    const fetchExercise = async () => {
      if (exerciseId) {
        const docRef = doc(db, "excercise", exerciseId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const exerciseData = docSnap.data();
          setTitleValue(exerciseData.name || "");
          setSelectedLevelValue(exerciseData.level || "");
          setText(exerciseData.text || "");

          const tasksCollectionRef = collection(docRef, "ReadingTasks");
          const querySnapshot = await getDocs(tasksCollectionRef);

          const tasks = querySnapshot.docs
            .map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))
            .sort((a, b) => a.order - b.order);

          setReadingTasks(tasks);
        } else {
          console.log("No such document!");
        }
      }
    };

    fetchExercise();
  }, [exerciseId]);

  const checkInputs = () => {
    let correct = true;
    if (titleValue === "") {
      setTitleError(true);
      correct = false;
    } else {
      setTitleError(false);
    }
    if (selectedLevelValue === "") {
      setLevelError(true);
      correct = false;
    } else {
      setLevelError(false);
    }

    if (text === "") {
      setTextError(true);
      correct = false;
    } else {
      setTextError(false);
    }

    const errors = readingTasks.map((task) => {
      if (
        task.sentence === "" ||
        task.truthness === "" ||
        task.truthness === null
      ) {
        correct = false;
      }
      return {
        sentence: task.sentence === "",
        truthness: task.truthness === "" || task.truthness === null,
      };
    });
    setReadingTasksError(errors);
    console.log(errors);
    return correct;
  };

  const addButtonOnClick = async () => {
    const newReadingTask = [...readingTasks];
    newReadingTask.push({ sentence: "", truthness: null });
    setReadingTasks(newReadingTask);
  };

  const addNewExercise = async () => {
    if (checkInputs()) {
      let exerciseDocRef;
      try {
        if (exerciseId) {
          const wordTasksCollectionRef = collection(
            db,
            "excercise",
            exerciseId,
            "ReadingTasks"
          );
          const querySnapshot = await getDocs(wordTasksCollectionRef);

          const deletePromises = querySnapshot.docs.map((docSnapshot) => {
            return deleteDoc(
              doc(db, "excercise", exerciseId, "ReadingTasks", docSnapshot.id)
            );
          });

          await Promise.all(deletePromises);
          exerciseDocRef = doc(db, "excercise", exerciseId);
          await updateDoc(exerciseDocRef, {
            name: titleValue,
            level: selectedLevelValue,
            type: exerciseTypes.readingComprehension,
            createdBy: userData.uid,
            createdByName: userData.firstname,
            createdByLastName: userData.lastname,
            createdByNickname: userData.nickname,
            text: text,
          });
        } else {
          exerciseDocRef = await addDoc(collection(db, "excercise"), {
            name: titleValue,
            level: selectedLevelValue,
            type: exerciseTypes.readingComprehension,
            createdAt: new Date(),
            createdBy: userData.uid,
            createdByName: userData.firstname,
            createdByLastName: userData.lastname,
            createdByNickname: userData.nickname,
            text: text,
          });
        }

        const tasksCollectionRef = collection(
          db,
          "excercise",
          exerciseDocRef.id,
          "ReadingTasks"
        );
        await Promise.all(
          readingTasks.map((readingTask, index) =>
            addDoc(tasksCollectionRef, {
              sentence: readingTask.sentence,
              truthness: readingTask.truthness,
              order: index,
            })
          )
        );

        console.log(
          "Exercise and word tasks created/updated successfully with ID: ",
          exerciseDocRef.id || exerciseId
        );
        setIsCorrect(true);
        setShowModal(true);
      } catch (e) {
        console.error("Error adding/updating document and word tasks: ", e);
        setIsCorrect(false);
        // setShowModal(true);
      }
    } else {
      console.log(
        "Error adding/updating document and word tasks: Missing or invalid inputs."
      );
      setIsCorrect(false);
      // setShowModal(true);
    }
  };

  return (
    <CreateExerciseBody
      addButtonOnclick={addButtonOnClick}
      addNewExercise={addNewExercise}
      backFunc={backFunc}
      titleValue={titleValue}
      setTitleValue={setTitleValue}
      selectedLevelValue={selectedLevelValue}
      setSelectedLevelValue={setSelectedLevelValue}
      showModal={showModal}
      setShowModal={setShowModal}
      isCorrect={isCorrect}
      isEditing={exerciseId ? true : false}
      type={exerciseTypes.readingComprehension}
      id={exerciseId}
      titleError={titleError}
      levelError={levelError}
    >
      <Textarea
        value={text}
        setValue={setText}
        icon={"description"}
        placeholder={"Text"}
        showError={textError}
        errorMessage={t("textError")}
      />
      {readingTasks.map((readingTask, index) => (
        <div key={index} className="flex flex-col items-start mb-5">
          <h1 className="font-bold text-2xl">
            {index + 1}. {t("task")}
          </h1>
          <div className="flex flex-row items-center bg-gray-100 p-4 rounded-lg">
            <div>
              <LoginInput
                value={readingTask.sentence}
                setValue={(value) => {
                  const newReadingTask = [...readingTasks];
                  newReadingTask[index].sentence = value;
                  setReadingTasks(newReadingTask);
                }}
                icon={"description"}
                placeholder={t("sentence")}
                showError={ReadingTasksError[index]?.sentence}
                errorMessage={t("sentenceError")}
              />
              <ValidationComboBox
                icon={"done_outline"}
                title={t("truthness")}
                selectedValue={readingTask.truthness ?? ""}
                width="w-full flex-1"
                setSelectedValue={(value) => {
                  const newReadingTask = [...readingTasks];
                  newReadingTask[index].truthness = value;
                  setReadingTasks(newReadingTask);
                }}
                options={[
                  { value: true, label: t("true") },
                  { value: false, label: t("false") },
                ]}
                hasError={ReadingTasksError[index]?.truthness}
                errorMessage={t("truthnessError")}
              />
            </div>
            <Button
              icon={"delete"}
              buttonType={"cta"}
              text={""}
              width={"w-[50px]"}
              heigth="h-[50px]"
              padding="p-3"
              onClick={() => {
                const newWordTasks = [...readingTasks];
                newWordTasks.splice(index, 1);
                setReadingTasks(newWordTasks);
              }}
            />
          </div>
        </div>
      ))}
    </CreateExerciseBody>
  );
};
export default CreateReadingExercise;
