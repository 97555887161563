import {
  createUserWithEmailAndPassword,
  sendSignInLinkToEmail,
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import actionCodeSettings from "../../config/actioncodeSettings";
import { auth, db } from "../../config/firebase";
import "../../pages/LoginPage/loginPage.css";
import { levelOptions, genderOptions } from "../../util/options";
import LoginInput from "../shared/LoginInput";
import ActivityIndicator from "../shared/activityIndicator";
import Button from "../shared/button";
import { useTranslation } from "react-i18next";
import ValidationComboBox from "../shared/validationComboBox";

const SignIn = () => {
  const { t } = useTranslation();
  const [email, setEmail] = React.useState("");
  const [firstname, setFirstname] = React.useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [lastname, setLastname] = React.useState("");
  const [userName, setUserName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [gender, setGender] = React.useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [emailError, setEmailError] = useState({
    value: false,
    message: "",
  });
  const [firstNameError, setFirstNameError] = useState({
    value: false,
    message: "",
  });
  const [lastNameError, setLastNameError] = useState({
    value: false,
    message: "",
  });
  const [userNameError, setUserNameError] = useState({
    value: false,
    message: "",
  });
  const [passwordError, setPasswordError] = useState({
    value: false,
    message: "",
  });
  const [confirmPasswordError, setConfirmPasswordError] = useState({
    value: false,
    message: "",
  });
  const [genderError, setGenderError] = useState({ value: false, message: "" });
  const [levelError, setLevelError] = useState({ value: false, message: "" });

  const navigate = useNavigate();

  const sendEmailVerification = (user) => {
    sendSignInLinkToEmail(auth, user.email, actionCodeSettings)
      .then(() => {
        setEmailSent(true);
      })
      .catch((error) => {
        console.error("Error sending email verification:", error);
      });
  };
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      setEmailError({ value: true, message: t("emailRequired") });
      return true;
    } else if (!emailRegex.test(email)) {
      setEmailError({ value: true, message: t("invalidEmail") });
      return true;
    } else {
      setEmailError({ value: false, message: "" });
      return false;
    }
  };

  const checkInput = (inputValue, setError, errorMessageKey, fieldName) => {
    if (!inputValue) {
      setError({ value: true, message: t(errorMessageKey) });
      return true;
    } else {
      setError({ value: false, message: "" });
      return false;
    }
  };

  const checkInputs = () => {
    let error = true;
    error = validateEmail(email) || error;
    error =
      checkInput(firstname, setFirstNameError, "firstNameRequired") || error;
    error = checkInput(lastname, setLastNameError, "lastNameRequired") || error;
    error = checkInput(userName, setUserNameError, "usernameRequired") || error;
    error = checkInput(password, setPasswordError, "passwordRequired") || error;
    error =
      checkInput(
        confirmPassword,
        setConfirmPasswordError,
        "passwordConfirmationRequired"
      ) || error;
    error = checkInput(gender, setGenderError, "genderRequired") || error;
    error = checkInput(selectedValue, setLevelError, "levelRequired") || error;
    if (password !== confirmPassword) {
      setPasswordError({ value: true, message: t("passwordsDoNotMatch") });
      setConfirmPasswordError({
        value: true,
        message: t("passwordsDoNotMatch"),
      });
      error = true;
    } else if (!error) {
      setPasswordError({ value: false, message: "" });
      setConfirmPasswordError({ value: false, message: "" });
    }

    return error;
  };

  const [loading, setLoading] = React.useState(false);

  const signIn = async () => {
    setLoading(true);
    const check = checkInputs();
    if (check) {
      try {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        sendEmailVerification(userCredential.user);
        const user = userCredential.user;
        console.log("Registered user");
        await saveUserProfile(user.uid, {
          firstname: firstname,
          lastname: lastname,
          nickname: userName,
          level: selectedValue,
          uid: user.uid,
          gender: gender,
          email: email,
          role: "user",
          stats: {
            exercisesCompleted: 0,
            exercisesCreated: 0,
            wordExercisesCompleted: 0,
            readingExercisesCompleted: 0,
            grammarExercisesCompleted: 0,
          },
          avatar: {
            heads: ["default"],
            torsos: ["default"],
            legs: ["default"],
            boots: ["default"],
          },
        });
      } catch (error) {
        if (error.code === "auth/email-already-in-use") {
          setEmailError({ value: true, message: t("emailAlreadyInUse") });
        }

        if (error.code === "auth/weak-password") {
          setPasswordError({ value: true, message: t("weakPassword") });
        }
        console.error(error.code);
      }
    }
    setLoading(false);
  };

  const saveUserProfile = (uid, profileData) => {
    const userRef = doc(db, "users", uid);
    return setDoc(userRef, profileData);
  };

  return (
    <div className="items-center flex flex-col justify-center w-full sm:w-fit h-full sm:h-fit bg-cardBg rounded-3xl p-10 shadow-md ">
      {!emailSent && (
        <div className="items-center flex flex-col h-full flex-grow">
          <h1 className="text-3xl mb-5 font-bold text-primaryColor">
            {t("registration")}
          </h1>
          <LoginInput
            icon={"person"}
            value={email}
            setValue={setEmail}
            placeholder={"Email"}
            showError={emailError.value}
            errorMessage={emailError.message}
          />
          <LoginInput
            icon={"badge"}
            value={firstname}
            setValue={setFirstname}
            placeholder={t("firstname")}
            showError={firstNameError.value}
            errorMessage={firstNameError.message}
          />
          <LoginInput
            icon={"badge"}
            placeholder={t("lastname")}
            value={lastname}
            setValue={setLastname}
            showError={lastNameError.value}
            errorMessage={lastNameError.message}
          />
          <LoginInput
            icon={"badge"}
            placeholder={t("nickname")}
            value={userName}
            setValue={setUserName}
            showError={userNameError.value}
            errorMessage={userNameError.message}
          />

          <ValidationComboBox
            icon={"wc"}
            title={t("gender")}
            options={genderOptions}
            hasError={genderError.value}
            selectedValue={gender}
            setSelectedValue={setGender}
            errorMessage={genderError.message}
            width="w-full"
          />

          <ValidationComboBox
            icon={"speed"}
            title={t("levelOfGerman")}
            options={levelOptions}
            hasError={levelError.value}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            errorMessage={levelError.message}
            width="w-full"
          />

          <LoginInput
            icon={"lock"}
            placeholder={t("password")}
            value={password}
            setValue={setPassword}
            inputType={"password"}
            showError={passwordError.value}
            errorMessage={passwordError.message}
          />
          <LoginInput
            icon={"done_all"}
            placeholder={t("passwordConfirmation")}
            value={confirmPassword}
            setValue={setConfirmPassword}
            inputType={"password"}
            showError={confirmPasswordError.value}
            errorMessage={confirmPasswordError.message}
          />
          {loading ? (
            <ActivityIndicator color={"black"} />
          ) : (
            <Button
              text={t("register")}
              onClick={signIn}
              buttonType={"cta"}
              extraStyle="mt-5 mb-2"
            />
          )}
          <div className="flex flex-row items-center">
            <p className="mr-2"> {t("alreadyHaveAccount")}</p>
            <button
              className="signIn-button"
              onClick={() => navigate("/login")}
            >
              <div className="text-ctaPrimary   hover:underline">
                <p>{t("login")}</p>
              </div>
            </button>
          </div>
        </div>
      )}
      {emailSent && (
        <div className="max-w-xs sm:max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg 2xl:max-w-xl">
          <h1 className="text-3xl font-bold mb-5 ">
            {t("youHaveBeenSentEmail")}
          </h1>
          <p>{t("pleaseActivateAccount")}</p>
        </div>
      )}
    </div>
  );
};

export default SignIn;
