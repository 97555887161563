const colors = {
  button: {
    cta: {
      button: "bg-ctaPrimary hover:bg-ctaHover",
      text: "text-ctaText",
    },
    logOut: { button: "bg-red-500 hover:bg-red-700", text: "text-white" },
    login: { button: "bg-orange-500 hover:bg-orange-700", text: "text-white" },
    back: { button: "bg-blue-500 hover:bg-blue-700", text: "text-white" },
    add: { button: "bg-green-500 hover:bg-green-700", text: "text-black" },
    delete: { button: "bg-red-500 hover:bg-red-700", text: "text-white" },
    play: { button: "bg-green-500 hover:bg-green-700", text: "text-black" },
  },
};

export default colors;
