import React, { useEffect, useState } from "react";
import Header from "../../components/shared/header";
import { useNavigate } from "react-router-dom";
import { fetchUserStatistics } from "../../util/fetch";
import { Pie, Bar, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { useSelector } from "react-redux/es/hooks/useSelector";
import Footer from "../../components/shared/footer";
import Card from "../../components/shared/card";
import Goal from "../../img/goal.png";
import GoalTrophy from "../../img/goalTrophy.png";
import Star from "../../img/star.png";
import Certificate from "../../img/certificate.png";
import Insignia from "../../img/insignia.png";
import Medal from "../../img/medal.png";
import Trophy from "../../img/trophy.png";
import PageTransition from "../../components/shared/pageTransition";
import { skeletonTypes } from "../../util/options";
import LoadingPage from "../../components/shared/loadingPage";
import ScrollToTop from "../../util/scrollToTop";
import { useTranslation } from "react-i18next";
import ComboBox from "../../components/shared/combobox";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement, // Make sure ArcElement is registered
  Tooltip,
  Legend
);

const StatisticsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user.userData);

  const [isLoading, setIsLoading] = useState(true);
  const [chartType, setChartType] = useState("pie");

  const [stats, setStats] = useState({
    exercisesCompleted: 0,
    wordExercisesCompleted: 0,
    readingExercisesCompleted: 0,
    sentenceExercisesCompleted: 0,
    multipleChoiceExercisesCompleted: 0,
    orderExercisesCompleted: 0,
    connectionExercisesCompleted: 0,
  });

  useEffect(() => {
    if (!userData.uid) return;
    const fetchStats = async () => {
      const fetchedStats = await fetchUserStatistics(userData.uid);
      setStats(fetchedStats);
      setIsLoading(false);
    };
    fetchStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data = {
    labels: [
      t("words"),
      t("readingComprehension"),
      t("multipleChoice"),
      t("order"),
      t("connection"),
      t("sentenceCreation"),
    ],
    datasets: [
      {
        label: t("exercisesCompleted"),
        data: [
          stats.wordExercisesCompleted,
          stats.readingExercisesCompleted,
          stats.sentenceExercisesCompleted,
          stats.multipleChoiceExercisesCompleted,
          stats.orderExercisesCompleted,
          stats.connectionExercisesCompleted,
        ],
        backgroundColor: [
          "rgba(255, 99, 132, 0.6)",
          "rgba(54, 162, 235, 0.6)",
          "rgba(255, 206, 86, 0.6)",
          "rgba(75, 192, 192, 0.6)",
          "rgba(153, 102, 255, 0.6)",
          "rgba(255, 159, 64, 0.6)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
  };

  const chartOptions = [
    { value: "pie", label: t("pie") },
    { value: "bar", label: t("bar") },
    { value: "line", label: t("line") },
  ];

  const renderChart = () => {
    switch (chartType) {
      case "bar":
        return <Bar data={data} options={options} />;
      case "line":
        return <Line data={data} options={options} />;
      default:
        return <Pie data={data} options={options} />;
    }
  };

  return isLoading || !userData ? (
    <LoadingPage type={skeletonTypes.statistics} />
  ) : (
    <div className="h-fit min-h-screen w-full bg-pageBackground items-center flex flex-col">
      <Header navigateTo={() => navigate("/home")} />
      <ScrollToTop />

      <PageTransition extraStyles="flex flex-col flex-1 items-center h-full w-full ">
        <h1 className="border-b border-transparent text-headerText font-bold text-5xl">
          {" "}
          {t("yourStatistics")}
        </h1>

        <div className="w-full flex flex-col flex-1">
          <div className="flex  flex-row">
            <Card
              text={stats?.exercisesCompleted}
              secondaryText={t("exercisesCompleted")}
              textSize="text-8xl"
              secondaryTextSize="text-xl sm:text-2xl"
              hover=""
              icon={Goal}
            />
          </div>
          {stats?.exercisesCompleted > 0 && (
            <div className=" rounded-3xl mx-5 my-2 sm:my-5 flex flex-col sm:flex-row items-center justify-center mt-10 mb-10 bg-white p-10 h-fit max-h-[500px]">
              <div className="flex flex-col items-center justify-center">
                <h1 className="text-5xl sm:text-6xl border-b border-transparent text-headerText font-bold">
                  {t("graph")}
                </h1>
                <h1
                  className={`text-xl font-bold sm:text-2xl text-underHeaderText`}
                >
                  {t("chooseGraphType")}
                </h1>
                <ComboBox
                  options={chartOptions}
                  selectedValue={chartType}
                  setSelectedValue={setChartType}
                  title={t("selectChartType")}
                />
              </div>
              <div>{renderChart()}</div>
            </div>
          )}
          <div className="flex flex-1 flex-row">
            <Card
              text={stats?.wordExercisesCompleted ?? 0}
              secondaryText={t("wordExercisesCompleted")}
              textSize="text-8xl"
              hover=""
              secondaryTextSize="text-xl sm:text-2xl"
              icon={GoalTrophy}
            />

            <Card
              text={stats?.readingExercisesCompleted ?? 0}
              secondaryText={t("readingExercisesCompleted")}
              textSize="text-8xl"
              hover=""
              secondaryTextSize="text-xl sm:text-2xl"
              icon={Star}
            />
          </div>
          <div className="flex flex-1 flex-row">
            <Card
              text={stats?.sentenceExercisesCompleted ?? 0}
              secondaryText={t("sentenceExercisesCompleted")}
              textSize="text-8xl"
              hover=""
              secondaryTextSize="text-xl sm:text-2xl"
              icon={Certificate}
            />

            <Card
              text={stats?.connectionExercisesCompleted ?? 0}
              secondaryText={t("connectionExercisesCompleted")}
              textSize="text-8xl"
              hover=""
              secondaryTextSize="text-xl sm:text-2xl"
              icon={Insignia}
            />
          </div>
          <div className="flex flex-1 flex-row">
            <Card
              text={stats?.orderExercisesCompleted ?? 0}
              secondaryText={t("orderExercisesCompleted")}
              textSize="text-8xl"
              hover=""
              secondaryTextSize="text-xl sm:text-2xl"
              icon={Medal}
            />

            <Card
              text={stats?.multipleChoiceExercisesCompleted ?? 0}
              secondaryText={t("multipleChoiceExercisesCompleted")}
              textSize="text-8xl"
              hover=""
              secondaryTextSize="text-xl sm:text-2xl"
              icon={Trophy}
            />
          </div>
        </div>
      </PageTransition>
      <Footer />
    </div>
  );
};

export default StatisticsPage;
