import React from "react";

const Input = ({
  placeholderName,
  value,
  onChangeFunction,
  inputType = "text",
  extraStyles = "",
  width = "w-[320px]",
  border = "border-b-2 border-gray-500",
  isError = false,
  isCorrect = false,
  errorMessage = "",
  disabled = false,
}) => {
  const errorStyles = isError
    ? "border-b-2 border-red-500 focus:border-red-700"
    : isCorrect
    ? "border-b-2 border-green-500 focus:border-green-700"
    : border;

  return (
    <div className="flex flex-col items-center justify-center">
      <input
        disabled={disabled}
        className={`${width} p-2.5 h-[55px] ${errorStyles} rounded-none hover:rounded-md focus:rounded-md outline-none bg-transparent font-roboto text-black text-xl ${extraStyles} focus:bg-inputFocusBg hover:bg-inputHoverBg hover:border-inputHoverBorder focus:border-inputFocusHover md:hover:bg-inputFocusBg rounded-md px-4 py-2 outline-none transition-colors duration-200 ease-in-out`}
        type={inputType}
        placeholder={placeholderName}
        value={value}
        onChange={onChangeFunction}
      />
      {isError && (
        <div className="mt-2 text-sm text-red-600">{errorMessage}</div>
      )}
    </div>
  );
};

export default Input;
