export const levelOptions = [
  { value: 1, label: "A1" },
  { value: 2, label: "A2" },
];

export const genderOptions = [
  { value: "male", label: "Muž" },
  { value: "female", label: "Žena" },
  // { value: "other", label: "Iné" },
];

export const levelOptionsArray = ["", "A1", "A2"];

export const exerciseDifficulty = {
  1: "A1",
  2: "A2",
};

export const exerciseDifficultyLabels = ["", "A1", "A2"];

export const exercisesXP = [
  [100, 200, 500],
  [200, 400, 1000],
];

export const wordsExerciseXP = [100, 200, 500];

export const readingExerciseXP = [200, 400, 1000];

export const exerciseTypes = {
  words: 1,
  readingComprehension: 2,
  multipleChoice: 3,
  order: 4,
  connection: 5,
  sentenceCreation: 6,
};

export const exerciseTypesOptions = (t) => [
  { value: exerciseTypes.words, label: t("words") },
  {
    value: exerciseTypes.readingComprehension,
    label: t("readingComprehension"),
  },
  { value: exerciseTypes.multipleChoice, label: t("multipleChoice") },
  { value: exerciseTypes.order, label: t("order") },
  { value: exerciseTypes.connection, label: t("connection") },
  { value: exerciseTypes.sentenceCreation, label: t("sentenceCreation") },
];

export const getExerciseTypeName = (t, type) => {
  switch (type) {
    case exerciseTypes.words:
      return t("words");
    case exerciseTypes.readingComprehension:
      return t("readingComprehension");
    case exerciseTypes.multipleChoice:
      return t("multipleChoice");
    case exerciseTypes.order:
      return t("order");
    case exerciseTypes.connection:
      return t("connection");
    case exerciseTypes.sentenceCreation:
      return t("sentenceCreation");
    default:
      return t("words");
  }
};

export const exerciseNames = (t) => [
  t("words"),
  t("readingComprehension"),
  t("multipleChoice"),
  t("order"),
  t("connection"),
  t("sentenceCreation"),
];
export const editExerciseLinks = {
  [exerciseTypes.words]: "/home/myExercises/editExersice/words/",
  [exerciseTypes.readingComprehension]:
    "/home/myExercises/editExersice/readingComprehension/",
  [exerciseTypes.connection]: "/home/myExercises/editExersice/connection/",
  [exerciseTypes.sentenceCreation]:
    "/home/myExercises/editExersice/sentenceCreation/",
  [exerciseTypes.order]: "/home/myExercises/editExersice/order/",
  [exerciseTypes.multipleChoice]:
    "/home/myExercises/editExersice/multipleChoice/",
};

export const exerciseTypesArray = [
  { value: exerciseTypes.words, label: "Slovná zásoba" },
  {
    value: exerciseTypes.readingComprehension,
    label: "Čítanie s porozumením",
  },
];

export const roles = {
  admin: "Administrator",
  teacher: "Učiteľ",
  user: "Benutzer",
};

export const taskState = {
  correct: 1,
  incorrect: 2,
  notChecked: 3,
};

export const skeletonTypes = {
  homepage: "homepage",
  statistics: "statistics",
};
