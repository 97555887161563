import Chest from "../img/chest.png";
import Goal from "../img/goal.png";
import GrammarBook from "../img/grammarBook.png";
import Login from "../img/login.png";
import Profile from "../img/profile.png";
import Random from "../img/random.png";
import ReadingBook from "../img/readingBook.png";
import Slovakia from "../img/slovakia.png";
import Teacher from "../img/teacher.png";
import Trophy from "../img/trophy.png";
import Wizard from "../img/wizard.png";
import WordsBook from "../img/wordsBook.png";
import Certificate from "../img/certificate.png";
import Germany from "../img/germany.png";
import SlovakiaBubble from "../img/slovakiaBubble.png";
import Smile from "../img/smile.png";
import Christmas from "../img/christmas.png";
import Student from "../img/student.png";
import Hat from "../img/hat.png";
// import Competition from "../img/competition.png";
import Sequence from "../img/sequence.png";
import NoConnection from "../img/no-connection.png";
import Brick from "../img/brick.png";
import Letter from "../img/letter.png";

export const creditItemData = [
  {
    link: "https://www.flaticon.com/free-icons/knowledge",
    title: "knowledge icons",
    icons: [GrammarBook],
    name: "Knowledge icons created by Smashicons - Flaticon",
  },
  {
    link: "https://www.flaticon.com/free-icons/knowledge",
    title: "knowledge icons",
    icons: [ReadingBook],
    name: "Knowledge icons created by Smashicons - Flaticon",
  },
  {
    link: "https://www.flaticon.com/free-icons/knowledge",
    title: "knowledge icons",
    icons: [WordsBook],
    name: "Knowledge icons created by Smashicons - Flaticon",
  },
  {
    link: "https://www.flaticon.com/free-icons/picture",
    title: "picture icons",
    icons: [Profile],
    name: "Picture icons created by kliwir art - Flaticon",
  },
  {
    link: "https://www.flaticon.com/free-icons/trophy",
    title: "trophy icons",
    icons: [Trophy],
    name: "Trophy icons created by Freepik - Flaticon",
  },
  {
    link: "https://www.flaticon.com/free-icons/continuous-learning",
    title: "continuous learning",
    icons: [Random],
    name: "Continuous learning icons created by Freepik - Flaticon",
  },
  {
    link: "https://www.flaticon.com/free-icons/slovakia",
    title: "slovakia icons",
    icons: [Slovakia],
    name: "Slovakia icons created by Freepik - Flaticon",
  },
  {
    link: "https://www.flaticon.com/free-icons/teacher",
    title: "teacher icons",
    icons: [Teacher],
    name: "Teacher icons created by monkik - Flaticon",
  },
  {
    link: "https://www.flaticon.com/free-icons/treasure",
    title: "treasure icons",
    icons: [Chest],
    name: "Treasure icons created by shmai - Flaticon",
  },
  {
    link: "https://www.flaticon.com/free-icons/woman",
    title: "woman icons",
    icons: [Login],
    name: "Woman icons created by Freepik - Flaticon",
  },
  {
    link: "https://www.flaticon.com/free-icons/character",
    title: "character icons",
    icons: [Wizard],
    name: "Character icons created by Freepik - Flaticon",
  },
  //NEW ONES
  //GOAL
  // <a href="https://www.flaticon.com/free-icons/goal" title="goal icons">Goal icons created by Freepik - Flaticon</a>
  {
    link: "https://www.flaticon.com/free-icons/goal",
    title: "goal icons",
    icons: [Goal],
    name: "Goal icons created by Freepik - Flaticon",
  },
  //trphy
  //<a href="https://www.flaticon.com/free-icons/trophy" title="trophy icons">Trophy icons created by Freepik - Flaticon</a>
  {
    link: "https://www.flaticon.com/free-icons/trophy",
    title: "trophy icons",
    icons: [Trophy],
    name: "Trophy icons created by Freepik - Flaticon",
  },
  //certificate
  // <a href="https://www.flaticon.com/free-icons/certificate" title="certificate icons">Certificate icons created by Freepik - Flaticon</a>
  {
    link: "https://www.flaticon.com/free-icons/certificate",
    title: "certificate icons",
    icons: [Certificate],
    name: "Certificate icons created by Freepik - Flaticon",
  },
  //compo
  // <a href="https://www.flaticon.com/free-icons/sports-and-competition" title="sports-and-competition icons">Sports-and-competition icons created by Freepik - Flaticon</a>
  // {
  //   link: "https://www.flaticon.com/free-icons/sports-and-competition",
  //   title: "sports-and-competition icons",
  //   icons: [Competition],
  //   name: "Sports-and-competition icons created by Freepik - Flaticon",
  // },
  //<a href="https://www.flaticon.com/free-icons/quality" title="quality icons">Quality icons created by Freepik - Flaticon</a>
  {
    link: "https://www.flaticon.com/free-icons/quality",
    title: "quality icons",
    icons: [Random],
    name: "Quality icons created by Freepik - Flaticon",
  },
  //letter
  // <a href="https://www.flaticon.com/free-icons/contact-form" title="contact form icons">Contact form icons created by piksart - Flaticon</a>
  {
    link: "https://www.flaticon.com/free-icons/contact-form",
    title: "contact form icons",
    icons: [Letter],
    name: "Contact form icons created by piksart - Flaticon",
  },
  //sequence
  // <a href="https://www.flaticon.com/free-icons/sequence" title="sequence icons">Sequence icons created by Freepik - Flaticon</a>
  {
    link: "https://www.flaticon.com/free-icons/sequence",
    title: "sequence icons",
    icons: [Sequence],
    name: "Sequence icons created by Freepik - Flaticon",
  },
  //no connection
  // <a href="https://www.flaticon.com/free-icons/no-connection" title="no connection icons">No connection icons created by Freepik - Flaticon</a>
  {
    link: "https://www.flaticon.com/free-icons/no-connection",
    title: "no connection icons",
    icons: [NoConnection],
    name: "No connection icons created by Freepik - Flaticon",
  },
  //brick
  // <a href="https://www.flaticon.com/free-icons/brick" title="brick icons">Brick icons created by Freepik - Flaticon</a>
  {
    link: "https://www.flaticon.com/free-icons/brick",
    title: "brick icons",
    icons: [Brick],
    name: "Brick icons created by Freepik - Flaticon",
  },
  //slovakia
  // <a href="https://www.flaticon.com/free-icons/slovakia" title="slovakia icons">Slovakia icons created by Freepik - Flaticon</a>
  {
    link: "https://www.flaticon.com/free-icons/slovakia",
    title: "slovakia icons",
    icons: [SlovakiaBubble],
    name: "Slovakia icons created by Freepik - Flaticon",
  },
  //germany
  // <a href="https://www.flaticon.com/free-icons/germany" title="germany icons">Germany icons created by Roundicons - Flaticon</a>
  {
    link: "https://www.flaticon.com/free-icons/germany",
    title: "germany icons",
    icons: [Germany],
    name: "Germany icons created by Roundicons - Flaticon",
  },

  //smile
  // <a href="https://www.flaticon.com/free-icons/smile" title="smile icons">Smile icons created by Pixel perfect - Flaticon</a>

  {
    link: "https://www.flaticon.com/free-icons/smile",
    title: "smile icons",
    icons: [Smile],
    name: "Smile icons created by Pixel perfect - Flaticon",
  },

  //christmas
  // <a href="https://www.flaticon.com/free-icons/christmas" title="christmas icons">Christmas icons created by imaginationlol - Flaticon</a>
  {
    link: "https://www.flaticon.com/free-icons/christmas",
    title: "christmas icons",
    icons: [Christmas],
    name: "Christmas icons created by imaginationlol - Flaticon",
  },

  {
    link: "https://www.flaticon.com/free-icons/graduation-hat",
    title: "graduation-hat icons",
    icons: [Student],
    name: "Graduation-hat icons created by Freepik - Flaticon",
  },

  {
    link: "https://www.flaticon.com/free-icons/hat",
    title: "hat icons",
    icons: [Hat],
    name: "Hat icons created by Freepik - Flaticon",
  },
  {
    link: "https://chatgpt.com",
    title: "Avatars",
    // icons: [Hat],
    name: "Avatars created with ChatGPT",
  },
  {
    link: "https://fonts.google.com/",
    title: "Google Fonts",
    // icons: [Hat],
    name: "Fonts and icons from Google Fonts",
  },
];
