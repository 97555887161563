import { getDoc, doc } from "firebase/firestore";
import { db } from "../config/firebase";

export const fetchUserData = (userId) => async (dispatch) => {
  try {
    const userRef = doc(db, "users", userId);
    const userSnap = await getDoc(userRef);

    if (userSnap.exists()) {
      dispatch({
        type: "SET_USER_DATA",
        payload: userSnap.data(),
      });
      console.log("User data fetched successfully!");
    } else {
      console.log("No such user!");
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
  }
};

export const clearUserData = () => (dispatch) => {
  dispatch({
    type: "CLEAR_USER_DATA",
  });
};
