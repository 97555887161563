import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import * as animations from "../../util/animations";

const LoadingHeader = ({
  children,
  variants = animations.slideUpEffectLoadOut,
}) => {
  // const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="header w-screen text-3xl font-bold mb-0 bg-primaryColor flex flex-col lg:flex-row justify-between p-2 sm:p-5 items-center">
      {/* <PageTransition
        variants={variants}
        extraStyles="flex flex-row w-full justify-between lg:justify-normal"
      > */}
      <div className="flex flex-row w-full justify-between lg:justify-normal">
        <div className="flex items-center opacity-1">
          <button
            className={`title text-headerTitle font-bold "flex" opacity-1 md:hidden `}
          >
            <span
              className="material-symbols-outlined"
              style={{
                color: "text-headerTitle",
                fontSize: "2rem",
              }}
            >
              arrow_back
            </span>
          </button>
        </div>
        <button className="title text-headerTitle font-bold text-5xl md:text-6xl opacity-1">
          SpielMit
        </button>
        <button
          className={`lg:hidden text-headerTitle m-0 p-0 "flex"`}
          style={{ opacity: children ? 1 : 0 }}
        >
          ☰
        </button>
      </div>
      {/* </PageTransition> */}
      <motion.div
        initial={false}
        animate={!isMobileView ? "open" : "closed"}
        variants={{
          closed: {
            opacity: 0,
            maxHeight: 0,
            overflow: "hidden",
            transition: { duration: 0.3 },
          },
          open: {
            opacity: 1,
            maxHeight: "20rem",
            transition: {
              duration: 0.3,
              when: "beforeChildren",
              staggerChildren: 0.05,
            },
          },
        }}
        className="menu lg:flex flex-col lg:flex-row items-center mt-4 lg:mt-0"
      >
        {children}
      </motion.div>
    </div>
  );
};

export default LoadingHeader;
