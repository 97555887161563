const initialState = {
  name: null,
  createdBy: null,
  level: null,
  type: null,
};

const allWordsFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_FILTER":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default allWordsFilterReducer;
