import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Card from "../../components/shared/card";
import Button from "../../components/shared/button";
import Footer from "../../components/shared/footer";
import Header from "../../components/shared/header";
import LastPlayedExercise from "../../components/shared/lastPlayedExercise";
import LoadingPage from "../../components/shared/loadingPage";
import Logo from "../../img/logo.png";
import Profile from "../../img/profile.png";
import Random from "../../img/random.png";
import Trophy from "../../img/trophy.png";
import Wizard from "../../img/wizard.png";
import "../../index.css";
import { logout } from "../../util/authFunctions";
import PageTransition from "../../components/shared/pageTransition";
import { useTranslation } from "react-i18next";
import { notifySuccess } from "../../util/toast";

const Home = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const userData = useSelector((state) => state.user.userData);

  React.useEffect(() => {
    if (!userData) {
      navigate("/");
    }
  }, [navigate, userData]);

  return !userData ? (
    <LoadingPage />
  ) : (
    <div className="h-max min-h-screen w-screen bg-pageBackground items-center flex flex-col flex-wrap scroll-m-0 overflow-x-hidden">
      <Header>
        {userData.role === "admin" && (
          <Button
            icon={"edit_note"}
            buttonType={"cta"}
            text={t("requestButtonText")}
            width="w-[220px]"
            heigth="h-full"
            onClick={() => navigate("/home/requests")}
            extraStyle={"lg:mr-10 mb-2 lg:mb-0"}
          ></Button>
        )}
        {userData.role === "teacher" && (
          <Button
            icon={"edit_note"}
            buttonType={"cta"}
            text={t("newExerciseButtonText")}
            width="w-[220px]"
            heigth="h-full"
            onClick={() => navigate("/home/createExercise")}
            extraStyle={"lg:mr-10 mb-2 lg:mb-0"}
          ></Button>
        )}
        {userData.role === "teacher" && (
          <Button
            icon={"edit_note"}
            buttonType={"cta"}
            text={t("myExercisesButtonText")}
            width="w-[220px]"
            heigth="h-full"
            onClick={() => navigate("/home/myExercises")}
            extraStyle={"lg:mr-10 mb-2 lg:mb-0"}
          ></Button>
        )}
        <Button
          icon={"account_circle"}
          buttonType={"cta"}
          text={t("myProfileButtonText")}
          width="w-[220px]"
          heigth="h-full"
          onClick={() => navigate("/home/profile")}
          extraStyle={"lg:mr-10 mb-2 lg:mb-0"}
        ></Button>
        <Button
          icon={"power_settings_new"}
          buttonType={"cta"}
          text={t("logoutButtonText")}
          width="w-[220px]"
          heigth="h-full"
          onClick={() => {
            notifySuccess("Odlásenie");
            logout(dispatch, navigate);
          }}
        ></Button>
      </Header>
      <PageTransition extraStyles="h-full w-full flex flex-grow flex-col">
        <div className="h-full w-full flex flex-grow flex-col">
          <div className="flex flex-wrap items-center sm:items-start sm:p-4 text-2xl sm:text-5xl font-bold w-full ml-5 text-primaryColor">
            <h1 className="text-primaryBlue">{t("welcome")}&nbsp;</h1>
            <h1 className="animate-pulse text-primaryColor">
              {userData?.nickname},
            </h1>
            <h1 className="text-primaryColor">&nbsp;{t("toSpieleMit")}</h1>
          </div>

          <div className="flex flex-col flex-1">
            <div className="flex flex-col md:flex-row w-full flex-1">
              <Card
                text={"Spiel mit!"}
                secondaryText={t("chooseFromManyExercises")}
                icon={Logo}
                flex={"row"}
                textSize={"text-7xl"}
                onClick={() => navigate("/home/exercises")}
              />
            </div>
            <div className="flex flex-col md:flex-row w-full flex-1">
              <LastPlayedExercise />
              <Card
                text={"Avatar"}
                secondaryText={t("avatarSecondary")}
                onClick={() => navigate("/home/avatar")}
                icon={Wizard}
              />
              <Card
                text={t("myProfile")}
                secondaryText={t("myProfileSecondary")}
                onClick={() => navigate("/home/profile")}
                icon={Profile}
              />
            </div>
            <div className="flex flex-col md:flex-row w-full flex-1">
              <Card
                text={t("randomExercise")}
                secondaryText={t("randomExerciseSecondary")}
                onClick={() => navigate("/home/exercises/random")}
                icon={Random}
              />
              <Card
                text={t("statistics")}
                secondaryText={t("statisticsSecondary")}
                onClick={() => navigate("/home/statistics")}
                icon={Trophy}
              />
            </div>
          </div>
        </div>
      </PageTransition>
      <Footer />
    </div>
  );
};

export default Home;
