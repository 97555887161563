import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { fetchSentenceExerciseWithTasks } from "../../util/fetch";
import { exerciseTypes, taskState } from "../../util/options";
import ExerciseBody from "../../components/shared/ExerciseBody";
import LoadingPage from "../../components/shared/loadingPage";
import Input from "../../components/shared/input";
import Brick from "../../img/brick.png";
import { useTranslation } from "react-i18next";

const SentenceCreationExercise = () => {
  const { t } = useTranslation();
  const { exerciseId } = useParams();
  const [exercise, setExercise] = useState({ sentenceTasks: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [taskInputs, setTaskInputs] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchSentenceExerciseWithTasks(
      setIsLoading,
      exerciseId,
      setExercise,
      setTaskInputs
    );
  }, [exerciseId]);

  const normalizeSpace = (str) => {
    if (typeof str !== "string") {
      console.error(
        "normalizeSpace was called with a non-string argument:",
        str
      );
      return "";
    }
    return str.replace(/\s+/g, " ").trim();
  };

  const checkInputs = () => {
    let correct = true;
    exercise.sentenceTasks.forEach((task, index) => {
      const userInput = normalizeSpace(taskInputs[index].text);
      const correctSolution = normalizeSpace(task.solution);
      if (correctSolution !== userInput) {
        correct = false;
        const newTaskInputs = [...taskInputs];
        newTaskInputs[index].taskState = taskState.incorrect;
        setTaskInputs(newTaskInputs);
      } else {
        const newTaskInputs = [...taskInputs];
        newTaskInputs[index].taskState = taskState.correct;
        setTaskInputs(newTaskInputs);
      }
    });

    if (correct) {
      setShowModal(true);
      return true;
    } else {
      return false;
    }
  };

  const updateInput = (index, value) => {
    const newInputs = [...taskInputs];
    newInputs[index] = { ...newInputs[index], text: value };
    setTaskInputs(newInputs);
  };

  return isLoading ? (
    <LoadingPage />
  ) : (
    <ExerciseBody
      exerciseTypeTitle={t("sentenceCreation")}
      icon={Brick}
      exercise={exercise}
      checkInputs={checkInputs}
      showModal={showModal}
      exerciseType={exerciseTypes.sentenceCreation}
      navigateString="/home/exercises/sentenceCreation"
      defaultDescription={t("sentenceCreationDefaultDescription")}
    >
      {exercise.sentenceTasks.map((task, index) => (
        <div key={task.id || index} className="flex flex-col mb-4">
          <div className="flex flex-row mb-2 text-lg font-semibold w-full">
            {task.prompt}
          </div>
          <div className="flex flex-row items-center justify-center">
            <Input
              placeholder="Enter translation"
              value={taskInputs[index]?.text || ""}
              onChangeFunction={(e) => updateInput(index, e.target.value)}
              extraStyles={` ${
                taskInputs[index]?.taskState === taskState.incorrect
                  ? "border-red-500"
                  : taskInputs[index]?.taskState === taskState.notChecked
                  ? ""
                  : "border-green-500"
              }`}
            />
            {taskInputs[index].taskState === taskState.incorrect && (
              <span
                style={{ color: "red" }}
                className="material-symbols-outlined"
              >
                close
              </span>
            )}
            {taskInputs[index].taskState === taskState.correct && (
              <span
                style={{ color: "green" }}
                className="material-symbols-outlined"
              >
                done
              </span>
            )}
          </div>
        </div>
      ))}
    </ExerciseBody>
  );
};

export default SentenceCreationExercise;
