import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import React from "react";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useParams } from "react-router-dom";
import Button from "../../components/shared/button";
import CreateExerciseBody from "../../components/shared/createExerciseBody";
import { db } from "../../config/firebase";
import { exerciseTypes } from "../../util/options";
import { useTranslation } from "react-i18next";
import LoginInput from "../../components/shared/LoginInput";
import { notifyError } from "../../util/toast";

const CreateWordExercise = () => {
  const { t } = useTranslation();
  const userData = useSelector((state) => state.user.userData);
  const { exerciseId } = useParams();
  // const navigate = useNavigate();

  // const backFunc = () => {
  //   if (exerciseId) {
  //     navigate("/home/myExercises");
  //   } else {
  //     navigate("/home/createExercise");
  //   }
  // };

  const [wordTasks, setWordTasks] = React.useState([
    { slovak: "", german: "" },
  ]);
  const [selectedLevelValue, setSelectedLevelValue] = React.useState("");
  const [titleValue, setTitleValue] = React.useState("");
  const [showModal, setShowModal] = React.useState(false);
  const [isCorrect, setIsCorrect] = React.useState(false);
  const [titleError, setTitleError] = React.useState(false);
  const [levelError, setLevelError] = React.useState(false);
  const [wordTasksError, setWordTasksError] = React.useState([]);

  React.useEffect(() => {
    const fetchExercise = async () => {
      if (exerciseId) {
        const docRef = doc(db, "excercise", exerciseId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const exerciseData = docSnap.data();
          setTitleValue(exerciseData.name || "");
          setSelectedLevelValue(exerciseData.level || "");

          const tasksCollectionRef = collection(docRef, "WordTasks");
          const querySnapshot = await getDocs(tasksCollectionRef);

          const tasks = querySnapshot.docs
            .map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))
            .sort((a, b) => a.order - b.order);

          setWordTasks(tasks);
        } else {
          console.log("No such document!");
        }
      }
    };

    fetchExercise();
  }, [exerciseId]);

  const checkInputs = () => {
    let correct = true;
    if (titleValue === "") {
      setTitleError(true);
      correct = false;
    } else {
      setTitleError(false);
    }
    if (selectedLevelValue === "") {
      setLevelError(true);
      correct = false;
    } else {
      setLevelError(false);
    }

    const errors = wordTasks.map((task) => {
      if (task.slovak === "" || task.german === "") {
        correct = false;
      }
      return {
        slovak: task.slovak === "",
        german: task.german === "",
      };
    });
    setWordTasksError(errors);
    correct = !errors.includes(true) && correct;

    return correct;
  };

  const addNewExercise = async () => {
    if (checkInputs()) {
      try {
        let exerciseDocRef;
        if (exerciseId) {
          const wordTasksCollectionRef = collection(
            db,
            "excercise",
            exerciseId,
            "WordTasks"
          );
          const querySnapshot = await getDocs(wordTasksCollectionRef);

          const deletePromises = querySnapshot.docs.map((docSnapshot) => {
            return deleteDoc(
              doc(db, "excercise", exerciseId, "WordTasks", docSnapshot.id)
            );
          });

          await Promise.all(deletePromises);

          exerciseDocRef = doc(db, "excercise", exerciseId);
          await updateDoc(exerciseDocRef, {
            name: titleValue,
            level: selectedLevelValue,
            type: exerciseTypes.words,
          });
        } else {
          exerciseDocRef = await addDoc(collection(db, "excercise"), {
            name: titleValue,
            level: selectedLevelValue,
            type: exerciseTypes.words,
            createdAt: new Date(),
            createdBy: userData.uid,
            createdByName: userData.firstname ?? "N/A",
            createdByLastName: userData.lastname ?? "N/A",
            createdByNickname: userData.nickname ?? "N/A",
          });
        }

        const tasksCollectionRef = collection(exerciseDocRef, "WordTasks");
        await Promise.all(
          wordTasks.map((task, index) =>
            addDoc(tasksCollectionRef, {
              slovak: task.slovak.trim(),
              german: task.german.trim(),
              order: index,
            })
          )
        );
        console.log("Exercise and word tasks created/updated successfully.");
        setIsCorrect(true);
        setShowModal(true);
      } catch (error) {
        console.error("Error adding/updating document and word tasks:", error);
        notifyError("Error adding/updating document and word tasks.");
        // setShowModal(true);
      }
    } else {
      // setShowModal(true);
      console.log("Missing or invalid inputs.");
    }
  };

  const handleAddNewTask = () => {
    const newWordTasks = [...wordTasks];
    newWordTasks.push({ slovak: "", german: "" });
    setWordTasks(newWordTasks);
  };

  const onChangeWordTask = (value, index, key) => {
    const newWordTasks = [...wordTasks];
    newWordTasks[index][key] = value;
    setWordTasks(newWordTasks);

    // Update error state for the specific task
    if (
      newWordTasks[index].slovak !== "" &&
      newWordTasks[index].german !== ""
    ) {
      const newErrors = [...wordTasksError];
      newErrors[index] = false;
      setWordTasksError(newErrors);
    }
  };

  return (
    <CreateExerciseBody
      addNewExercise={addNewExercise}
      addButtonOnclick={handleAddNewTask}
      titleValue={titleValue}
      setTitleValue={setTitleValue}
      selectedLevelValue={selectedLevelValue}
      setSelectedLevelValue={setSelectedLevelValue}
      isEditing={exerciseId ? true : false}
      wordTasks={wordTasks}
      setWordTasks={setWordTasks}
      showModal={showModal}
      setShowModal={setShowModal}
      isCorrect={isCorrect}
      setIsCorrect={setIsCorrect}
      type={exerciseTypes.words}
      id={exerciseId}
      titleError={titleError}
      levelError={levelError}
    >
      <div className="flex flex-col w-fit">
        {wordTasks.map((wordTask, index) => (
          <div
            key={index}
            className="flex flex-row justify-center items-center mb-5 w-fit"
          >
            <div className="flex flex-col md:flex-row flex-4">
              <h1 className="font-bold text-2xl back">
                {index + 1}. {t("task")}
              </h1>
              <div className="flex flex-row items-center bg-gray-100 p-4 rounded-lg">
                <div className="flex flex-col">
                  <LoginInput
                    placeholder={t("originalWord")}
                    icon={"description"}
                    value={wordTask.slovak}
                    secondParam={true}
                    setValue={(e) =>
                      onChangeWordTask(e.target.value, index, "slovak")
                    }
                    showError={wordTasksError[index]?.slovak}
                    errorMessage={t("emptyFieldError")}
                  />
                  <LoginInput
                    placeholder={t("translation")}
                    icon={"description"}
                    value={wordTask.german}
                    secondParam={true}
                    setValue={(e) =>
                      onChangeWordTask(e.target.value, index, "german")
                    }
                    showError={wordTasksError[index]?.german}
                    errorMessage={t("emptyFieldError")}
                  />
                </div>
                <Button
                  icon={"delete"}
                  buttonType={"cta"}
                  text={""}
                  extraStyle="flex-1 p-3"
                  width="w-[50px]"
                  heigth="h-[50px]"
                  padding="p-3"
                  onClick={() => {
                    const newWordTasks = [...wordTasks];
                    newWordTasks.splice(index, 1);
                    setWordTasks(newWordTasks);
                    const newErrors = [...wordTasksError];
                    newErrors.splice(index, 1);
                    setWordTasksError(newErrors);
                  }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </CreateExerciseBody>
  );
};
export default CreateWordExercise;
