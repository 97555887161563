import React from "react";
const ProfileArrtibute = ({ title, value }) => {
  return (
    <div className="flex flex-col p-3 ">
      <p className=" text-sm text-primaryColor">{title}</p>
      <p className=" text-3xl font-bold text-primaryColor">{value}</p>
    </div>
  );
};
export default ProfileArrtibute;
