import React from "react";
import Header from "../../components/shared/header";
import Footer from "../../components/shared/footer";
import IconCreditItem from "../../components/shared/iconCreditItem";
import { useNavigate } from "react-router-dom";
import { creditItemData } from "../../util/creditData";
import PageTransition from "../../components/shared/pageTransition";

export default function CreditsPage() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col min-h-screen w-full">
      <Header navigateTo={() => navigate("/home")} />
      <PageTransition extraStyles="flex-1 bg-pageBackground flex flex-col items-center p-5">
        <h1 className="text-5xl font-bold text-primaryColor mt-5 mb-2">
          Credits
        </h1>
        <h2 className="text-xl text-secondaryColor mb-5">
          A big thank you to the artists.
        </h2>
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 justify-items-center">
          {creditItemData?.map((creditItem, index) => (
            <IconCreditItem key={index} creditItem={creditItem} />
          ))}
        </div>
      </PageTransition>
      <Footer />
    </div>
  );
}
