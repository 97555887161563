import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { fetchMultipleChoiceExerciseWithTasks } from "../../util/fetch";
import ExerciseBody from "../../components/shared/ExerciseBody";
import LoadingPage from "../../components/shared/loadingPage";
import ComboBox from "../../components/shared/combobox";
import { exerciseTypes, taskState } from "../../util/options";
import Letter from "../../img/letter.png";
import { useTranslation } from "react-i18next";

const MultipleChoiceExercise = () => {
  const { t } = useTranslation();
  const { exerciseId } = useParams();
  const [exercise, setExercise] = useState({ multipleChoiceTasks: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [taskInputs, setTaskInputs] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchMultipleChoiceExerciseWithTasks(
      setIsLoading,
      exerciseId,
      setExercise,
      setTaskInputs
    );
  }, [exerciseId]);

  const checkInputs = () => {
    let correct = true;
    exercise.multipleChoiceTasks.forEach((task, index) => {
      if (task.type === "choice") {
        if (taskInputs[index].selectedOption !== String(task.correct)) {
          console.log("incorrect");
          correct = false;
          taskInputs[index] = {
            ...taskInputs[index],
            taskState: taskState.incorrect,
          };
        } else {
          taskInputs[index] = {
            ...taskInputs[index],
            taskState: taskState.correct,
          };
        }
      }
    });

    setTaskInputs([...taskInputs]);
    if (correct) {
      setShowModal(true);
      return true;
    } else {
      return false;
    }
  };

  const updateInput = (index, selectedOption) => {
    const newInputs = [...taskInputs];
    newInputs[index] = {
      ...newInputs[index],
      selectedOption,
      taskState: taskState.notChecked,
    };
    setTaskInputs(newInputs);
  };

  return isLoading ? (
    <LoadingPage />
  ) : (
    <ExerciseBody
      exerciseTypeTitle={t("multipleChoice")}
      icon={Letter}
      exercise={exercise}
      checkInputs={checkInputs}
      showModal={showModal}
      setIsLoading={setIsLoading}
      exerciseType={exerciseTypes.multipleChoice}
      navigateString="/home/exercises/multipleChoice"
      defaultDescription={t("multipleChoiceDefaultDescription")}
    >
      <div className="flex flex-wrap items-center">
        {exercise.multipleChoiceTasks.map((task, index) => (
          <React.Fragment key={index}>
            {task.type === "text" ? (
              <span className="text-lg mr-2">{task.value}</span>
            ) : (
              <>
                <span className="text-lg font-semibold mr-2">
                  {task.question}
                </span>
                <ComboBox
                  options={task.options.map((option, idx) => ({
                    value: idx,
                    label: option,
                  }))}
                  selectedValue={taskInputs[index]?.selectedOption || ""}
                  setSelectedValue={(value) => updateInput(index, value)}
                  title={t("choose")}
                  width="w-full"
                  isError={taskInputs[index]?.taskState === taskState.incorrect}
                  isCorrect={taskInputs[index]?.taskState === taskState.correct}
                />
                {taskInputs[index].taskState === taskState.incorrect && (
                  <span
                    className="material-symbols-outlined"
                    style={{
                      color: "red",
                    }}
                  >
                    close
                  </span>
                )}
                {taskInputs[index].taskState === taskState.correct && (
                  <span
                    className="material-symbols-outlined"
                    style={{
                      color: "green",
                    }}
                  >
                    done
                  </span>
                )}
              </>
            )}
          </React.Fragment>
        ))}
      </div>
    </ExerciseBody>
  );
};

export default MultipleChoiceExercise;
