import React from "react";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-footerBg w-screen h-fit p-3 flex flex-col sm:flex-row items-center ">
      <h1 className="text-headerTitle font-thin text-xl mr-0 sm:mr-10 whitespace-nowrap">
        Copyright © Zoltán Raffay 2024
      </h1>
      <button
        onClick={() => navigate("/home/credits")}
        className="font-thin text-xl bg-transparent text-headerTitle hover:underline"
      >
        Icons by various artists
      </button>
    </div>
  );
};

export default Footer;
