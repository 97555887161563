import {
  collection,
  doc,
  getDoc,
  getDocs,
  writeBatch,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../components/shared/button";
import CreateExerciseBody from "../../components/shared/createExerciseBody";
import { db } from "../../config/firebase";
import { exerciseTypes } from "../../util/options";
import { notifyError, notifySuccess } from "../../util/toast";
import LoginInput from "../../components/shared/LoginInput";
import LoginTextArea from "../../components/shared/textarea";

const CreateMultipleChoiceExercise = () => {
  const { t } = useTranslation();
  const userData = useSelector((state) => state.user.userData);
  const { exerciseId } = useParams();
  const navigate = useNavigate();

  const backFunc = () => {
    if (exerciseId) {
      navigate("/home/myExercises");
    } else {
      navigate("/home/createExercise");
    }
  };

  const [contentBlocks, setContentBlocks] = useState([
    { type: "text", value: "" },
  ]);
  const [selectedLevelValue, setSelectedLevelValue] = useState("");
  const [titleValue, setTitleValue] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [titleError, setTitleError] = React.useState(false);
  const [levelError, setLevelError] = React.useState(false);
  const [multipleChoiceTasksError, setMultipleChoiceTasksError] =
    React.useState([]);

  useEffect(() => {
    const fetchExercise = async () => {
      if (exerciseId) {
        const docRef = doc(db, "excercise", exerciseId);
        const exerciseSnap = await getDoc(docRef);

        if (exerciseSnap.exists()) {
          const exerciseData = exerciseSnap.data();
          setTitleValue(exerciseData.name || "");
          setSelectedLevelValue(exerciseData.level || "");

          const tasksCollectionRef = collection(docRef, "MultipleChoiceTasks");
          const tasksSnapshot = await getDocs(tasksCollectionRef);
          const loadedContent = tasksSnapshot.docs
            .map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))
            .sort((a, b) => a.order - b.order);

          setContentBlocks(
            loadedContent.length ? loadedContent : [{ type: "text", value: "" }]
          );
          console.log("Exercise loaded successfully.");
        } else {
          console.log("No such document found!");
        }
      }
    };

    fetchExercise();
  }, [exerciseId]);

  const handleAddText = () => {
    setContentBlocks([...contentBlocks, { type: "text", value: "" }]);
  };

  const handleAddChoice = () => {
    setContentBlocks([
      ...contentBlocks,
      { type: "choice", value: "", options: ["", "", "", ""], correct: 0 },
    ]);
  };

  const updateContent = (index, field, value) => {
    const updatedContent = [...contentBlocks];
    if (field === "options") {
      updatedContent[index].options[value.index] = value.option;
    } else {
      updatedContent[index][field] = value;
    }
    setContentBlocks(updatedContent);
  };

  const removeContent = (index) => {
    setContentBlocks(contentBlocks.filter((_, idx) => idx !== index));
  };

  const checkInputs = () => {
    let correct = true;
    if (titleValue === "") {
      setTitleError(true);
      correct = false;
    } else {
      setTitleError(false);
    }
    if (selectedLevelValue === "") {
      setLevelError(true);
      correct = false;
    } else {
      setLevelError(false);
    }

    //eslint-disable-next-line
    const errors = contentBlocks.map((task) => {
      if (task.type === "text") {
        if (task.value === "") {
          correct = false;
          return true;
        }
      } else {
        //eslint-disable-next-line
        const optionErrors = task.options.map((option) => {
          if (option === "") {
            correct = false;
            return true;
          }
        });
        return optionErrors;
      }
    });
    setMultipleChoiceTasksError(errors);

    correct = !errors.includes(true) && correct;

    return correct;
  };

  const saveExercise = async () => {
    if (!checkInputs()) {
      console.error("Validation failed. Missing or incorrect inputs.");
      notifyError("Please check your inputs and try again.");
      return;
    }

    try {
      let exerciseDocRef;
      const batch = writeBatch(db);

      if (exerciseId) {
        exerciseDocRef = doc(db, "excercise", exerciseId);
        batch.update(exerciseDocRef, {
          name: titleValue,
          level: selectedLevelValue,
          type: exerciseTypes.multipleChoice,
        });
      } else {
        exerciseDocRef = doc(collection(db, "excercise"));
        batch.set(exerciseDocRef, {
          name: titleValue,
          level: selectedLevelValue,
          type: exerciseTypes.multipleChoice,
          createdAt: new Date(),
          createdBy: userData.uid,
          createdByName: userData.firstname ?? "N/A",
          createdByLastName: userData.lastname ?? "N/A",
        });
      }

      const tasksCollectionRef = collection(
        exerciseDocRef,
        "MultipleChoiceTasks"
      );
      contentBlocks.forEach((block, index) => {
        const blockRef = block.id
          ? doc(tasksCollectionRef, block.id)
          : doc(tasksCollectionRef);
        batch.set(blockRef, { ...block, order: index });
      });

      await batch.commit();
      setIsCorrect(true);
      setShowModal(true);
      notifySuccess("Exercise saved successfully.");
    } catch (error) {
      console.error("Error in creating/updating exercise:", error);
      notifyError("An error occurred. Please try again.");
      setIsCorrect(false);
      setShowModal(true);
    }
  };

  return (
    <CreateExerciseBody
      addNewExercise={saveExercise}
      addButtonOnClick={handleAddText}
      backFunc={backFunc}
      titleValue={titleValue}
      setTitleValue={setTitleValue}
      selectedLevelValue={selectedLevelValue}
      setSelectedLevelValue={setSelectedLevelValue}
      showModal={showModal}
      setShowModal={setShowModal}
      isCorrect={isCorrect}
      setIsCorrect={setIsCorrect}
      type={exerciseTypes.multipleChoice}
      isEditing={exerciseId ? true : false}
      id={exerciseId}
      hideAddButton={true}
      titleError={titleError}
      levelError={levelError}
    >
      {contentBlocks.map((block, index) => (
        <div>
          {block.type === "text" ? (
            <h1 className="font-bold text-2xl">{index + 1}. Text</h1>
          ) : (
            <h1 className="font-bold text-2xl">
              {index + 1}. {t("choice")}
            </h1>
          )}
          <div
            key={index}
            className="mb-4 flex flex-row items-center bg-gray-100 rounded-lg p-3"
          >
            {block.type === "text" ? (
              <LoginTextArea
                placeholder={t("yourTextHere")}
                icon={"description"}
                value={block.value}
                setValue={(e) => updateContent(index, "value", e.target.value)}
                secondParam={true}
                showError={multipleChoiceTasksError[index]}
                errorMessage={t("emptyFieldError")}
                width="w-full"
              />
            ) : (
              <div>
                {block.options.map((option, optIndex) => (
                  <div key={optIndex} className="flex items-center">
                    <LoginInput
                      placeholder={t("option") + ` ${optIndex + 1}`}
                      icon={"description"}
                      value={option}
                      setValue={(e) =>
                        updateContent(index, "options", {
                          index: optIndex,
                          option: e.target.value,
                        })
                      }
                      secondParam={true}
                      showError={multipleChoiceTasksError[index]?.[optIndex]}
                      errorMessage={t("emptyFieldError")}
                    />
                    <input
                      type="radio"
                      name={`correctOption-${index}`}
                      checked={block.correct === optIndex}
                      onChange={() => updateContent(index, "correct", optIndex)}
                      className="ml-2"
                    />
                  </div>
                ))}
              </div>
            )}
            <Button
              icon={"delete"}
              onClick={() => removeContent(index)}
              padding="p-3"
              width="w-[50px]"
            />
          </div>
        </div>
      ))}
      <div className="flex flex-row">
        <Button
          icon={"add"}
          padding="p-3"
          width="w-[150px]"
          text={t("addTextBlock")}
          onClick={handleAddText}
        />
        <Button
          icon={"add"}
          padding="p-3"
          width="w-[150px]"
          text={t("addMultipleChoiceBlock")}
          onClick={handleAddChoice}
        />
      </div>
    </CreateExerciseBody>
  );
};

export default CreateMultipleChoiceExercise;
