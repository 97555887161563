import React from "react";
import { useTranslation } from "react-i18next";
import ComboBox from "./combobox";

const ValidationComboBox = ({
  title,
  icon,
  options,
  selectedValue,
  setSelectedValue,
  hasError,
  errorMessage,
  disabled = false,
  width = "w-fit",
}) => {
  const { t } = useTranslation();

  const border = hasError
    ? " border-b-1 border-red-600 "
    : "border-b-1 border-gray-500";

  return (
    <div className={`${width}`}>
      <div
        className={` ${border}
        focus:bg-blue-50 hover:bg-blue-50 hover:border-blue-300 focus:border-blue-300 md:hover:bg-blue-50 focus:rounded-md hover:rounded-md transition-colors duration-200 ease-in-out w-full ${
          hasError ? "mb-0" : "mb-5"
        }`}
      >
        <div className="flex flex-row items-center relative">
          <span
            className="material-symbols-outlined"
            style={{ color: "grey", marginRight: "10px" }}
          >
            {icon}
          </span>
          <h1 className="text-xl text-gray-400">{t(title)}</h1>
        </div>

        <ComboBox
          disabled={disabled}
          title={t("choose")}
          options={options}
          width="w-full"
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
          isError={hasError}
        />
      </div>
      {hasError && (
        <div className="text-sm text-red-600 mb-5">{errorMessage}</div>
      )}
    </div>
  );
};

export default ValidationComboBox;
