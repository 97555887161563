import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import { fetchUserData } from "../actions/userActions";
import { db } from "../config/firebase";
import { exerciseTypes, taskState } from "../util/options";

export const fetchWordExerciseWithTasks = async (
  setIsLoading,
  exerciseId,
  setExercise,
  setTaskInputs,
  setStats
) => {
  try {
    setIsLoading(true);
    const exerciseDocRef = doc(db, "excercise", exerciseId);
    const exerciseDoc = await getDoc(exerciseDocRef);

    if (!exerciseDoc.exists()) {
      console.log("No such exercise!");
      return;
    }

    const exercise = { id: exerciseDoc.id, ...exerciseDoc.data() };

    if (exercise.type === exerciseTypes.words) {
      const wordTasksCollectionRef = collection(exerciseDocRef, "WordTasks");
      const wordTasksSnapshot = await getDocs(wordTasksCollectionRef);

      let wordTasks = wordTasksSnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .sort((a, b) => a.order - b.order);

      exercise.wordTasks = wordTasks;
    }

    setExercise(exercise);
    const newTaskInputs = exercise.wordTasks.map(() => ({
      input: "",
      taskState: taskState.notChecked,
    }));

    setTaskInputs(newTaskInputs);
    setStats({ correct: 0, total: exercise.wordTasks.length });
    setIsLoading(false);
  } catch (error) {
    console.error("Error fetching exercise:", error);
  }
};

export const fetchReadingExerciseWithTasks = async (
  setIsLoading,
  exerciseId,
  setExercise,
  setTaskInputs,
  setStats
) => {
  try {
    setIsLoading(true);
    const exerciseDocRef = doc(db, "excercise", exerciseId);
    const exerciseDoc = await getDoc(exerciseDocRef);

    if (!exerciseDoc.exists()) {
      console.log("No such exercise!");
      return;
    }

    const exercise = { id: exerciseDoc.id, ...exerciseDoc.data() };

    if (exercise.type === exerciseTypes.readingComprehension) {
      const readingTasksCollectionRef = collection(
        exerciseDocRef,
        "ReadingTasks"
      );
      const readingTasksSnapshot = await getDocs(readingTasksCollectionRef);

      let readingTasks = readingTasksSnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .sort((a, b) => a.order - b.order);

      exercise.readingTasks = readingTasks;
    }

    setExercise(exercise);
    const newTaskInputs = exercise.readingTasks.map(() => ({
      truthness: null,
      taskState: taskState.notChecked,
    }));

    setTaskInputs(newTaskInputs);
    setStats({ correct: 0, total: exercise.readingTasks.length });
    setIsLoading(false);
  } catch (error) {
    console.error("Error fetching exercise:", error);
  }
};

export const updateLastPlayed = async (userId, lastPlayedID) => {
  try {
    const userDocRef = doc(db, "users", userId);
    console.log("Updating lastPlayed for user:", userId, lastPlayedID);
    await updateDoc(userDocRef, {
      lastPlayed: lastPlayedID,
    });
    console.log("Successfully updated lastPlayed for user:", userId);
  } catch (error) {
    console.error("Error updating lastPlayed for user:", userId, error);
  }
};

export const fetchLastPlayed = async (userId) => {
  try {
    const userDocRef = doc(db, "users", userId);
    const userDocSnapshot = await getDoc(userDocRef);
    if (userDocSnapshot.exists()) {
      const userData = userDocSnapshot.data();
      return userData.lastPlayed ?? null;
    } else {
      console.log("No such user!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching lastPlayed for user:", userId, error);
    return null;
  }
};

export const fetchUserStatistics = async (userId) => {
  try {
    const userDocRef = doc(db, "users", userId);
    const userDocSnap = await getDoc(userDocRef);
    if (userDocSnap.exists()) {
      const stats = userDocSnap.data().stats;
      return stats;
    } else {
      console.log("No user found with ID:", userId);
      return null;
    }
  } catch (error) {
    console.error("Error fetching user statistics:", error);
    return null;
  }
};

export const updateUserStatistics = async (userId, exerciseType) => {
  console.log("Updating user statistics for user:", userId, exerciseType);
  try {
    const userDocRef = doc(db, "users", userId);
    const userDocSnap = await getDoc(userDocRef);
    if (userDocSnap.exists()) {
      const userStats = userDocSnap.data().stats;
      console.log("User statistics fetched successfully:", userStats);
      let updatedStats = {
        ...userStats,
        exercisesCompleted:
          (userStats.wordExercisesCompleted ?? 0) +
          (userStats.readingExercisesCompleted ?? 0) +
          (userStats.multipleChoiceExercisesCompleted ?? 0) +
          (userStats.orderExercisesCompleted ?? 0) +
          (userStats.connectionExercisesCompleted ?? 0) +
          (userStats.sentenceExercisesCompleted ?? 0) +
          1,
      };
      switch (exerciseType) {
        case exerciseTypes.words:
          updatedStats = {
            ...updatedStats,
            wordExercisesCompleted: (userStats.wordExercisesCompleted ?? 0) + 1,
          };
          break;
        case exerciseTypes.readingComprehension:
          updatedStats = {
            ...updatedStats,
            readingExercisesCompleted:
              (userStats.readingExercisesCompleted ?? 0) + 1,
          };
          break;

        case exerciseTypes.multipleChoice:
          updatedStats = {
            ...updatedStats,
            multipleChoiceExercisesCompleted:
              (userStats.multipleChoiceExercisesCompleted ?? 0) + 1,
          };
          break;
        case exerciseTypes.order:
          updatedStats = {
            ...updatedStats,
            orderExercisesCompleted:
              (userStats.orderExercisesCompleted ?? 0) + 1,
          };
          break;
        case exerciseTypes.connection:
          updatedStats = {
            ...updatedStats,
            connectionExercisesCompleted:
              (userStats.connectionExercisesCompleted ?? 0) + 1,
          };
          break;
        case exerciseTypes.sentenceCreation:
          updatedStats = {
            ...updatedStats,
            sentenceExercisesCompleted:
              (userStats.sentenceExercisesCompleted ?? 0) + 1,
          };
          break;
        default:
          break;
      }
      await updateDoc(userDocRef, { stats: updatedStats });
      console.log("User statistics updated successfully:", updatedStats);
    } else {
      console.log("No user found with ID:", userId);
    }
  } catch (error) {
    console.error("Error updating user statistics:", error);
  }
};

export const checkIfUserOwnsItem = async (userId, lootItem) => {
  try {
    const userDocRef = doc(db, "users", userId);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      const userData = userDocSnap.data();
      const { avatar } = userData;
      console.log("User owns avatar:", avatar);

      if (avatar && avatar[lootItem.category]) {
        console.log("owns", avatar[lootItem.category].includes(lootItem.name));
        return avatar[lootItem.category].includes(lootItem.name);
      }
    }
    console.log("owns", lootItem.name);
    return false;
  } catch (error) {
    console.error("Error fetching user avatar loadout:", error);
    return false;
  }
};

export const addItemToUserAvatar = async (userId, lootItem, dispatch) => {
  const userDocRef = doc(db, "users", userId);

  try {
    const userDocSnap = await getDoc(userDocRef);

    if (!userDocSnap.exists()) {
      console.log("User document does not exist!");
      return "User not found";
    }

    const userData = userDocSnap.data();
    const userAvatar = userData.avatar || {};
    const categoryItems = userAvatar[lootItem.category] || [];

    if (categoryItems.includes(lootItem.name)) {
      console.log("User already owns the item:", lootItem.name);
      return "Item already owned";
    }
    const updatedItems = [...categoryItems, lootItem.name];

    const updateData = {
      [`avatar.${lootItem.category}`]: updatedItems,
    };

    await updateDoc(userDocRef, updateData);
    console.log("Item added to user's avatar:", lootItem.name);
    await dispatch(fetchUserData(userData.uid));
    return "Item added successfully";
  } catch (error) {
    console.error("Error adding item to user avatar:", error);
    return "Failed to add item";
  }
};

export const fetchSentenceExerciseWithTasks = async (
  setIsLoading,
  exerciseId,
  setExercise,
  setTaskInputs
) => {
  try {
    setIsLoading(true);
    const exerciseDocRef = doc(db, "excercise", exerciseId);
    const exerciseDoc = await getDoc(exerciseDocRef);

    if (!exerciseDoc.exists()) {
      console.log("No such exercise!");
      setIsLoading(false);
      return;
    }

    const exercise = { id: exerciseDoc.id, ...exerciseDoc.data() };

    if (exercise.type === exerciseTypes.sentenceCreation) {
      const sentenceTasksCollectionRef = collection(
        exerciseDocRef,
        "SentenceTasks"
      );
      const sentenceTasksSnapshot = await getDocs(sentenceTasksCollectionRef);

      let sentenceTasks = sentenceTasksSnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .sort((a, b) => a.order - b.order);

      exercise.sentenceTasks = sentenceTasks;
    }

    setExercise(exercise);
    const newTaskInputs = exercise.sentenceTasks.map(() => ({
      input: "",
      taskState: taskState.notChecked,
    }));

    setTaskInputs(newTaskInputs);
    console.log("Exercise fetched successfully:", exercise);
    setIsLoading(false);
  } catch (error) {
    console.error("Error fetching exercise:", error);
    setIsLoading(false);
  }
};

export const fetchMultipleChoiceExerciseWithTasks = async (
  setIsLoading,
  exerciseId,
  setExercise,
  setTaskInputs
) => {
  try {
    console.log("Fetching exercise with ID:", exerciseId);
    setIsLoading(true);
    const exerciseDocRef = doc(db, "excercise", exerciseId);
    const exerciseDoc = await getDoc(exerciseDocRef);

    if (!exerciseDoc.exists()) {
      console.log("No such exercise!");
      setIsLoading(false);
      return;
    }

    const exercise = { id: exerciseDoc.id, ...exerciseDoc.data() };
    console.log("Exercise fetched:", exercise);
    if (exercise.type === exerciseTypes.multipleChoice) {
      const multipleChoiceTasksCollectionRef = collection(
        exerciseDocRef,
        "MultipleChoiceTasks"
      );

      const multipleChoiceTasksSnapshot = await getDocs(
        multipleChoiceTasksCollectionRef
      );

      let multipleChoiceTasks = multipleChoiceTasksSnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .sort((a, b) => a.order - b.order);

      exercise.multipleChoiceTasks = multipleChoiceTasks;
    }

    setExercise(exercise);
    const newTaskInputs = exercise.multipleChoiceTasks.map((task) => ({
      text: "",
      taskState: taskState.notChecked,
      options: task.options,
    }));

    setTaskInputs(newTaskInputs);
    console.log("Exercise fetched successfully:", exercise);
    setIsLoading(false);
  } catch (error) {
    console.error("Error fetching exercise:", error);
    setIsLoading(false);
  }
};

export const fetchOrderExerciseWithTasks = async (
  setIsLoading,
  exerciseId,
  setExercise,
  setTaskInputs
) => {
  try {
    console.log("Fetching exercise with ID:", exerciseId);
    setIsLoading(true);
    const exerciseDocRef = doc(db, "excercise", exerciseId);
    const exerciseDoc = await getDoc(exerciseDocRef);

    if (!exerciseDoc.exists()) {
      console.log("No such exercise!");
      setIsLoading(false);
      return;
    }

    const exercise = { id: exerciseDoc.id, ...exerciseDoc.data() };
    console.log("Exercise details:", exercise);

    if (exercise.type === exerciseTypes.order) {
      const orderTasksCollectionRef = collection(exerciseDocRef, "OrderTasks");
      const orderTasksSnapshot = await getDocs(orderTasksCollectionRef);

      let orderTasks = orderTasksSnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .sort((a, b) => a.order - b.order);

      orderTasks.forEach((task) => {
        task.initialWords = [...task.words];
        task.words = shuffleArray([...task.words]);
      });

      exercise.orderTasks = orderTasks;
    }

    setExercise(exercise);
    const newTaskInputs = exercise.orderTasks.map((task) => ({
      words: [...task.words],
      taskState: "notChecked",
    }));

    setTaskInputs(newTaskInputs);
    console.log("Order tasks set up successfully:", exercise);
    setIsLoading(false);
  } catch (error) {
    console.error("Error fetching order exercise:", error);
    setIsLoading(false);
  }
};

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export const fetchConnectionExerciseWithTasks = async (
  setIsLoading,
  exerciseId,
  setExercise,
  setTaskInputs
) => {
  setIsLoading(true);
  try {
    const exerciseDocRef = doc(db, "excercise", exerciseId);
    const exerciseDoc = await getDoc(exerciseDocRef);

    if (!exerciseDoc.exists()) {
      console.log("No such exercise!");
      setIsLoading(false);
      return;
    }

    const exercise = { id: exerciseDoc.id, ...exerciseDoc.data() };
    console.log("Exercise details:", exercise);
    setExercise(exercise);

    if (exercise.type === exerciseTypes.connection) {
      const connectionTasksCollectionRef = collection(
        exerciseDocRef,
        "ConnectionTasks"
      );
      const connectionTasksSnapshot = await getDocs(
        connectionTasksCollectionRef
      );

      let connectionTasks = connectionTasksSnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .sort((a, b) => a.order - b.order);

      connectionTasks = shufflePairs(connectionTasks);

      exercise.connectionTasks = connectionTasks;
    }

    const newTaskInputs = exercise.connectionTasks.map((task) => ({
      left: task.left,
      right: task.right,
      userLeft: "",
      userRight: "",
      taskState: "notChecked",
    }));

    setTaskInputs(newTaskInputs);
    setIsLoading(false);
  } catch (error) {
    console.error("Error fetching connection exercise:", error);
    setIsLoading(false);
  }
};

function shufflePairs(tasks) {
  const shuffledTasks = tasks
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);

  return shuffledTasks;
}

export const deleteExercise = async (exerciseId) => {
  const exerciseRef = doc(db, "excercise", exerciseId);

  try {
    const subcollections = [
      "WordTasks",
      "ReadingTasks",
      "SentenceTasks",
      "MultipleChoiceTasks",
      "OrderTasks",
      "ConnectionTasks",
    ];

    const batch = writeBatch(db);

    for (const subcollection of subcollections) {
      const collectionRef = collection(exerciseRef, subcollection);
      const snapshot = await getDocs(collectionRef);

      snapshot.docs.forEach((doc) => {
        batch.delete(doc.ref);
      });
    }

    await batch.commit();

    await deleteDoc(exerciseRef);
    console.log("Main document and all subcollections deleted successfully.");
  } catch (error) {
    console.error(
      "Error removing exercise document and subcollections:",
      error
    );
  }
};
