import React from "react";
import Footer from "./footer";
import PageTransition from "./pageTransition";
import * as animations from "../../util/animations";
import icon from "../../img/logo.png";
import LoadingCard from "./loadingCard";
import { skeletonTypes } from "../../util/options";
import LoadingHeader from "./loadingHeader";

const HomepageSkeleton = () => {
  return (
    <div className="animate-pulse h-full w-full flex flex-grow flex-col">
      <div className="h-full w-full flex flex-grow flex-col">
        <div className="items-center sm:items-start sm:p-4 text-2xl sm:text-5xl font-bold w-fit rounded-3xl flex flex-row ml-5 mt-5 text-primaryColor bg-gray-300">
          <h1 className="text-primaryBlue bg-gray-300 opacity-0">
            Vitaj&nbsp;
          </h1>
          <h1 className=" text-primaryColor opacity-0 "> placeholder,</h1>
          <h1 className="text-primaryColor opacity-0">&nbsp;v SpielMit</h1>
        </div>
        <div className="flex flex-col flex-1">
          <div className="flex flex-col md:flex-row w-full flex-1">
            <LoadingCard icon={icon} />
          </div>
          <div className="flex flex-col md:flex-row w-full flex-1">
            <LoadingCard icon={icon} />
            <LoadingCard icon={icon} />
            <LoadingCard icon={icon} />
          </div>
          <div className="flex flex-col md:flex-row w-full flex-1">
            <LoadingCard icon={icon} />
            <LoadingCard icon={icon} />
          </div>
        </div>
      </div>
    </div>
  );
};

const StatisticsSkeleton = () => {
  return (
    <div className="animate-pulse flex flex-col flex-1 items-center h-full w-full ">
      <h1 className="border-b border-transparent text-transparent rounded-2xl font-bold text-5xl bg-gray-300 mt-2">
        {" "}
        Vaše štatistky
      </h1>
      <div className="w-full flex flex-col flex-1">
        <div className="flex  flex-row flex-1">
          <LoadingCard
            icon={icon}
            textSize="text-8xl"
            secondaryTextSize="text-xl sm:text-2xl"
          />
        </div>
        <div className="flex flex-1 flex-row">
          <LoadingCard
            icon={icon}
            textSize="text-8xl"
            secondaryTextSize="text-xl sm:text-2xl"
          />
          <LoadingCard
            icon={icon}
            textSize="text-8xl"
            secondaryTextSize="text-xl sm:text-2xl"
          />
        </div>
        <div className="flex flex-1 flex-row">
          <LoadingCard
            icon={icon}
            textSize="text-8xl"
            secondaryTextSize="text-xl sm:text-2xl"
          />
          <LoadingCard
            icon={icon}
            textSize="text-8xl"
            secondaryTextSize="text-xl sm:text-2xl"
          />
        </div>
        <div className="flex flex-1 flex-row">
          <LoadingCard
            icon={icon}
            textSize="text-8xl"
            secondaryTextSize="text-xl sm:text-2xl"
          />
          <LoadingCard
            icon={icon}
            textSize="text-8xl"
            secondaryTextSize="text-xl sm:text-2xl"
          />
        </div>
      </div>
    </div>
  );
};

const skeletonComponents = {
  homepage: HomepageSkeleton,
  statistics: StatisticsSkeleton,
};

const LoadingPage = ({
  skeletonType = skeletonTypes.homepage,
  animationPage = animations.slideUpEffect,
  animationHeader = animations.slideUpEffectLoadOut,
}) => {
  const SkeletonComponent = skeletonComponents[skeletonType];
  return (
    <div className="h-fit min-h-screen w-full bg-pageBackground items-center flex flex-col">
      <LoadingHeader variants={animationHeader} />
      <PageTransition
        variants={{
          initial: {
            opacity: 0,
            y: 100,
          },
          in: {
            opacity: 1,
            y: 0,
            transition: { duration: 1, ease: "easeInOut" },
          },
          out: {
            opacity: 0,
            y: -50,
            transition: { duration: 0.3, ease: "easeInOut" },
          },
        }}
        extraStyles="h-full w-full flex flex-grow flex-col"
      >
        <SkeletonComponent />
      </PageTransition>
      <Footer />
    </div>
  );
};

export default LoadingPage;
