import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Login from "../../components/auth/login";
import PasswordReset from "../../pages/PasswordResetConfirmPage/index";

import Footer from "../../components/shared/footer";
import Header from "../../components/shared/header";
import PageTransition from "../../components/shared/pageTransition";
import * as animations from "../../util/animations";

const Auth = () => {
  const [searchParams] = useSearchParams();
  const [mode, setMode] = useState("login"); // Default mode is login
  const [oobCode, setOobCode] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [lang, setLang] = useState("en");
  const modeParam = searchParams.get("mode");
  const oobCodeParam = searchParams.get("oobCode");
  const apiKeyParam = searchParams.get("apiKey");
  const langParam = searchParams.get("lang");

  useEffect(() => {
    if (oobCodeParam) setOobCode(oobCodeParam);
    if (apiKeyParam) setApiKey(apiKeyParam);
    if (langParam) setLang(langParam);

    switch (modeParam) {
      case "resetPassword":
        setMode("resetPassword");
        break;
      default:
        setMode("login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <div className="bg-pageBackground h-screen w-screen flex flex-col items-center">
      <Header />
      <div className="fade In p-4 flex-grow">
        <PageTransition variants={animations.slideInLeft}>
          {mode === "login" && <Login />}
          {mode === "resetPassword" && (
            <PasswordReset oobCode={oobCode} apiKey={apiKey} lang={lang} />
          )}
        </PageTransition>
      </div>
      <Footer />
    </div>
  );
};

export default Auth;
