import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../components/shared/button";
import Footer from "../../components/shared/footer";
import Header from "../../components/shared/header";
import PageTransition from "../../components/shared/pageTransition";
import Logo from "../../img/logo.png";
import Slovakia from "../../img/slovakia.png";
import Teacher from "../../img/teacher.png";
import * as animations from "../../util/animations";

const LandingPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user.userData);

  React.useEffect(() => {
    if (userData) {
      navigate("/home");
    }
  }, [navigate, userData]);

  return (
    <div className="overflow-x-hidden h-fit min-h-screen w-full bg-pageBackground items-center flex flex-col ">
      <Header>
        <Button
          buttonType={"cta"}
          text={t("loginButtonText")}
          onClick={() => navigate("/login")}
          extraStyle={
            "lg:mr-12 mb-2 lg:mb-0 px-14 py-3 focus:ring whitespace-nowrap"
          }
        ></Button>
        <Button
          buttonType={"cta"}
          text={t("registerButtonText")}
          width="w-[220px]"
          heigth="h-full"
          onClick={() => navigate("/register")}
          extraStyle={" mb-2 lg:mb-0  px-14 py-3 focus:ring whitespace-nowrap"}
        ></Button>
      </Header>
      <PageTransition
        variants={animations.slideUpEffect}
        extraStyles="h-full w-full bg-neutral  flex flex-col flex-1 p-8"
      >
        <div className="text-center w-full px-6 py-8 mb-6 bg- border-b rounded-3xl flex items-center justify-center flex-1 flex-col drop-shadow-lg bg-cardBg">
          <div className="flex flex-row items-center justify-center">
            <div>
              <h1 className="text-5xl sm:text-7xl font-bold leading-none text-headerText">
                {t("landingPageTitle")}
              </h1>
              <p className=" mt-6 text-xl sm:text-2xl text-underHeaderText">
                {t("landingPageSecondaryTitle")}
              </p>
            </div>
            <img
              src={Logo}
              className="w-28 sm:w-52 sm:h-52 ml-4"
              alt={String(Logo)}
            />
          </div>
          <div className="flex flex-col w-fit justify-center mx-auto">
            <button
              onClick={() => navigate("/register")}
              className="mt-6 inline-block bg-ctaPrimary text-white font-bold py-1 sm:py-3 px-6 rounded-full hover:bg-ctaHover transition duration-200 text-xl sm:text-3xl"
            >
              {t("startButtonText")}
            </button>
            <div className="flex flex-row items-center">
              <p className="text-xl sm:text-2xl mt-4  ">
                {t("alreadyHaveAccount")}
                &nbsp;
              </p>
              <button
                className="text-ctaPrimary text-xl sm:text-2xl mt-4 hover:underline "
                onClick={() => navigate("/login")}
              >
                {t("login")}
              </button>
            </div>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row w-full justify-center flex-2 lg:flex-1">
          <div className="text-center px-6 py-8  bg-cardBg border-b rounded-3xl  mr-5 w-full flex-row flex items-center justify-center flex-1 mb-5 lg:mb-0 drop-shadow-lg">
            <div>
              <h1 className="text-4xl sm:text-5xl font-bold leading-none text-headerText">
                {t("landingPageSlovak")}
              </h1>
              <p className="text-underHeaderText mt-6 text-xl  md:text-2xl">
                {t("landingPageSlovakSecondary")}
              </p>
            </div>
            <img
              src={Slovakia}
              className="w-28 sm:w-34 sm:h-34 md:w-52 md:h-52 ml-4"
              alt={String(Slovakia)}
            />
          </div>
          <div className="text-center px-6 py-6  bg-cardBg border-b rounded-3xl  justify-center w-full flex flex-row items-center flex-1 drop-shadow-lg">
            <div>
              <h1 className="text-4xl sm:text-5xl font-bold leading-none text-headerText">
                {t("landingPageExercises")}
              </h1>
              <p className=" mt-6 text-xl sm:text-2xl text-underHeaderText">
                {t("landingPageExercisesSecondary")}
              </p>
            </div>
            <img
              src={Teacher}
              className="w-28 sm:w-34 sm:h-34 md:w-52 md:h-52 ml-4"
              alt={String(Teacher)}
            />
          </div>
        </div>
      </PageTransition>
      <Footer />
    </div>
  );
};

export default LandingPage;
