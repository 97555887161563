import React from "react";
import Input from "../shared/input";

export default function LoginInput({
  value,
  setValue,
  showError,
  errorMessage,
  icon,
  placeholder,
  secondParam = false,
  inputType = "text",
  disabled = false,
  width = "w-full",
  extraStyles = "",
}) {
  return (
    <div>
      <div className={`flex flex-row items-center relative ${extraStyles}`}>
        <Input
          disabled={disabled}
          placeholderName={""}
          value={value}
          inputType={inputType}
          width={width}
          onChangeFunction={(e) => setValue(secondParam ? e : e.target.value)}
          extraStyles={`${showError ? "mb-0" : "mb-4"} pt-10 pb-5`}
          border={
            showError
              ? "border-b-2 border-red-500"
              : "border-b-2 border-gray-500"
          }
        />
        <div className="absolute top-0 items-center flex flex-row">
          <span
            className="material-symbols-outlined"
            style={{ color: "grey", marginRight: "10px" }}
          >
            {icon}
          </span>
          <h1 className="text-xl text-gray-400">{placeholder}</h1>
        </div>
      </div>
      <div
        className={`transition-opacity duration-300 ease-in-out mb-5 ${
          showError ? "opacity-100" : "opacity-0 pointer-events-none h-0"
        }`}
        style={{ transitionProperty: "height, opacity" }}
      >
        {showError && <p className="text-sm text-red-600">{errorMessage}</p>}
      </div>
    </div>
  );
}
