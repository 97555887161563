import React from "react";

const Card = ({
  onClick,
  icon,
  text,
  flex = "col",
  textSize = "text-4xl",
  imgSize = "w-32 h-32",
  padding = "p-4",
  secondaryText = "",
  secondaryTextSize = "text-2xl",
  hover = "hover:bg-cardBgHover hover:text-cardBgHover hover:shadow-lg",
}) => {
  return (
    <div className="flex-1 mx-5 my-2 sm:my-5  ">
      <button
        className={`${padding} font-bold w-full h-full flex flex-${flex} flex-1 justify-center ${textSize} text-black rounded-3xl bg-cardBg items-center transition transform hover:scale-105 shadow-md duration-150 ${hover}`}
        onClick={onClick}
      >
        <div className="flex flex-col">
          <h1 className="border-b border-transparent text-headerText">
            {text}
          </h1>
          <h1 className={`${secondaryTextSize} text-underHeaderText`}>
            {secondaryText}
          </h1>
        </div>
        <img src={icon} className={`${imgSize} ml-4`} alt={String(icon)} />
      </button>
    </div>
  );
};

export default Card;
