import React from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import PrivateRoute from "./components/shared/privateRoute";
import routeConfig from "./routes";
import { useDispatch } from "react-redux";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./config/firebase";
import { fetchUserData, clearUserData } from "./actions/userActions";
import { AnimatePresence } from "framer-motion";
import LoadingPage from "./components/shared/loadingPage";
import * as animations from "./util/animations";
import "./i18n";
import { ToastContainer } from "react-toastify";
import ScrollToTop from "./util/scrollToTop";

const App = () => {
  const dispatch = useDispatch();
  const [authInitialized, setAuthInitialized] = React.useState(false);

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        await dispatch(fetchUserData(user.uid))
          .then(() => {
            setAuthInitialized(true);
          })
          .catch((error) => {
            console.error("Error fetching user data:", error);
            setAuthInitialized(false);
          });
      } else {
        dispatch(clearUserData());
        auth.signOut().finally(() => {
          setAuthInitialized(true);
        });
      }
    });

    return () => unsubscribe();
  }, [dispatch]);

  if (!authInitialized) {
    return (
      <Router>
        <LoadingPage
          animationHeader={animations.slideUpEffectLoadIn}
          animationPage={animations.slideUpEffectLoadIn}
        />
      </Router>
    );
  }

  return (
    <Router>
      <ScrollToTop />
      <RouteHandler />
    </Router>
  );
};

const RouteHandler = () => {
  const location = useLocation();
  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        {" "}
        {routeConfig.map((route, index) => {
          const { path, component: Component, isProtected, roles } = route;
          const routeElement = isProtected ? (
            <PrivateRoute key={index} path={path} roles={roles}>
              <Component />
            </PrivateRoute>
          ) : (
            <Component />
          );
          return <Route key={path} path={path} element={routeElement} />;
        })}
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </AnimatePresence>
  );
};

export default App;
