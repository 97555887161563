import React, { useState } from "react";
import ProfileAttribute from "./profileAttribute";
import { motion, AnimatePresence } from "framer-motion";
import { exerciseDifficultyLabels, exerciseNames } from "../../util/options";
import { useTranslation } from "react-i18next";

const ExerciseListItem = ({ exercise, children }) => {
  const { t } = useTranslation();
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const detailVariants = {
    hidden: { opacity: 0, height: 0, overflow: "hidden" },
    visible: { opacity: 1, height: "auto", transition: { duration: 0.3 } },
  };

  return (
    <div className="bg-cardBg  mb-5 w-full rounded-lg flex flex-col sm:flex-row justify-between">
      <div className="flex flex-col sm:flex-row">
        <div className="flex flex-row justify-between">
          <ProfileAttribute title={t("name")} value={exercise.name} />

          <button onClick={toggleDetails} className="mr-5 sm:hidden text-xl">
            <span className="material-symbols-outlined">
              {showDetails ? "expand_less" : "expand_more"}
            </span>
          </button>
        </div>
        <AnimatePresence>
          {(showDetails || window.innerWidth >= 640) && (
            <motion.div
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={detailVariants}
              className="flex flex-col sm:flex-row w-full"
            >
              <ProfileAttribute
                title={t("level")}
                value={exerciseDifficultyLabels[exercise.level]}
                // style={"w-36"}
              />
              <ProfileAttribute
                title={t("created")}
                value={new Date(exercise.createdAt).toLocaleDateString()}
              />
              <ProfileAttribute
                title={t("createdBy")}
                value={exercise.createdByName}
              />
              <ProfileAttribute
                title={t("type")}
                value={exerciseNames(t)[exercise.type - 1]}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div className="flex flex-col items-center mb-5 pr-5 pt-5">
        {children}
      </div>
    </div>
  );
};

export default ExerciseListItem;
