import { signInWithEmailAndPassword } from "firebase/auth";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../config/firebase";
import "../../pages/LoginPage/loginPage.css";
import LoginInput from "../shared/LoginInput";
import ActivityIndicator from "../shared/activityIndicator";
import Button from "../shared/button";
import LoginImg from "../../img/login.png";
import { useTranslation } from "react-i18next";
import { notifyError } from "../../util/toast";

const Login = () => {
  const { t } = useTranslation();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [showError, setShowError] = useState(false);
  const [emailError, setEmailError] = useState({ value: false, message: "" });
  const [passwordError, setPasswordError] = useState({
    value: false,
    message: "",
  });

  const navigate = useNavigate();

  const logIn = async () => {
    if (!validateInputs()) {
      return;
    }
    setLoading(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/home");
    } catch (error) {
      setShowError(true);
      notifyError(t("invalidCredentials"));
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const validateInputs = () => {
    let isValid = true;
    if (!email.trim()) {
      setEmailError({ value: true, message: t("emailRequired") });
      isValid = false;
    } else {
      setEmailError({ value: false, message: "" });
    }

    if (!password.trim()) {
      setPasswordError({ value: true, message: t("passwordRequired") });
      isValid = false;
    } else {
      setPasswordError({ value: false, message: "" });
    }

    return isValid;
  };

  return (
    <div className="items-center flex flex-col justify-center w-full sm:w-fit h-full sm:h-fit bg-cardBg rounded-3xl p-10 shadow-md ">
      <img src={LoginImg} className="w-44 h-44" alt={String(LoginImg)} />

      <h1 className="text-3xl mb-5 font-bold text-primaryColor">
        {t("loginTitle")}
      </h1>
      <LoginInput
        value={email}
        setValue={setEmail}
        icon={"person"}
        placeholder={"Email"}
        showError={emailError.value}
        errorMessage={emailError.message}
      />
      <LoginInput
        value={password}
        setValue={setPassword}
        icon={"lock"}
        placeholder={t("password")}
        inputType="password"
        showError={passwordError.value}
        errorMessage={passwordError.message}
      />
      {showError && (
        <h1 className="text-xl text-red-600 mb-4 underline">
          {t("invalidCredentials")}
        </h1>
      )}
      <button
        className="b-none bg-transparent text-ctaPrimary items-center hover:underline"
        onClick={() => navigate("/passwordReset")}
      >
        {t("forgotPassword")}
      </button>
      <div className="mt-2 mb-3">
        {loading ? (
          <ActivityIndicator color={"black"} />
        ) : (
          <Button
            text={t("loginButtonText")}
            onClick={logIn}
            buttonType={"cta"}
          />
        )}
      </div>
      <span className="signIn">
        <p className="signIn-text">{t("dontHaveAccount")}</p>
        <button
          className="b-none bg-transparent text-ctaPrimary items-center ml-2 hover:underline"
          onClick={() => navigate("/register")}
        >
          {t("register")}
        </button>
      </span>
    </div>
  );
};

export default Login;
