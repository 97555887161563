//Head styles
import MaleHeadDefault from "../img/avatars/male_head_default.png";
import FemaleHeadDefault from "../img/avatars/female_head_default.png";
import MaleHeadSanta from "../img/avatars/male_head_santa.png";
import FemaleHeadSanta from "../img/avatars/female_head_santa.png";
import FemaleHeadStudent from "../img/avatars/female_head_student.png";
import FemaleHeadHat from "../img/avatars/female_head_hat.png";
import MaleHeadStudent from "../img/avatars/male_head_student.png";
import MaleHeadHat from "../img/avatars/male_head_hat.png";

//Torso styles
//MALE
import MaleTorsoBlue from "../img/avatars/male_torso_blue.png";
import MaleTorsoGreen from "../img/avatars/male_torso_green.png";
import MaleTorsoRed from "../img/avatars/male_torso_red.png";
import MaleTorsoSpielMit from "../img/avatars/male_torso_spielmit.png";
import MaleTorsoDefault from "../img/avatars/male_torso_default.png";
//FEMALE
import FemaleTorsoDefault from "../img/avatars/female_torso_default.png";
import FemaleTorsoRed from "../img/avatars/female_torso_red.png";
import FemaleTorsoPink from "../img/avatars/female_torso_pink.png";
import FemaleTorsoSpielMit from "../img/avatars/female_torso_spielmit.png";
import FemaleTorsoLove from "../img/avatars/female_torso_love.png";

//Pants styles
import MalePantsDefault from "../img/avatars/male_pants_default.png";
import FemaleLegsDefault from "../img/avatars/female_legs_default.png";
import FemaleLegsRed from "../img/avatars/female_legs_red.png";
import FemaleLegsYellow from "../img/avatars/female_legs_yellow.png";
import FemaleLegsPink from "../img/avatars/female_legs_pink.png";
import MaleLegsRed from "../img/avatars/male_legs_red.png";
import MaleLegsBlue from "../img/avatars/male_legs_blue.png";
import MaleLegsYellow from "../img/avatars/male_legs_yellow.png";

//Boots styles
import MaleBootsDefault from "../img/avatars/male_boots_default.png";
import FemaleBootsDefault from "../img/avatars/female_boots_default.png";
import FemaleBootsViolet from "../img/avatars/female_boots_violet.png";
import FemaleBoootsGreen from "../img/avatars/female_boots_green.png";
import FemaleBootsRed from "../img/avatars/female_boots_red.png";
import MaleBootsKhaki from "../img/avatars/male_boots_khaki.png";
import MaleBootsRed from "../img/avatars/male_boots_red.png";
import MaleBootsGreen from "../img/avatars/male_boots_green.png";

export const maleAvatarImages = {
  heads: {
    default: MaleHeadDefault,
    santa: MaleHeadSanta,
    student: MaleHeadStudent,
    hat: MaleHeadHat,
  },
  torsos: {
    default: MaleTorsoDefault,
    blue: MaleTorsoBlue,
    green: MaleTorsoGreen,
    red: MaleTorsoRed,
    spielmit: MaleTorsoSpielMit,
  },
  legs: {
    default: MalePantsDefault,
    red: MaleLegsRed,
    blue: MaleLegsBlue,
    yellow: MaleLegsYellow,
  },
  boots: {
    default: MaleBootsDefault,
    khaki: MaleBootsKhaki,
    red: MaleBootsRed,
    green: MaleBootsGreen,
  },
};

export const maleAvatarItemsArrays = {
  heads: Object.keys(maleAvatarImages.heads),
  torsos: Object.keys(maleAvatarImages.torsos),
  legs: Object.keys(maleAvatarImages.legs),
  boots: Object.keys(maleAvatarImages.boots),
};

export const avatarItemsTypes = ["Head", "Torso", "Legs", "Boots"];

export const femaleAvatarImages = {
  heads: {
    default: FemaleHeadDefault,
    santa: FemaleHeadSanta,
    student: FemaleHeadStudent,
    hat: FemaleHeadHat,
  },
  torsos: {
    default: FemaleTorsoDefault,
    red: FemaleTorsoRed,
    pink: FemaleTorsoPink,
    spielmit: FemaleTorsoSpielMit,
    love: FemaleTorsoLove,
  },
  legs: {
    default: FemaleLegsDefault,
    red: FemaleLegsRed,
    yellow: FemaleLegsYellow,
    pink: FemaleLegsPink,
  },
  boots: {
    default: FemaleBootsDefault,
    violet: FemaleBootsViolet,
    green: FemaleBoootsGreen,
    red: FemaleBootsRed,
  },
};

export const femaleAvatarItemsArrays = {
  heads: Object.keys(femaleAvatarImages.heads),
  torsos: Object.keys(femaleAvatarImages.torsos),
  legs: Object.keys(femaleAvatarImages.legs),
  boots: Object.keys(femaleAvatarImages.boots),
};

export const getAvatarItemCategories = (t) => {
  return {
    heads: t("heads"),
    torsos: t("torsos"),
    legs: t("legs"),
    boots: t("boots"),
  };
};

export const getAvatarItemNames = (t) => {
  return {
    default: t("default"),
    santa: t("santa"),
    student: t("student"),
    hat: t("hat"),
    blue: t("blue"),
    green: t("green"),
    red: t("red"),
    spielmit: t("spielmit"),
    pink: t("pink"),
    love: t("love"),
    yellow: t("yellow"),
    violet: t("violet"),
    khaki: t("khaki"),
  };
};
