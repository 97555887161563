import React from "react";
import colors from "../../util/colors";
import LoadingIndicator from "./activityIndicator";

const Button = ({
  icon,
  buttonType = "cta",
  text = "",
  onClick,
  width = "w-[220px]",
  heigth = "h-full",
  extraStyle = "",
  isLoading = false,
  padding = "px-14 py-3",
}) => {
  const colorClass = colors.button[buttonType]?.button;
  const textColor = colors.button[buttonType]?.text;

  return (
    <button
      className={`transition ease-in-out delay-30 hover:-translate-y-1 hover:scale-110 ${colorClass} duration-300 rounded-full border-0 ${textColor} ${width} ${heigth} font-bold text-2xl font-roboto  flex items-center justify-center ${extraStyle} ${padding}  focus:ring whitespace-nowrap`}
      onClick={onClick}
    >
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <div className="flex flex-row items-center">
          {" "}
          <span className="material-symbols-outlined">{icon}</span>
          <h1 className="w-max-content">{text}</h1>
        </div>
      )}
    </button>
  );
};

export default Button;
