// PasswordReset.js
import React, { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../config/firebase";
import { useNavigate } from "react-router-dom";
import Button from "../../components/shared/button";
import ActivityIndicator from "../../components/shared/activityIndicator";
import Header from "../../components/shared/header";
import LoginInput from "../../components/shared/LoginInput";
import { useTranslation } from "react-i18next";
import Footer from "../../components/shared/footer";
import PageTransition from "../../components/shared/pageTransition";
import * as animations from "../../util/animations";

const PasswordReset = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handlePasswordReset = async () => {
    setLoading(true);
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage(t("resetEmailSent"));
    } catch (error) {
      setError(t("resetEmailError"));
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-pageBackground min-h-screen h-fit items-center flex flex-col">
      <Header />
      <PageTransition
        variants={animations.slideInLeft}
        extraStyles="h-full flex-1"
      >
        <div className="items-center flex flex-col justify-center w-full sm:w-fit h-full sm:h-fit bg-cardBg rounded-3xl p-10 shadow-md mt-10">
          <h1 className="text-3xl mb-5 font-bold">{t("passwordReset")}</h1>
          <LoginInput
            placeholderName="Email"
            value={email}
            icon={"person"}
            placeholder={"Email"}
            setValue={setEmail}
            border={error ? "border-red-400" : "border-gray-400"}
            extraStyles="mb-5"
          />

          {message && <p className="text-xl text-green-500">{message}</p>}
          {error && <p className="text-xl text-red-400">{error}</p>}

          {loading ? (
            <ActivityIndicator color={"black"} />
          ) : (
            <Button
              text={t("resetPassword")}
              onClick={handlePasswordReset}
              buttonType={"cta"}
              extraStyle="mt-5 mb-2"
            />
          )}

          <span className="signIn">
            <p className="signIn-text">{t("passwordRemembered")} &nbsp;</p>
            <button
              className="signIn-button"
              onClick={() => navigate("/login")}
            >
              {t("login")}
            </button>
          </span>
        </div>
      </PageTransition>
      <Footer />
    </div>
  );
};

export default PasswordReset;
