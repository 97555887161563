import { maleAvatarItemsArrays, femaleAvatarItemsArrays } from "./avatarStyles";

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getRandomAvatarItem(gender) {
  const avatarItemsArrays =
    gender === "male" ? maleAvatarItemsArrays : femaleAvatarItemsArrays;
  const categories = Object.keys(avatarItemsArrays).filter(
    (category) => category !== "default"
  );
  const randomCategory = categories[getRandomInt(0, categories.length - 1)];

  const items = avatarItemsArrays[randomCategory];
  const itemKeys = Object.keys(items).filter(
    (itemKey) => items[itemKey] !== "default"
  );
  const randomItemKey = itemKeys[getRandomInt(0, itemKeys.length - 1)];
  const randomItem = items[randomItemKey];

  return {
    type: randomItemKey,
    name: randomItem,
    category: randomCategory,
  };
}
