import React, { useState } from "react";
import Header from "../../components/shared/header";
import Button from "../../components/shared/button";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../config/firebase";
import LoadingPage from "../../components/shared/loadingPage";
import ExerciseListItem from "../../components/shared/exerciseListItem";
import { format } from "date-fns";
import { editExerciseLinks } from "../../util/options";
import PageTransition from "../../components/shared/pageTransition";
import * as animations from "../../util/animations";
import Footer from "../../components/shared/footer";
import { useTranslation } from "react-i18next";

const MyExercises = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [myExercises, setMyExercises] = useState([]);
  const userData = useSelector((state) => state.user.userData);
  const { t } = useTranslation();

  const compareFunction = (a, b) => {
    if (a.createdAt < b.createdAt) {
      return 1;
    } else if (a.createdAt > b.createdAt) {
      return -1;
    }
    return 0;
  };

  React.useEffect(() => {
    const getMyExercises = async () => {
      try {
        setIsLoading(true);
        const querySnapshot = await getDocs(collection(db, "excercise"));
        const documents = querySnapshot.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          .filter((exercise) => exercise.createdBy === userData.uid)
          .sort(compareFunction);
        setMyExercises(documents);
        setIsLoading(false);
      } catch (error) {
        console.error("Error getting documents: ", error);
      }
    };
    getMyExercises();
    // eslint-disable-next-line
  }, []);

  return isLoading ? (
    <LoadingPage />
  ) : (
    <div>
      <div className="bg-pageBackground h-fit min-h-screen">
        <Header navigateTo={() => navigate("/home")}></Header>
        <PageTransition
          variants={animations.slideUpEffect}
          extraStyles="flex flex-col-reverse sm:flex-row items-center p-10"
        >
          <div className="flex flex-col-reverse sm:flex-row items-center p-10 w-full">
            <div className="flex flex-col flex-3 w-full">
              <h1 className="text-3xl flex flex-col font-bold w-full ml-auto mr-3 text-headerText">
                {" "}
                {myExercises.length > 0
                  ? t("myExercisesButtonText")
                  : t("noMyExercises")}
              </h1>
              {myExercises.map((exercise, index) => {
                const startDate = new Date(
                  exercise.createdAt.seconds * 1000 +
                    exercise.createdAt.nanoseconds / 1000000
                );

                const formattedCreatedAt = format(startDate, "PPpp");
                return (
                  <ExerciseListItem
                    key={index}
                    exercise={{ ...exercise, createdAt: formattedCreatedAt }}
                  >
                    <Button
                      icon={"play_arrow"}
                      buttonType={"cta"}
                      text={t("edit")}
                      onClick={() => {
                        navigate(
                          editExerciseLinks[exercise.type] + exercise.id
                        );
                      }}
                    />
                  </ExerciseListItem>
                );
              })}
            </div>
          </div>
        </PageTransition>
      </div>
      <Footer />
    </div>
  );
};

export default MyExercises;
