import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ children, roles }) => {
  const location = useLocation();
  const userData = useSelector((state) => state.user.userData);

  if (!userData) {
    console.log("Redirecting to login due to no userData.");
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  const userHasRequiredRole = roles ? roles.includes(userData.role) : true;

  if (!userHasRequiredRole) {
    console.log("Redirecting to home due to insufficient role.");
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
};

export default PrivateRoute;
