import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { fetchOrderExerciseWithTasks } from "../../util/fetch";
import ExerciseBody from "../../components/shared/ExerciseBody";
import LoadingPage from "../../components/shared/loadingPage";
import DraggableList from "../../components/shared/draggableList";
import { exerciseTypes } from "../../util/options";
import Sequence from "../../img/sequence.png";
import { useTranslation } from "react-i18next";
import { taskState } from "../../util/options";

const OrderExercise = () => {
  const { exerciseId } = useParams();
  const { t } = useTranslation();
  const [exercise, setExercise] = useState({ orderTasks: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [taskInputs, setTaskInputs] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchOrderExerciseWithTasks(
      setIsLoading,
      exerciseId,
      setExercise,
      setTaskInputs
    );
  }, [exerciseId]);

  const checkInputs = () => {
    let correct = true;

    exercise.orderTasks.forEach((task, index) => {
      const trimAndJoin = (words) => words.map((word) => word.trim()).join(" ");
      if (
        trimAndJoin(task.initialWords) !== trimAndJoin(taskInputs[index].words)
      ) {
        const newTaskInputs = [
          ...taskInputs,
          (taskInputs[index].taskState = taskState.incorrect),
        ];
        setTaskInputs(newTaskInputs);
        correct = false;
      } else {
        const newTaskInputs = [
          ...taskInputs,
          (taskInputs[index].taskState = taskState.correct),
        ];
        setTaskInputs(newTaskInputs);
      }
    });

    if (correct) {
      setShowModal(true);
      return true;
    } else {
      return false;
    }
  };

  const handleTaskInputChange = (index, newOrder) => {
    const newTaskInputs = [...taskInputs];
    newTaskInputs[index] = {
      ...newTaskInputs[index],
      words: newOrder,
    };
    setTaskInputs(newTaskInputs);
  };

  return isLoading ? (
    <LoadingPage />
  ) : (
    <ExerciseBody
      exerciseTypeTitle={t("order")}
      icon={Sequence}
      exercise={exercise}
      checkInputs={checkInputs}
      showModal={showModal}
      exerciseType={exerciseTypes.order}
      navigateString="/home/exercises/order"
      defaultDescription={t("orderDefaultDescription")}
    >
      <div>
        {exercise.orderTasks.map((task, index) => (
          <div key={index} className="mb-4 flex flex-row items-center">
            <h1 className="text-3xl font-bold mr-2">{index + 1}.</h1>
            <DraggableList
              listItems={taskInputs[index].words}
              setListItems={(newOrder) =>
                handleTaskInputChange(index, newOrder)
              }
            />
            {taskInputs[index].taskState === taskState.incorrect && (
              <span
                style={{ color: "red" }}
                className="material-symbols-outlined"
              >
                close
              </span>
            )}
            {taskInputs[index].taskState === taskState.correct && (
              <span
                style={{ color: "green" }}
                className="material-symbols-outlined"
              >
                done
              </span>
            )}
          </div>
        ))}
      </div>
    </ExerciseBody>
  );
};

export default OrderExercise;
