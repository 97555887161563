import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../components/shared/button";
import CreateExerciseBody from "../../components/shared/createExerciseBody";
import { db } from "../../config/firebase";
import { exerciseTypes } from "../../util/options";
import LoginInput from "../../components/shared/LoginInput";

const CreateOrderWordsExercise = () => {
  const { t } = useTranslation();
  const userData = useSelector((state) => state.user.userData);
  const { exerciseId } = useParams();
  const navigate = useNavigate();
  const [sentences, setSentences] = useState([[""]]);
  const [selectedLevelValue, setSelectedLevelValue] = useState("");
  const [titleValue, setTitleValue] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [titleError, setTitleError] = React.useState(false);
  const [levelError, setLevelError] = React.useState(false);
  const [orderTasksError, setOrderTasksError] = React.useState([]);

  const backFunc = () => {
    if (exerciseId) {
      navigate("/home/myExercises");
    } else {
      navigate("/home/createExercise");
    }
  };

  useEffect(() => {
    const fetchExercise = async () => {
      if (exerciseId) {
        const docRef = doc(db, "excercise", exerciseId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const exerciseData = docSnap.data();
          setTitleValue(exerciseData.name || "");
          setSelectedLevelValue(exerciseData.level || "");

          const tasksCollectionRef = collection(docRef, "OrderTasks");
          const querySnapshot = await getDocs(tasksCollectionRef);

          const tasks = querySnapshot.docs
            .map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))
            .sort((a, b) => a.order - b.order);

          setSentences(tasks.map((task) => task.words || [""]));
        } else {
          console.log("No such document!");
        }
      }
    };

    fetchExercise();
  }, [exerciseId]);

  const handleAddPair = () => {
    setSentences([...sentences, [""]]);
  };

  const updateSentence = (sentenceIndex, wordIndex, value) => {
    const updatedSentences = sentences.map((sentence, idx) =>
      idx === sentenceIndex
        ? [
            ...sentence.slice(0, wordIndex),
            value,
            ...sentence.slice(wordIndex + 1),
          ]
        : sentence
    );
    setSentences(updatedSentences);
  };

  const addWordToSentence = (sentenceIndex) => {
    const updatedSentences = sentences.map((sentence, idx) =>
      idx === sentenceIndex ? [...sentence, ""] : sentence
    );
    setSentences(updatedSentences);
  };

  const removeWordFromSentence = (sentenceIndex, wordIndex) => {
    const updatedSentences = sentences.map((sentence, idx) =>
      idx === sentenceIndex
        ? [...sentence.slice(0, wordIndex), ...sentence.slice(wordIndex + 1)]
        : sentence
    );
    setSentences(updatedSentences);
  };

  const removeSentence = (sentenceIndex) => {
    const updatedSentences = sentences.filter(
      (_, idx) => idx !== sentenceIndex
    );
    setSentences(updatedSentences);
  };

  const checkInputs = () => {
    let correct = true;
    if (titleValue === "") {
      setTitleError(true);
      correct = false;
    } else {
      setTitleError(false);
    }
    if (selectedLevelValue === "") {
      setLevelError(true);
      correct = false;
    } else {
      setLevelError(false);
    }

    const errors = sentences.map((sentence) => {
      const sentenceErrors = sentence.map((word) => word === "");
      if (sentenceErrors.includes(true)) {
        correct = false;
      }
      return sentenceErrors;
    });
    setOrderTasksError(errors);

    return correct;
  };

  const saveExercise = async () => {
    if (!checkInputs()) {
      return;
    }
    let exerciseDocRef;
    if (exerciseId) {
      exerciseDocRef = doc(db, "excercise", exerciseId);
      await updateDoc(exerciseDocRef, {
        name: titleValue,
        level: selectedLevelValue,
        type: exerciseTypes.order,
      });
    } else {
      exerciseDocRef = await addDoc(collection(db, "excercise"), {
        name: titleValue,
        level: selectedLevelValue,
        type: exerciseTypes.order,
        createdAt: new Date(),
        createdBy: userData.uid,
        createdByName: userData.firstname ?? "N/A",
        createdByLastName: userData.lastname ?? "N/A",
      });
    }

    const tasksCollectionRef = collection(exerciseDocRef, "OrderTasks");
    await Promise.all(
      sentences.map((sentence, index) =>
        addDoc(tasksCollectionRef, {
          words: sentence,
          order: index,
        })
      )
    );

    setIsCorrect(true);

    setShowModal(true);
  };

  return (
    <CreateExerciseBody
      addNewExercise={saveExercise}
      addButtonOnclick={handleAddPair}
      backFunc={backFunc}
      titleValue={titleValue}
      setTitleValue={setTitleValue}
      selectedLevelValue={selectedLevelValue}
      setSelectedLevelValue={setSelectedLevelValue}
      isEditing={exerciseId ? true : false}
      showModal={showModal}
      setShowModal={setShowModal}
      isCorrect={isCorrect}
      setIsCorrect={setIsCorrect}
      type={exerciseTypes.order}
      id={exerciseId}
      titleError={titleError}
      levelError={levelError}
    >
      {sentences.map((sentence, index) => (
        <div key={index} className="flex flex-col mb-4">
          <h1 className="font-bold text-2xl back">
            {index + 1}. {t("sentence")}
          </h1>
          <div className="bg-gray-100 p-4 rounded-lg">
            <div className="flex flex-row items-center justify-center flex-wrap">
              {sentence.map((word, wordIndex) => (
                <div className="flex flex-row">
                  <LoginInput
                    key={wordIndex}
                    placeholder={t("word") + " " + (wordIndex + 1)}
                    icon={"description"}
                    value={word}
                    setValue={(e) =>
                      updateSentence(index, wordIndex, e.target.value)
                    }
                    secondParam={true}
                    width="w-[100px]"
                    showError={orderTasksError[index]?.[wordIndex]}
                    errorMessage={t("emptyFieldError")}
                  />
                  <Button
                    icon="delete"
                    width="w-[30px]"
                    padding=""
                    extraStyles="mr-4"
                    onClick={() => removeWordFromSentence(index, wordIndex)}
                  />
                </div>
              ))}
            </div>
            <div className="flex flex-row items-center">
              <Button
                width="w-fit"
                icon="add"
                text={t("addWordToSentence")}
                onClick={() => addWordToSentence(index)}
                extraStyle="mr-2"
              />
              <Button
                icon="delete"
                width="w-[30px]"
                onClick={() => removeSentence(index)}
              />
            </div>
          </div>
        </div>
      ))}
    </CreateExerciseBody>
  );
};

export default CreateOrderWordsExercise;
