import { motion } from "framer-motion";
import * as animations from "../../util/animations";

const PageTransition = ({
  children,
  variants = animations.slideUpEffect,
  transition = { duration: 0.5, type: "spring", stiffness: 120 },
  extraStyles = "",
}) => (
  <motion.div
    className={extraStyles}
    style={{ willChange: "transform, opacity" }}
    initial="initial"
    animate="in"
    exit="out"
    variants={variants}
    transition={transition}
  >
    {children}
  </motion.div>
);

export default PageTransition;
