import React from "react";
import { useNavigate } from "react-router-dom";
import Card from "../../components/shared/card";
import Footer from "../../components/shared/footer";
import Header from "../../components/shared/header";
import Chest from "../../img/chest.png";
import Random from "../../img/random.png";
import ReadingBook from "../../img/readingBook.png";
import Letter from "../../img/letter.png";
import Sequence from "../../img/sequence.png";
import NoConnection from "../../img/no-connection.png";
import Brick from "../../img/brick.png";
import PageTransition from "../../components/shared/pageTransition";
import * as animations from "../../util/animations";
import { useTranslation } from "react-i18next";

const ChooseExerciseTypePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="bg-pageBackground h-fit min-h-screen w-full items-center flex flex-col">
      <Header
        navigateTo={() => {
          navigate("/home");
        }}
      />
      <PageTransition
        variants={animations.slideUpEffect}
        extraStyles="lg:flex-row w-full h-full justify-center flex flex-col flex-1"
      >
        <Card
          text={t("words")}
          secondaryText={t("wordsSecondary")}
          onClick={() => navigate("/home/exercises/words")}
          icon={Chest}
        />
        <Card
          text={t("readingComprehension")}
          secondaryText={t("readingComprehensionSecondary")}
          onClick={() => navigate("/home/exercises/readingComprehension")}
          icon={ReadingBook}
        />
        <Card
          text={t("multipleChoice")}
          onClick={() => navigate("/home/exercises/multipleChoice")}
          secondaryText={t("multipleChoiceSecondary")}
          icon={Letter}
        />

        <Card
          text={t("order")}
          onClick={() => navigate("/home/exercises/order")}
          secondaryText={t("orderSecondary")}
          icon={Sequence}
        />

        <Card
          text={t("connection")}
          onClick={() => navigate("/home/exercises/connection")}
          secondaryText={t("connectionSecondary")}
          icon={NoConnection}
        />

        <Card
          text={t("sentenceCreation")}
          onClick={() => navigate("/home/exercises/sentenceCreation")}
          secondaryText={t("sentenceCreationSecondary")}
          icon={Brick}
        />
        <Card
          text={t("randomExercise")}
          secondaryText={t("randomExerciseSecondary")}
          onClick={() => navigate("/home/exercises/random")}
          icon={Random}
        />
      </PageTransition>
      <Footer />
    </div>
  );
};

export default ChooseExerciseTypePage;
