import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ExerciseBody from "../../components/shared/ExerciseBody";
import LoadingPage from "../../components/shared/loadingPage";
import Chest from "../../img/chest.png";
import { fetchWordExerciseWithTasks } from "../../util/fetch";
import { exerciseTypes, taskState } from "../../util/options";
import Input from "../../components/shared/input";

const WordExercise = () => {
  const { t } = useTranslation();
  const { exerciseId } = useParams();
  const [exercise, setExercise] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [taskInputs, setTaskInputs] = React.useState([]);
  // eslint-disable-next-line no-unused-vars
  const [stats, setStats] = React.useState({ correct: 0, total: 0 });
  const [showModal, setShowModal] = React.useState(false);

  React.useEffect(() => {
    fetchWordExerciseWithTasks(
      setIsLoading,
      exerciseId,
      setExercise,
      setTaskInputs,
      setStats
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const normalizeSpace = (str) => str.replace(/\s+/g, " ").trim();
  const checkInputs = () => {
    let correct = true;

    exercise.wordTasks.forEach((task, index) => {
      const userInput = normalizeSpace(taskInputs[index].input);
      const correctAnswer = normalizeSpace(task.german);

      if (correctAnswer !== userInput) {
        correct = false;
        const newTaskInputs = [...taskInputs];
        newTaskInputs[index].taskState = taskState.incorrect;
        setTaskInputs(newTaskInputs);
      } else {
        const newTaskInputs = [...taskInputs];
        newTaskInputs[index].taskState = taskState.correct;
        setTaskInputs(newTaskInputs);
      }
    });

    if (correct) {
      setShowModal(true);
      return true;
    } else {
      return false;
    }
  };

  return isLoading || !taskInputs ? (
    <LoadingPage />
  ) : (
    <ExerciseBody
      exerciseTypeTitle={t("words")}
      icon={Chest}
      exercise={exercise}
      checkInputs={checkInputs}
      showModal={showModal}
      exerciseType={exerciseTypes.words}
      navigateString={"/home/exercises/words"}
      defaultDescription={t("wordsDefaultDescription")}
    >
      {exercise.wordTasks &&
        exercise.wordTasks.map((task, index) => {
          return (
            <div key={task.id} className="flex flex-row items-center ">
              <span className="mr-2 text-2xl flex-1">
                {index + 1}. {task.slovak}
              </span>
              <div className="flex-1 flex flex-row  items-center">
                <div className="">
                  <Input
                    placeholderName={t("translate")}
                    value={taskInputs[index].input}
                    onChangeFunction={(event) => {
                      let newTaskInputs = [...taskInputs];
                      newTaskInputs[index].input = event.target.value;
                      setTaskInputs(newTaskInputs);
                    }}
                    isError={
                      taskInputs[index].taskState === taskState.incorrect
                    }
                    isCorrect={
                      taskInputs[index].taskState === taskState.correct
                    }
                    errorMessage={""}
                    width="w-full"
                  />
                </div>
                <div>
                  {taskInputs[index].taskState === taskState.incorrect && (
                    <span
                      style={{ color: "red" }}
                      className="material-symbols-outlined"
                    >
                      close
                    </span>
                  )}
                  {taskInputs[index].taskState === taskState.correct && (
                    <span
                      style={{ color: "green" }}
                      className="material-symbols-outlined"
                    >
                      done
                    </span>
                  )}
                </div>
              </div>
            </div>
          );
        })}
    </ExerciseBody>
  );
};

export default WordExercise;
