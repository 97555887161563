import React from "react";
import { useTranslation } from "react-i18next";
import Slovakia from "../../img/slovakiaBubble.png"; // Ensure path is correct
import Germany from "../../img/germany.png"; // Ensure path is correct

const LanguageSwitch = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem("appLanguage", language);
  };

  return (
    <div
      className="relative w-24 h-12 flex items-center bg-gray-300 rounded-full p-1 cursor-pointer"
      onClick={() => changeLanguage(currentLanguage === "sk" ? "de" : "sk")}
    >
      <div
        className={`absolute h-10 w-10 rounded-full shadow-md bg-white transition-transform duration-300 ease-in-out
          ${currentLanguage === "sk" ? "left-1" : "translate-x-12"}`}
        style={{ transition: "transform 0.3s ease-in-out" }}
      >
        <img
          src={currentLanguage === "sk" ? Slovakia : Germany}
          alt={currentLanguage === "sk" ? "SK" : "DE"}
          className="h-full w-full rounded-full"
        />
      </div>
    </div>
  );
};

export default LanguageSwitch;
