import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../components/shared/button";
import CreateExerciseBody from "../../components/shared/createExerciseBody";
import { db } from "../../config/firebase";
import { exerciseTypes } from "../../util/options";
import {
  collection,
  addDoc,
  doc,
  getDoc,
  getDocs,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { useTranslation } from "react-i18next";
import LoginInput from "../../components/shared/LoginInput";

const CreateConnectionExercise = () => {
  const { t } = useTranslation();
  const userData = useSelector((state) => state.user.userData);
  const { exerciseId } = useParams();
  const navigate = useNavigate();
  const [pairs, setPairs] = useState([{ left: "", right: "" }]);
  const [selectedLevelValue, setSelectedLevelValue] = useState("");
  const [titleValue, setTitleValue] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [titleError, setTitleError] = React.useState(false);
  const [levelError, setLevelError] = React.useState(false);
  const [ConnectionTasksError, setConnectionTasksError] = React.useState([]);

  useEffect(() => {
    const fetchExercise = async () => {
      if (exerciseId) {
        const docRef = doc(db, "excercise", exerciseId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const exerciseData = docSnap.data();
          setTitleValue(exerciseData.name || "");
          setSelectedLevelValue(exerciseData.level || "");

          const tasksCollectionRef = collection(docRef, "ConnectionTasks");
          const querySnapshot = await getDocs(tasksCollectionRef);

          const tasks = querySnapshot.docs
            .map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))
            .sort((a, b) => a.order - b.order);

          setPairs(
            tasks.map((task) => ({ left: task.left, right: task.right }))
          );
        } else {
          console.log("No such document!");
          navigate("/home/myExercises"); // Redirect if no document found
        }
      }
    };

    fetchExercise();
  }, [exerciseId, navigate]);

  const handleAddPair = () => {
    setPairs([...pairs, { left: "", right: "" }]);
  };

  const updatePair = (index, side, value) => {
    const newPairs = [...pairs];
    newPairs[index][side] = value;
    setPairs(newPairs);
  };

  const removePair = (index) => {
    const newPairs = pairs.filter((_, idx) => idx !== index);
    setPairs(newPairs);
  };

  const checkInputs = () => {
    let correct = true;
    if (titleValue === "") {
      setTitleError(true);
      correct = false;
    } else {
      setTitleError(false);
    }
    if (selectedLevelValue === "") {
      setLevelError(true);
      correct = false;
    } else {
      setLevelError(false);
    }

    const errors = pairs.map((task) => {
      if (task.left === "" || task.right === "") {
        correct = false;
      }
      return {
        left: task.left === "",
        right: task.right === "",
      };
    });
    setConnectionTasksError(errors);

    return correct;
  };

  const saveExercise = async () => {
    if (!checkInputs()) {
      return;
    }

    let exerciseDocRef;
    if (exerciseId) {
      exerciseDocRef = doc(db, "excercise", exerciseId);
      await updateDoc(exerciseDocRef, {
        name: titleValue,
        level: selectedLevelValue,
        type: exerciseTypes.connection,
        lastUpdatedAt: new Date(), // Add last updated timestamp
      });

      const tasksCollectionRef = collection(exerciseDocRef, "ConnectionTasks");
      await Promise.all(
        (
          await getDocs(tasksCollectionRef)
        ).docs.map((doc) => deleteDoc(doc.ref))
      );
    } else {
      exerciseDocRef = await addDoc(collection(db, "excercise"), {
        name: titleValue,
        level: selectedLevelValue,
        type: exerciseTypes.connection,
        createdAt: new Date(),
        createdBy: userData.uid,
        createdByName: userData.firstname ?? "N/A",
        createdByLastName: userData.lastname ?? "N/A",
        createdByNickname: userData.nickname ?? "N/A",
      });
    }

    const tasksCollectionRef = collection(exerciseDocRef, "ConnectionTasks");
    await Promise.all(
      pairs.map((pair, index) =>
        addDoc(tasksCollectionRef, {
          left: pair.left,
          right: pair.right,
          order: index,
        })
      )
    );

    setIsCorrect(true);
    setShowModal(true);
    console.log("Exercise and connection tasks created/updated successfully.");
  };

  return (
    <CreateExerciseBody
      addNewExercise={saveExercise}
      addButtonOnclick={handleAddPair}
      backFunc={() => navigate("/home/myExercises")}
      titleValue={titleValue}
      setTitleValue={setTitleValue}
      selectedLevelValue={selectedLevelValue}
      setSelectedLevelValue={setSelectedLevelValue}
      isEditing={exerciseId ? true : false}
      showModal={showModal}
      setShowModal={setShowModal}
      isCorrect={isCorrect}
      setIsCorrect={setIsCorrect}
      type={exerciseTypes.connection}
      id={exerciseId}
      titleError={titleError}
      levelError={levelError}
    >
      {pairs.map((pair, index) => (
        <div>
          <h1 className="text-2xl font-bold text-start mb-4">
            {index + 1}. {t("pair")}
          </h1>
          <div
            key={index}
            className="flex flex-row items-center mb-4 justify-center bg-gray-100 rounded-lg p-4"
          >
            <div className="flex flex-col">
              <LoginInput
                value={pair.left}
                setValue={(value) => updatePair(index, "left", value)}
                showError={ConnectionTasksError[index]?.left}
                errorMessage={t("emptyFieldError")}
                icon="arrow_left_alt"
                placeholder={t("leftItem")}
                // secondParam={true}
              />

              <LoginInput
                value={pair.right}
                setValue={(value) => updatePair(index, "right", value)}
                showError={ConnectionTasksError[index]?.right}
                errorMessage={t("emptyFieldError")}
                icon="arrow_right_alt"
                placeholder={t("rightItem")}
                // secondParam={true}
              />
            </div>
            <Button
              icon="delete"
              width="w-[30px]"
              padding="p-5"
              onClick={() => removePair(index)}
            />
          </div>
        </div>
      ))}
    </CreateExerciseBody>
  );
};

export default CreateConnectionExercise;
