import { collection, getDocs } from "firebase/firestore";
import React, { useEffect } from "react";
import LoadingPage from "../../components/shared/loadingPage";
import { db } from "../../config/firebase";
import Header from "../../components/shared/header";
import { exerciseTypes } from "../../util/options";
import { useNavigate } from "react-router-dom";

const RandomExercisePage = () => {
  const [selectedExercise, setSelectedExercise] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchExercises = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "excercise"));
        const exercises = [];
        querySnapshot.forEach((doc) => {
          exercises.push({ id: doc.id, ...doc.data() });
        });
        if (exercises.length > 0) {
          const randomIndex = Math.floor(Math.random() * exercises.length);
          setSelectedExercise(exercises[randomIndex]);
        } else {
          console.log("No exercises found.");
        }
      } catch (error) {
        console.error("Error fetching exercises:", error);
      }
      setIsLoading(false);
    };

    fetchExercises();
    if (selectedExercise) {
      switch (selectedExercise.type) {
        case exerciseTypes.words:
          navigate(`/home/exercises/words/${selectedExercise.id}`);
          break;
        case exerciseTypes.readingComprehension:
          navigate(
            `/home/exercises/readingComprehension/${selectedExercise.id}`
          );
          break;
        case exerciseTypes.sentenceCreation:
          navigate(`/home/exercises/sentenceCreation/${selectedExercise.id}`);
          break;
        case exerciseTypes.order:
          navigate(`/home/exercises/order/${selectedExercise.id}`);
          break;
        case exerciseTypes.multipleChoice:
          navigate(`/home/exercises/multipleChoice/${selectedExercise.id}`);
          break;
        case exerciseTypes.connection:
          navigate(`/home/exercises/connection/${selectedExercise.id}`);
          break;
        default:
          console.log("Unknown exercise type.");
      }
    }
    // eslint-disable-next-line
  }, [selectedExercise]);

  return isLoading || !selectedExercise ? (
    <LoadingPage />
  ) : (
    <div>
      <Header />
    </div>
  );
};

export default RandomExercisePage;
