import React, { useState, useEffect, useRef, useCallback } from "react";
import { v4 as uuidv4 } from "uuid"; // Ensure you have uuid installed to use this function

const PairConnector = ({
  tasks,
  setTaskInputs,
  taskInputs,
  updateShuffledTasks,
}) => {
  const [selectedLeft, setSelectedLeft] = useState(null);
  const [lines, setLines] = useState([]);
  const [shuffledTasks, setShuffledTasks] = useState([]);
  const [mousePosition, setMousePosition] = useState(null);
  const leftItemsRef = useRef([]);
  const rightItemsRef = useRef([]);
  const [currentColor, setCurrentColor] = useState(0);
  const svgRef = useRef();
  const lineColorsRef = useRef({});

  useEffect(() => {
    const shuffledRightTasks = shuffleArray(
      tasks.map((item, index) => ({
        ...item,
        uniqueId: `right-${index}-${uuidv4()}`,
      }))
    );
    setShuffledTasks(shuffledRightTasks);
    setTaskInputs(
      tasks.map((task, index) => ({
        left: task.left,
        right: null,
        uniqueId: `left-${index}`,
      }))
    );
    console.log("Tasks shuffled and inputs set.");
    updateShuffledTasks(shuffledRightTasks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks, setTaskInputs]);

  const handleMouseMove = useCallback(
    (event) => {
      if (selectedLeft !== null) {
        const svgRect = svgRef.current.getBoundingClientRect();
        setMousePosition({
          x: event.clientX - svgRect.left,
          y: event.clientY - svgRect.top,
        });
      }
    },
    [selectedLeft]
  );

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, [handleMouseMove]);

  const handleLeftSelect = (index) => {
    setCurrentColor(getRandomColor());
    setSelectedLeft(index);
  };

  const handleRightSelect = (uniqueId) => {
    if (selectedLeft !== null) {
      const newInputs = [...taskInputs];
      const existingInputIndex = newInputs.findIndex(
        (input) => input.left === tasks[selectedLeft].left
      );

      if (existingInputIndex !== -1) {
        const rightTask = shuffledTasks.find(
          (item) => item.uniqueId === uniqueId
        );
        newInputs[existingInputIndex] = {
          ...newInputs[existingInputIndex],
          right: rightTask.uniqueId, // Store uniqueId instead of rightTask.right
        };

        setTaskInputs(newInputs);
        const rightIndex = shuffledTasks.findIndex(
          (item) => item.uniqueId === uniqueId
        );
        const newLine = calculateLineCoords(selectedLeft, rightIndex);
        if (newLine) {
          newLine.color =
            lineColorsRef.current[existingInputIndex] || currentColor;
          lineColorsRef.current[existingInputIndex] = newLine.color; // Store color in ref
          const newLines = [...lines];
          newLines[existingInputIndex] = newLine;
          setLines(newLines);
        }
      }

      setSelectedLeft(null);
      setMousePosition(null);
    }
  };

  const calculateLineCoords = (leftIndex, rightIndex) => {
    const leftEl = leftItemsRef.current[leftIndex];
    const rightEl = rightItemsRef.current[rightIndex];
    const svgRect = svgRef.current.getBoundingClientRect();

    if (leftEl && rightEl && svgRect) {
      const leftRect = leftEl.getBoundingClientRect();
      const rightRect = rightEl.getBoundingClientRect();

      return {
        x1: leftRect.right - svgRect.left,
        y1: leftRect.top - svgRect.top + leftRect.height / 2,
        x2: rightRect.left - svgRect.left,
        y2: rightRect.top - svgRect.top + rightRect.height / 2,
        color: currentColor,
      };
    }
    return null;
  };
  useEffect(() => {
    const newLines = taskInputs
      .map((input, index) => {
        if (input.right !== null) {
          const leftIndex = tasks.findIndex((task) => task.left === input.left);
          const rightIndex = shuffledTasks.findIndex(
            (task) => task.uniqueId === input.right // Use uniqueId to find the exact right task
          );
          if (leftIndex !== -1 && rightIndex !== -1) {
            const line = calculateLineCoords(leftIndex, rightIndex);
            if (line) {
              line.color = lineColorsRef.current[index] || getRandomColor(); // Assign color if not already assigned
              lineColorsRef.current[index] = line.color; // Store color
            }
            return line;
          }
        }
        return null;
      })
      .filter((line) => line !== null);

    setLines(newLines);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskInputs, tasks, shuffledTasks]);

  return (
    <div className="flex justify-between items-start px-10 py-5 relative w-full">
      <div className="flex flex-col space-y-4 w-1/2 items-center justify-center mr-10">
        {tasks.map((task, index) => (
          <div
            key={`left-${index}`}
            ref={(el) => (leftItemsRef.current[index] = el)}
            className={`cursor-pointer w-fit p-2 border rounded transition-colors duration-300 ease-in-out hover:bg-gray-200 ${
              selectedLeft === index ? "bg-blue-300" : "bg-white"
            }`}
            onClick={() => handleLeftSelect(index)}
          >
            {task.left}
          </div>
        ))}
      </div>
      <svg
        ref={svgRef}
        className="absolute top-0 left-0 w-full h-full pointer-events-none"
        style={{ height: "100%", zIndex: 10 }}
      >
        {lines.map(
          (line, index) =>
            line && (
              <line
                key={`line-${index}`}
                x1={line.x1}
                y1={line.y1}
                x2={line.x2}
                y2={line.y2}
                stroke={line.color}
                strokeWidth="2"
              />
            )
        )}
        {mousePosition && selectedLeft !== null && (
          <line
            x1={
              leftItemsRef.current[selectedLeft].getBoundingClientRect().right -
              svgRef.current.getBoundingClientRect().left
            }
            y1={
              leftItemsRef.current[selectedLeft].getBoundingClientRect().top -
              svgRef.current.getBoundingClientRect().top +
              leftItemsRef.current[selectedLeft].getBoundingClientRect()
                .height /
                2
            }
            x2={mousePosition.x}
            y2={mousePosition.y}
            stroke={currentColor}
            strokeWidth="2"
          />
        )}
      </svg>
      <div className="flex flex-col space-y-4 w-1/2 items-center justify-center">
        {shuffledTasks.map((task, index) => (
          <div
            key={task.uniqueId} // Ensure key uniqueness by using uniqueId
            ref={(el) => (rightItemsRef.current[index] = el)}
            className="cursor-pointer p-2 border rounded bg-white transition-colors duration-300 ease-in-out hover:bg-gray-200 w-fit"
            onClick={() => handleRightSelect(task.uniqueId)}
          >
            {task.right}
          </div>
        ))}
      </div>
    </div>
  );
};

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

function getRandomColor() {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export default PairConnector;
