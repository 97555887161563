import { signOut } from "firebase/auth";
import { auth } from "../config/firebase";
import { db } from "../config/firebase";
import { doc, getDoc } from "firebase/firestore";

export const logout = async (dispatch, navigate) => {
  await signOut(auth);
  dispatch({
    type: "CLEAR_USER_DATA",
  });
  navigate("/");
};

export async function fetchData(uid) {
  try {
    const userRef = doc(db, "users", uid);
    const userSnapshot = await getDoc(userRef);
    if (userSnapshot.exists()) {
      return userSnapshot.data();
    }
  } catch (error) {}
}
