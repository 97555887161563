import {
  collection,
  doc,
  getDoc,
  getDocs,
  writeBatch,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../components/shared/button";
import CreateExerciseBody from "../../components/shared/createExerciseBody";
import { db } from "../../config/firebase";
import { exerciseTypes } from "../../util/options";
import LoginInput from "../../components/shared/LoginInput";
import { notifyError } from "../../util/toast";

const CreateSentenceCreationExercise = () => {
  const { t } = useTranslation();
  const userData = useSelector((state) => state.user.userData);
  const { exerciseId } = useParams();

  const navigate = useNavigate();

  const [sentenceTasks, setSentenceTasks] = useState([
    { prompt: "", solution: "" },
  ]);
  const [selectedLevelValue, setSelectedLevelValue] = useState("");
  const [titleValue, setTitleValue] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [titleError, setTitleError] = React.useState(false);
  const [levelError, setLevelError] = React.useState(false);
  const [sentenceCreationTasksError, setSentenceCreationTasksError] =
    React.useState([]);

  useEffect(() => {
    const fetchExercise = async () => {
      if (exerciseId) {
        const docRef = doc(db, "excercise", exerciseId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const exerciseData = docSnap.data();
          setTitleValue(exerciseData.name || "");
          setSelectedLevelValue(exerciseData.level || "");

          const tasksCollectionRef = collection(docRef, "SentenceTasks");
          const querySnapshot = await getDocs(tasksCollectionRef);

          const tasks = querySnapshot.docs
            .map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))
            .sort((a, b) => a.order - b.order);

          setSentenceTasks(
            tasks.map((task) => ({
              prompt: task.prompt,
              solution: task.solution,
            }))
          );
        } else {
          console.log("No such document!");
        }
      }
    };

    fetchExercise();
  }, [exerciseId]);

  const handleAddTask = () => {
    setSentenceTasks([...sentenceTasks, { prompt: "", solution: "" }]);
  };

  const updateTask = (index, field, value) => {
    const updatedTasks = [...sentenceTasks];
    updatedTasks[index][field] = value;
    setSentenceTasks(updatedTasks);
  };

  const removeTask = (index) => {
    const filteredTasks = sentenceTasks.filter((_, idx) => idx !== index);
    setSentenceTasks(filteredTasks);
  };

  const checkInputs = () => {
    let correct = true;
    if (sentenceTasks.length === 0) {
      correct = false;
      notifyError(t("noTasksError"));
    }
    if (titleValue === "") {
      setTitleError(true);
      correct = false;
    } else {
      setTitleError(false);
    }
    if (selectedLevelValue === "") {
      setLevelError(true);
      correct = false;
    } else {
      setLevelError(false);
    }

    const errors = sentenceTasks.map((task) => {
      if (task.prompt === "" || task.solution === "") {
        correct = false;
      }
      return {
        solution: task.solution === "",
        prompt: task.prompt === "",
      };
    });
    setSentenceCreationTasksError(errors);
    console.log(errors);
    return correct;
  };

  const saveExercise = async () => {
    if (!checkInputs()) {
      return;
    }

    const batch = writeBatch(db);
    let exerciseDocRef;

    if (exerciseId) {
      exerciseDocRef = doc(db, "excercise", exerciseId);
      batch.update(exerciseDocRef, {
        name: titleValue,
        level: selectedLevelValue,
        type: exerciseTypes.sentenceCreation,
      });

      const existingTasksRef = collection(exerciseDocRef, "SentenceTasks");
      const snapshot = await getDocs(existingTasksRef);
      const existingTasksMap = snapshot.docs.reduce((acc, doc) => {
        acc[doc.data().prompt] = { id: doc.id, data: doc.data() }; // using prompt as key
        return acc;
      }, {});

      sentenceTasks.forEach((task, index) => {
        let taskRef;
        if (existingTasksMap[task.prompt]) {
          taskRef = doc(db, "SentenceTasks", existingTasksMap[task.prompt].id); // Existing task
        } else {
          taskRef = doc(collection(exerciseDocRef, "SentenceTasks")); // New task
        }

        batch.set(
          taskRef,
          {
            prompt: task.prompt,
            solution: task.solution.trim(),
            order: index,
          },
          { merge: true }
        );
      });
    } else {
      exerciseDocRef = doc(collection(db, "excercise"));
      batch.set(exerciseDocRef, {
        name: titleValue,
        level: selectedLevelValue,
        type: exerciseTypes.sentenceCreation,
        createdAt: new Date(),
        createdBy: userData.uid,
        createdByName: userData.firstname ?? "N/A",
        createdByLastName: userData.lastname ?? "N/A",
        createdByNickname: userData.nickname ?? "N/A",
      });

      sentenceTasks.forEach((task, index) => {
        const taskRef = doc(collection(exerciseDocRef, "SentenceTasks"));
        batch.set(taskRef, {
          prompt: task.prompt,
          solution: task.solution.trim(),
          order: index,
        });
      });
    }

    // Commit all changes in a single batch
    await batch.commit();
    setIsCorrect(true);
    setShowModal(true);
    console.log("Exercise saved successfully.");
  };

  return (
    <CreateExerciseBody
      addNewExercise={saveExercise}
      addButtonOnclick={handleAddTask}
      backFunc={() => navigate("/home/myExercises")}
      isEditing={exerciseId ? true : false}
      titleValue={titleValue}
      setTitleValue={setTitleValue}
      selectedLevelValue={selectedLevelValue}
      setSelectedLevelValue={setSelectedLevelValue}
      showModal={showModal}
      setShowModal={setShowModal}
      isCorrect={isCorrect}
      setIsCorrect={setIsCorrect}
      type={exerciseTypes.sentenceCreation}
      id={exerciseId}
      titleError={titleError}
      levelError={levelError}
    >
      <div className="flex flex-col justify-center items-center">
        {sentenceTasks.map((task, index) => (
          <div>
            <h1 className="text-2xl font-bold text-start mb-1">
              {index + 1}. {t("sentence")}
            </h1>
            <div
              key={index}
              className="flex flex-row items-center justify-center space-x-3 mb-4  w-fit bg-gray-100 rounded-lg p-4"
            >
              <div>
                <LoginInput
                  placeholder={t("sentencePrompt")}
                  value={task.prompt}
                  width="w-full"
                  setValue={(e) => updateTask(index, "prompt", e.target.value)}
                  showError={sentenceCreationTasksError[index]?.prompt}
                  secondParam={true}
                  icon={"description"}
                  errorMessage={t("emptyFieldError")}
                />
                <LoginInput
                  placeholder={t("sentenceSolution")}
                  value={task.solution}
                  width="w-full"
                  secondParam={true}
                  setValue={(e) =>
                    updateTask(index, "solution", e.target.value)
                  }
                  showError={sentenceCreationTasksError[index]?.solution}
                  icon={"description"}
                  errorMessage={t("emptyFieldError")}
                />
              </div>
              <Button
                icon="delete"
                onClick={() => removeTask(index)}
                width="w-[30px]"
                padding="p-5"
              />
            </div>
          </div>
        ))}
      </div>
    </CreateExerciseBody>
  );
};

export default CreateSentenceCreationExercise;
