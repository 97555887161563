import React from "react";
import Button from "./button";
import { motion, AnimatePresence } from "framer-motion";

const Modal = ({ showModal, onClick, children, buttonText = "Dokončiť" }) => {
  if (!showModal) return null;

  const modalVariants = {
    hidden: { opacity: 0, scale: 0.95 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.3 } },
    exit: { opacity: 0, scale: 0.95, transition: { duration: 0.3 } },
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center z-50 p-5">
      <AnimatePresence>
        <motion.div
          className="bg-white p-6 rounded-lg shadow-md z-50 items-center justify-center flex flex-col w-full md:w-1/2 lg:w-1/3"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          {children}
          <Button onClick={onClick} text={buttonText} buttonType={"cta"} />
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default Modal;
