import React from "react";

export default function IconCreditItem({ creditItem }) {
  return (
    <a
      className="bg-white mb-5 rounded-lg mx-4 p-5 flex flex-col items-center justify-center text-black font-thin text-xl hover:bg-gray-100 transition duration-150 ease-in-out transform hover:scale-105 shadow-md"
      href={creditItem?.link}
      title={creditItem?.title}
      target="_blank"
      rel="noopener noreferrer"
    >
      <h1>{creditItem?.name}</h1>
      <div className="flex flex-row justify-center items-center mt-2">
        {creditItem?.icons?.map((icon, index) => (
          <img
            key={index}
            src={icon}
            className="w-20 sm:w-24 md:w-32 lg:w-40 xl:w-52 mx-2 my-1"
            alt={creditItem?.name}
          />
        ))}
      </div>
    </a>
  );
}
