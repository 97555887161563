import React from "react";
import { useNavigate } from "react-router";
import Card from "../../components/shared/card";
import Footer from "../../components/shared/footer";
import Header from "../../components/shared/header";
import Chest from "../../img/chest.png";
import ReadingBook from "../../img/readingBook.png";
import PageTransition from "../../components/shared/pageTransition";
import * as animations from "../../util/animations";
import Letter from "../../img/letter.png";
import Sequence from "../../img/sequence.png";
import NoConnection from "../../img/no-connection.png";
import Brick from "../../img/brick.png";
import { useTranslation } from "react-i18next";

const CreateExercisePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="bg-pageBackground h-fit min-h-screen w-full flex flex-col">
      <Header navigateTo={() => navigate("/home")} />
      <PageTransition
        variants={animations.slideUpEffect}
        extraStyles="flex-1 h-full w-full flex flex-col items-center justify-center"
      >
        <h1 className="font-bold text-3xl text-headerText w-fit">
          {t("createExercise")}
        </h1>
        <div className="flex lg:flex-row flex-col h-full flex-1  w-full">
          <Card
            onClick={() => navigate("/home/createExercise/words")}
            icon={Chest}
            text={t("words")}
          />

          <Card
            onClick={() =>
              navigate("/home/createExercise/readingComprehension")
            }
            icon={ReadingBook}
            text={t("readingComprehension")}
          />

          <Card
            onClick={() => navigate("/home/createExercise/multipleChoice")}
            icon={Letter}
            text={t("multipleChoice")}
          />

          <Card
            onClick={() => navigate("/home/createExercise/order")}
            icon={Sequence}
            text={t("order")}
          />

          <Card
            onClick={() => navigate("/home/createExercise/connection")}
            icon={NoConnection}
            text={t("connection")}
          />

          <Card
            onClick={() => navigate("/home/createExercise/sentenceCreation")}
            icon={Brick}
            text={t("sentenceCreation")}
          />
        </div>
      </PageTransition>
      <Footer />
    </div>
  );
};

export default CreateExercisePage;
